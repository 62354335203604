<template>
  <div :class="{'row': !config}" style="width: 100%;">
    <CCol sm="12" :class="{'center-map-pdf': print ? true: null, 'overflow-zoom': zoom != 100? true : null}" v-dragscroll="zoom != 100">
      <div class="map-yard" :class="{'map-pdf': print ? true: null}" :style="mapYardStyles">
        <svg v-if="!print" id="COVER_WAREHOUSE" data-name="COVER WAREHOUSE" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1467.42 462.15">
          <g id="FONDO_ALMACEN" data-name="FONDO ALMACEN">
            <path d="M495.94,0h959.48c6.63,0,12,5.37,12,12V450.15c0,6.63-5.37,12-12,12H12c-6.63,0-12-5.37-12-12V12C0,5.37,5.37,0,12,0H397.96" fill="#878784"/>
          </g>
          <g id="AREA_3">
            <rect id="AREA_3-2" x="16.49" y="274.5" width="1434.6" height="167.92" fill="#f4d208" style="opacity: 0"/>
          </g>
          <g id="A-2">
            <rect id="A-2-2" x="16.49" y="185.09" width="1434.6" height="90.9" fill="#f4d208" style="opacity: 0"/>
          </g>
          <g id="AREA_1">
            <rect id="AREA_1-2" x="18.5" y="20.76" width="1432.59" height="164.33" fill="#f4d208" style="opacity: 0"/>
          </g>
          <g id="PERIMETRO">
            <path d="M1275.59,15.67H15.04V446.47l1234.8-.02v.02h202.54V186.32h0V15.67h-176.79ZM20.71,21.35H1253.32s193.38-.01,193.38-.01V183.78H20.71V21.35h0ZM1446.71,440.8h-185.16v-.02l-1240.84,.02v-163.31H1446.71v163.31Zm2.84-166.3H17.87v-87.74H1449.55v87.74h0Z" fill="#cdcdcd"/>
            <path d="M1449.6,232.13h-3.22l.05-3h3.17v3h0Zm-13.12,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0H18.38l.05-3h24.05v3Zm-25-.05h-.1v-2.9h.1v2.9Z" fill="#cdcdcd"/>
          </g>
        </svg>
        <svg v-else id="COVER_WAREHOUSE_PDF" data-name="COVER WAREHOUSE PDF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1437.34 430.8">
          <g id="PERIMETRO">
            <path d="M1260.55,0H0V430.8l1234.8-.02v.02h202.54V170.65h0V0h-176.79ZM5.67,5.68H1431.66s0,162.43,0,162.43H5.67V5.68h0ZM1431.67,425.13h-185.16v-.02l-1240.84,.02v-163.31H1431.67v163.31Zm2.84-166.3H2.83v-87.74H1434.51v87.74h0Z" fill="#070707"/>
            <path d="M1434.56,216.46h-3.22l.05-3h3.17v3h0Zm-13.12,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1l.05-3h24.05v3h0Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0h-24.1v-3h24.1v3Zm-34,0H3.34l.05-3H27.44v3h0Zm-25-.05h-.1v-2.9h.1v2.9Z" fill="#070707"/>
          </g>
          <g id="AREA_3">
            <rect id="AREA_3-2" x="5.67" y="261.82" width="1426" height="163.3" fill="#f4d208" style="opacity: 0;"/>
          </g>
          <g id="A-2">
            <rect id="A-2-2" x="3.34" y="171.09" width="1431.17" height="87.74" fill="#f4d208" style="opacity: 0;"/>
          </g>
          <g id="AREA_1">
            <rect id="AREA_1-2" x="5.35" y="5.09" width="1426.31" height="163.02" fill="#f4d208" style="opacity: 0;"/>
          </g>
        </svg>
        <div 
          id="areaOne"
          :style="{
            'min-width': areaOne.width,
            'max-width': areaOne.width,
            'min-height': areaOne.height,
            'max-height': areaOne.height,
            'top': areaOne.top,
            'left': areaOne.left,
          }"
          :class="{
            'zone-border': (isDragging || dragZone == 'areaOne') && config? true : null
          }"
          @drop="onDrop($event, 'AREA_1')"
          @dragover.prevent
          @dragenter.prevent
        >
          <grid-layout 
            ref="layoutOne"
            :layout.sync="layoutCopyOne"
            :col-num="28"
            :row-height="currentHeightOne"
            :max-rows="28"
            :is-draggable="true"
            :is-resizable="true"
            :is-bounded="true"
            :vertical-compact="false"
            :use-css-transforms="true"
            :preventCollision="false"
            :margin="[0,0]"
            :autoSize="false"
          >
            <grid-item
              :key="item.i"
              v-for="item in layoutCopyOne"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :minW="item.minW"
              :minH="item.minH"
              :h="item.h"
              :i="item.i"
              :is-resizable="item.resizable"
              :static="!config"
              @move="moveItem"
              @moved="movedItem"
              @resized="resizedEvent"
              :id="item.i"
            >
              <div class="area" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="(item.elementType == 'area' || item.YardAreaId) && !isCoverWareHouse(item)">
                <div class="item" @click="ValidateTpArea(item)" :style="PointerArea(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 40.74">
                    <path d="M41.64 16.27c0 9.78-7.43 12.72-11.45 18.91a18.68 18.68 0 0 0-2.69 7.69 20.52 20.52 0 0 0-2.92-7.69C20.26 29 13.36 26.26 13.36 16.27a14.14 14.14 0 1 1 28.28 0Z" data-name="Capa 2" :style="{fill: item.TpYardAreaColor}" transform="translate(-13.36 -2.13)"/>
                  </svg>
                  <CIcon class="icon" :src="iconSrc(item.TpYardAreaIconRoute)" size="sm" v-if="item.TpYardAreaIconRoute"/>
                </div>
                <div class="item-label" :class="{ 'item-label-black': print ? true :  null}">
                  {{ item.YardAreaName }}
                </div>
                <div class="controls-option" v-if="config">
                  <div class="control remove" @click.prevent="removeFromZone(item, 1)">
                    <CIcon name="cil-trash"/>
                  </div>
                  <div class="control"  style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1)">
                    <CIcon name="cil-arrow-thick-to-top"/>
                  </div>
                  <div class="control" style="margin-right: 0;" @click.prevent="rotateDown(item, 1)">
                    <CIcon name="cil-arrow-thick-to-bottom"/>
                  </div>
                </div>
              </div>
              <div v-if="isCoverWareHouse(item)" class="block area-module justify-content-center">
                <div class="item-label" :class="{ 'item-label-black': print ? true :  null}" style="font-weight: bold !important; color: #fff">
                  {{ item.YardAreaName }}
                </div>
                <div class="controls-option" v-if="config">
                  <div class="control remove" @click.prevent="removeFromZone(item, 1)">
                    <CIcon name="cil-trash"/>
                  </div>
                </div>
              </div>
              <div :class="blockClasses(item.tpa)" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="(item.elementType == 'block' || item.YardBlockId)&&(item?.Height >= level+1)">
                <div :class="codeClasses(item.tpa)">
                  <div class="circle" :class="{ 'border-black': print ? true :  null}">
                    <img
                      :src="getCharacterSvg(item.BlockAlias, print)"
                      alt="A"
                      class="center-icon"
                    >
                  </div>
                  <div :class="lineClasses(item.tpa)"></div>
                </div>
                <div :class="gridClasses(item.tpa)">
                  <yard-grid
                    :col="colNum(item)"
                    :row="rowNum(item)"
                    :tooltip="tooltip"
                    :viewType="viewType"
                    :angleType="item.tpa"
                    :print="print"
                    :preferences="preferences"
                    :margin="1"
                    showStatus
                    showNumbers
                    border
                    :items="generateLayout(item, level)"
                    :maxCol="colNum(item)"
                    @selected-position="selectedPosition"
                    :block = "item"
                  />
                </div>
                <div :class="controlsOptionClasses(item.tpa)" v-if="config">
                  <div :class="controlClasses(item.tpa, 1)" @click.prevent="removeFromZone(item, 1)">
                    <CIcon name="cil-trash"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" @click.prevent="orderBayItems(item)">
                    <CIcon name="cilSwapHorizontal"/>
                  </div>
                  <div :class="controlClasses(item.tpa)"  style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1, 'block')">
                    <CIcon name="cil-arrow-thick-to-top"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" style="margin-right: 0;" @click.prevent="rotateDown(item, 1, 'block')">
                    <CIcon name="cil-arrow-thick-to-bottom"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" @click.prevent="orderStackItems(item)">
                    <CIcon name="cilSwapVertical"/>
                  </div>
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
        <div 
          id="areaTwo"
          :style="{
            'min-width': areaTwo.width,
            'max-width': areaTwo.width,
            'min-height': areaTwo.height,
            'max-height': areaTwo.height,
            'top': areaTwo.top,
            'left': areaTwo.left,
          }"
          :class="{
            'zone-border': (isDragging || dragZone == 'areaTwo') && config? true : null
          }"
          @drop="onDrop($event, 'AREA_3')"
          @dragover.prevent
          @dragenter.prevent
        >
          <grid-layout 
            ref="layoutTow"
            :layout.sync="layoutCopyTwo"
            :col-num="28"
            :row-height="currentHeightTwo"
            :max-rows="28"
            :is-draggable="true"
            :is-resizable="true"
            :is-bounded="true"
            :vertical-compact="false"
            :use-css-transforms="true"
            :preventCollision="false"
            :margin="[0,0]"
            :autoSize="false"
          >
            <grid-item
              :key="item.i"
              v-for="item in layoutCopyTwo"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :minW="item.minW"
              :minH="item.minH"
              :h="item.h"
              :i="item.i"
              :is-resizable="item.resizable"
              :static="!config"
              @move="moveItemTwo"
              @moved="movedItemTwo"
              @resized="resizedEventTwo"
              :id="item.i"
            >
              <div class="area" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="(item.elementType == 'area' || item.YardAreaId) && !isCoverWareHouse(item)">
                <div class="item" @click="ValidateTpArea(item)" :style="PointerArea(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 40.74">
                    <path d="M41.64 16.27c0 9.78-7.43 12.72-11.45 18.91a18.68 18.68 0 0 0-2.69 7.69 20.52 20.52 0 0 0-2.92-7.69C20.26 29 13.36 26.26 13.36 16.27a14.14 14.14 0 1 1 28.28 0Z" data-name="Capa 2" :style="{fill: item.TpYardAreaColor}" transform="translate(-13.36 -2.13)"/>
                  </svg>
                  <CIcon class="icon" :src="iconSrc(item.TpYardAreaIconRoute)" size="sm" v-if="item.TpYardAreaIconRoute"/>
                </div>
                <div class="item-label" :class="{ 'item-label-black': print ? true :  null}">
                  {{ item.YardAreaName }}
                </div>
                <div class="controls-option" v-if="config">
                  <div class="control remove" @click.prevent="removeFromZone(item, 2)">
                    <CIcon name="cil-trash"/>
                  </div>
                  <div class="control"  style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1)">
                    <CIcon name="cil-arrow-thick-to-top"/>
                  </div>
                  <div class="control" style="margin-right: 0;" @click.prevent="rotateDown(item, 1)">
                    <CIcon name="cil-arrow-thick-to-bottom"/>
                  </div>
                </div>
              </div>
              <div v-if="isCoverWareHouse(item)" class="block area-module justify-content-center">
                <div class="item-label" :class="{ 'item-label-black': print ? true :  null}" style="font-weight: bold !important; color: #fff">
                  {{ item.YardAreaName }}
                </div>
                <div class="controls-option" v-if="config">
                  <div class="control remove" @click.prevent="removeFromZone(item, 2)">
                    <CIcon name="cil-trash"/>
                  </div>
                </div>
              </div>
              <div :class="blockClasses(item.tpa)" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="(item.elementType == 'block' || item.YardBlockId)&&(item?.Height >= level+1)">
                <div :class="codeClasses(item.tpa)">
                  <div class="circle" :class="{ 'border-black': print ? true :  null}">
                    <img
                      :src="getCharacterSvg(item.BlockAlias, print)"
                      alt="A"
                      class="center-icon"
                    >
                  </div>
                  <div :class="lineClasses(item.tpa)"></div>
                </div>
                <div :class="gridClasses(item.tpa)">
                  <yard-grid
                    :col="colNum(item)"
                    :row="rowNum(item)"
                    :tooltip="tooltip"
                    :viewType="viewType"
                    :angleType="item.tpa"
                    :print="print"
                    :preferences="preferences"
                    :margin="1"
                    showStatus
                    showNumbers
                    border
                    :items="generateLayout(item, level)"
                    :maxCol="colNum(item)"
                    @selected-position="selectedPosition"
                    :block = "item"
                  />
                </div>
                <div :class="controlsOptionClasses(item.tpa)" v-if="config">
                  <div :class="controlClasses(item.tpa, 1)" @click.prevent="removeFromZone(item, 2)">
                    <CIcon name="cil-trash"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" @click.prevent="orderBayItems(item)">
                    <CIcon name="cilSwapHorizontal"/>
                  </div>
                  <div :class="controlClasses(item.tpa)"  style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1, 'block')">
                    <CIcon name="cil-arrow-thick-to-top"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" style="margin-right: 0;" @click.prevent="rotateDown(item, 1, 'block')">
                    <CIcon name="cil-arrow-thick-to-bottom"/>
                  </div>
                  <div :class="controlClasses(item.tpa)" @click.prevent="orderStackItems(item)">
                    <CIcon name="cilSwapVertical"/>
                  </div>
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
    </CCol>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { GridLayout, GridItem } from "vue-grid-layout";
import YardGridMixin from '@/_mixins/yard-grid';
import YardGrid from './yard-grid';
import { GenerateSvgPlaceholder } from '@/_helpers/funciones';
import YardSlotModal from "./yard-slot-modal";
import { dragscroll } from 'vue-dragscroll';
import PositionModal from './position-modal';

//created
function created() {
  
}
//mounted
async function mounted() {
  await this.setObserver();

  this.layoutCopyOne = this.layout.reduce(function(filtered, option) {
    if (option.Location == 'AREA_1') {
      filtered.push({
        ...option,
        i: option.YardAreaId ? option.YardAreaId : option.YardBlockId,
      });
    }
    return filtered;
  }, []);
  this.layoutCopyTwo = this.layout.reduce(function(filtered, option) {
    if (option.Location == 'AREA_3') {
      filtered.push({
        ...option,
        i: option.YardAreaId ? option.YardAreaId : option.YardBlockId,
      });
    }
    return filtered;
  }, []);

  this.AuxiliarBlockJson = this.AuxiliarBlock;
  this.RecognitionZoneJson = this.RecognitionZone;
}

//data
function data() {
  return {
    dimensionsAreaOne: {},
    dimensionsAreaTwo: {},
    mapDimensions: null,
    dragZone: '',
    currentHeightOne: 0,
    currentHeightTwo: 0,
  }
}

//Method
function positionSubmit (){
  this.$emit('positionSubmit');
}
function areaOne() {
  return {
    height: this.dimensionsAreaOne ? this.dimensionsAreaOne.height+'px' : '0px',
    width: this.dimensionsAreaOne ? this.dimensionsAreaOne.width+'px' : '0px',
    top: this.dimensionsAreaOne && this.mapDimensions? (this.dimensionsAreaOne.top-this.mapDimensions.top)+'px' : '0px',
    bottom: this.dimensionsAreaOne ? this.dimensionsAreaOne.bottom+'px' : '0px',
    left: this.dimensionsAreaOne && this.mapDimensions ? (this.dimensionsAreaOne.left-this.mapDimensions.left)+'px' : '0px',
    right: this.dimensionsAreaOne ? this.dimensionsAreaOne.right+'px' : '0px',
  }
}
function areaTwo() {
  return {
    height: this.dimensionsAreaTwo ? this.dimensionsAreaTwo.height+'px' : '0px',
    width: this.dimensionsAreaTwo ? this.dimensionsAreaTwo.width+'px' : '0px',
    top: this.dimensionsAreaTwo && this.mapDimensions? (this.dimensionsAreaTwo.top-this.mapDimensions.top)+'px' : '0px',
    bottom: this.dimensionsAreaTwo ? this.dimensionsAreaTwo.bottom+'px' : '0px',
    left: this.dimensionsAreaTwo && this.mapDimensions ? (this.dimensionsAreaTwo.left-this.mapDimensions.left)+'px' : '0px',
    right: this.dimensionsAreaTwo ? this.dimensionsAreaTwo.right+'px' : '0px',
  }
}

function iconSrc(src) {
  return `${this.$store.getters["connection/getBase"]}${src}`;
}

function getCharacterSvg(character) {
  return GenerateSvgPlaceholder({
    width: 50,
    height: 30,
    bgColor: 'transparent',
    textColor: this.print ? '#000':'#fff',
    fontSize: 35,
    fontFamilty: 'Roboto',
    text: `${character}`
  });
}
function setObserver() {
  let idSvg = this.print ? 'COVER_WAREHOUSE_PDF' : 'COVER_WAREHOUSE';

  const resizeObserver = new ResizeObserver(() => {
    if(document.querySelector('#AREA_1')) {
      this.mapDimensions = document.querySelector('.map-yard').getBoundingClientRect();
      this.dimensionsAreaOne = document.querySelector('#AREA_1').getBoundingClientRect();
      this.$nextTick(() => {
        document.querySelector('#areaOne .vue-grid-layout').style.maxHeight = this.dimensionsAreaOne.height+'px';
        document.querySelector('#areaOne .vue-grid-layout').style.minHeight = this.dimensionsAreaOne.height+'px';
  
        this.currentHeightOne = this.dimensionsAreaOne.height / 28;
      });
    }
    if(document.querySelector('#AREA_3')) {
      this.mapDimensions = document.querySelector('.map-yard').getBoundingClientRect();
      this.dimensionsAreaTwo = document.querySelector('#AREA_3').getBoundingClientRect();
      this.$nextTick(() => {
        document.querySelector('#areaTwo .vue-grid-layout').style.maxHeight = this.dimensionsAreaTwo.height+'px';
        document.querySelector('#areaTwo .vue-grid-layout').style.minHeight = this.dimensionsAreaTwo.height+'px';
  
        this.currentHeightTwo = this.dimensionsAreaTwo.height / 28;
      });
    }
  });

  resizeObserver.observe(document.getElementById(idSvg));
}
function onDrop(evt, divId) {
  this.$store.state.yardsetting.dragging = false;
  let area = evt.dataTransfer.getData('area');
  let type = evt.dataTransfer.getData('type');
  let atype = evt.dataTransfer.getData('atype');
  this.layoutCopyOne = this.layoutCopyOne.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
  this.layoutCopyTwo = this.layoutCopyTwo.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
  /*let item = this.layoutCopyOne.find(layout => layout.i == area)
  if ((item.y*14.85)+(item.h*14.84)<=1596.51) {
    let current = this.getCurrentLayout(divId);
    this.$emit('update-layout', area, divId, atype, current);
  }*/
  let current = this.getCurrentLayout(divId);
  this.$emit('update-layout', area, divId, atype, current);
}

function rotateStart(e, item) {
  
}

function removeFromZone(item, arr) {
  let found = -1;
  if(item.YardAreaId) {
    switch (arr) {
      case 1:
        found = this.layoutCopyOne.findIndex((it) => it.YardAreaId == item.YardAreaId);
        if(found != -1) {
          this.layoutCopyOne.splice(found, 1);
        }
        break;
      case 2:
        found = this.layoutCopyTwo.findIndex((it) => it.YardAreaId == item.YardAreaId);
        if(found != -1) {
          this.layoutCopyTwo.splice(found, 1);
        }
        break;
      case 3:
        found = this.layoutCopyThree.findIndex((it) => it.YardAreaId == item.YardAreaId);
        if(found != -1) {
          this.layoutCopyThree.splice(found, 1);
        }
        break;
      default:
        break;
    }
  }

  if(item.YardBlockId) {
    switch (arr) {
      case 1:
        found = this.layoutCopyOne.findIndex((it) => it.YardBlockId == item.YardBlockId);
        if(found != -1) {
          this.layoutCopyOne.splice(found, 1);
        }
        break;
      case 2:
        found = this.layoutCopyTwo.findIndex((it) => it.YardBlockId == item.YardBlockId);
        if(found != -1) {
          this.layoutCopyTwo.splice(found, 1);
        }
        break;
      case 3:
        found = this.layoutCopyThree.findIndex((it) => it.YardBlockId == item.YardBlockId);
        if(found != -1) {
          this.layoutCopyThree.splice(found, 1);
        }
        break;
      default:
        break;
    }
  }
  this.$emit("remove", item);
}

function moveItem(i, newX, newY){
  this.dragZone = 'areaOne';
}

function movedItem(i, newX, newY){
  this.dragZone = '';
  this.layoutCopyOne = this.layoutCopyOne.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
}

function moveItemTwo(i, newX, newY){
  this.dragZone = 'areaTwo';
}

function movedItemTwo(i, newX, newY){
  this.dragZone = '';
  this.layoutCopyTwo = this.layoutCopyTwo.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
}

function resizedEvent(i, newH, newW, newHPx, newWPx) {
  this.layoutCopyOne = this.layoutCopyOne.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
}

function resizedEventTwo(i, newH, newW, newHPx, newWPx) {
  this.layoutCopyTwo = this.layoutCopyTwo.filter(arr => {
    if ((arr.y)+(arr.h)<=28) {
      return true
    }else{
      this.$emit("remove", arr)
      return false;
    }
  })
}

function orderStackItems(item) {
  item.FgBlockOrder = item.FgBlockOrder ? 0 : 1;
}

function orderBayItems(item) {
  item.FgBayOrder = item.FgBayOrder ? 0 : 1;
}

function setAngle(item, angle, area) {
  let found = -1;
  switch (area) {
    case 1:
      if(item.YardAreaId)
        found = this.layoutCopyOne.findIndex((it) => it.YardAreaId == item.YardAreaId);      
      else if(item.YardBlockId)
        found = this.layoutCopyOne.findIndex((it) => it.YardBlockId == item.YardBlockId);
      if(found != -1) {
        this.layoutCopyOne[found].a = angle;
      }
      break;
    case 2:
      if(item.YardAreaId)
        found = this.layoutCopyTwo.findIndex((it) => it.YardAreaId == item.YardAreaId);      
      else if(item.YardBlockId)
        found = this.layoutCopyTwo.findIndex((it) => it.YardBlockId == item.YardBlockId);
      if(found != -1) {
        this.layoutCopyTwo[found].a = angle;
      }
      break;
    case 3:
      if(item.YardAreaId)
        found = this.layoutCopyThree.findIndex((it) => it.YardAreaId == item.YardAreaId);      
      else if(item.YardBlockId)
        found = this.layoutCopyThree.findIndex((it) => it.YardBlockId == item.YardBlockId);
      if(found != -1) {
        this.layoutCopyThree[found].a = angle;
      }
      break;
    default:
      break;
  }
}

function isCoverWareHouse(item) {
  return item.TpYardAreaId == process.env.VUE_APP_TP_YARD_AREA_ID_MODULE
}
//computed

//watch

export default {
  name: 'yards-four',
  mixins:[YardGridMixin],
  components: {
    GridLayout,
    GridItem,
    YardGrid,
    YardSlotModal,
    PositionModal
  },
  props: {
    layout: {
      type: Array,
      default: () => [],
    },
    print: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: '',
    },
    preferences: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 100,
    },
    AuxiliarBlock: {
      type: Array,
      default: () => []
    },
    RecognitionZone: {
      type: Array,
      default: () => []
    },
  },
  data,
  directives: {
    dragscroll
  },
  created,
  mounted,
  methods: {
    iconSrc,
    getCharacterSvg,
    setObserver,
    onDrop,
    rotateStart,
    removeFromZone,
    moveItem,
    moveItemTwo,
    movedItem,
    movedItemTwo,
    resizedEvent,
    resizedEventTwo,
    orderStackItems,
    orderBayItems,
    setAngle,
    positionSubmit,
    isCoverWareHouse,
  },
  computed: {
    areaOne,
    areaTwo,
    ...mapState({
      submitState: state => state.yardsetting.isSubmit,
      submitStateEdit: state => state.yardsetting.isSubmitEdit,
      printing: state => state.yardsetting.printing,
      isDragging: state => state.yardsetting.dragging,
    })
  },
  watch: {
    layout: {
      handler: function (val, oldVal) {
        this.layoutCopyOne = this.updateCopy(val.filter((item) => item.Location == 'AREA_1'));
        this.layoutCopyTwo = this.updateCopy(val.filter((item) => item.Location == 'AREA_3'));
      },
      deep: true
    },
    submitState: function(newVal, oldVal) {
      if(newVal && !oldVal) {
        this.$emit("submited", [...this.layoutCopyOne, ...this.layoutCopyTwo, ...this.layoutCopyThree]);
      }
    },
    submitStateEdit: function(newVal, oldVal) {
      if(newVal && !oldVal) {
        this.$emit("edited", [...this.layoutCopyOne, ...this.layoutCopyTwo, ...this.layoutCopyThree]);
      }
    },
    printing: function(newVal, oldVal) {
      if(newVal && !oldVal) {
        this.$emit("print", [...this.layoutCopyOne, ...this.layoutCopyTwo, ...this.layoutCopyThree]);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../css/rotate.scss';
.map-yard {
  width: 100%;

  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-pdf{
  width: 100%;
}
.zone-border { 
  border: 2px dashed #ec631b !important;
}
.center-map-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
}
#COVER_WAREHOUSE{
  width: 90%;
}

#areaOne,
#areaTwo,
#areaThree {
  // overflow: hidden;
  position: absolute;
  /* background-color: #000; */
}

.box {
  display: flex;
}

.sidebar {
  background-color: #d6d6d6;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}
.droppable-element {
    width: 150px;
    text-align: center;
    background: #dde9ff;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
    cursor: grab;
}
.vue-grid-layout {
    background: transparent;
}
.vue-grid-item:not(.vue-grid-placeholder) { /*Estilos del rectangulo*/
    /* background: #ccc; */
    /* border: 1px solid black; */
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vue-grid-item.vue-grid-placeholder {
    background: #ec631b !important;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 16px;
    text-align: center;
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%; */
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
.vue-grid-item {
  touch-action: none;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

.controls-option {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);

  display: flex;
  flex-direction: column;

  .control {
    cursor: pointer;
    color: #fff;
    margin-bottom: 2px;
    &:active{
      background-color: #1a3760;
      border-radius: 2px;
    }
    &.remove {
      color: #e1373f;
      margin-bottom: 2px;
    }
  }
}

.item-label-black {
  color: #000 !important;
}
.border-black {
  border: 1px solid #000 !important;
}
// AREAS
.area {
  // margin: 0 5px;
  min-height: 60px;						
  width:	100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  .item {
    width: 100%;
    min-height: 44px;
    // background-color: #214774;
    // border-radius: 5px;
    // border: 3px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    .icon {
      width: 17%;
      position: absolute;
      top: 20%;
    }
    svg {
      width: 33%;
    }
  }
  .item-label {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
  }
  // .item {
  //   width: 100%;
  //   min-height: 44px;
  //   background-color: #214774;
  //   border-radius: 5px;
  //   border: 3px solid #000;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .icon {
  //     max-width: 25px;
  //   }
  // }
  // .item-label {
  //   font-size: 10px;
  //   font-weight: bold;
  //   color: #fff;
  // }
}

.block {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  // border: 1px solid #bbbbbb;
  // border-radius: 2px;

  .code {
    width: 10%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle {
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
    }
    .line {
      border: 1px solid #fff;
      flex: 1;
    }

  }
  .grid {
    width: 90%;
    height: 100%;
    position: relative;
  }
}

.center-icon { /* Center a.svg */

position: absolute;
width: 45%;
height: 45%;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-moz-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
-o-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}

// SVG
.cls-1 {
    fill: #cdcdcd
}

.cls-2 {
    fill: #898989
}

.cls-7,
.cls-9 {
    stroke-miterlimit: 10
}

.cls-5 {
    fill: #e8e8e8
}

.cls-6 {
    fill: #eebd2c
}

.cls-7,
.cls-9 {
    fill: none
}

.cls-7 {
    stroke: #23210d;
    stroke-linecap: round
}

.cls-8 {
    fill: #877d08
}

.cls-9 {
    stroke: #757373;
    stroke-width: .28px
}

.cls-10 {
    fill: #757373
}

.cls-11 {
    fill: none;
    stroke:#898989;
    stroke-width: 1px;
}

.cls-1print,
.cls-2print {
    fill: #fff;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: .57px
}

.cls-2print {
    fill: none
}

.cls-3print {
    fill: none;
    stroke:#fff;
    stroke-width: 1px;
}
</style>
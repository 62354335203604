<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasYardInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="img-yard">
                <img :src="hasYardImg" alt="YARD" srcset="" />
              </div>
              <div class="yard">
                <table>
                  <tbody>
                    <tr>
                      <td colspan="2" class="center-text">
                        <strong>{{ titleDataSheet }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>AREA: </strong
                        >{{ formatNumber(yardInfo.SquareMeters) }}&nbsp;M2
                      </td>
                      <td>
                        <strong>{{ $t('label.yardGroup') }}: </strong
                        >{{ getDato(yardInfo.Alfa) }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{{ isCoverWareHouse ? $t('label.module') : 'TEUS' }}: </strong
                        >{{ getDato(isCoverWareHouse ? '' : teus) }}
                      </td>
                      <td>
                        <strong>{{ isCoverWareHouse ? $t('label.nroAccess') : $t('label.capStatic') }}: </strong
                        >{{ isCoverWareHouse ? '' : getDato(yardInfo.StaticCapacity) }}
                      </td>
                    </tr>
                    <tr v-if="!isCoverWareHouse">
                      <td>
                        <strong>{{ $t('label.nroAccess') }}: </strong
                        >{{ getDato(yardInfo.AccesNumber) }}
                      </td>
                      <td>
                        <strong>{{ $t('label.operationalCapacity') }}: </strong
                        >{{ getDato(yardInfo.OperationalCapacity) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="info-yard">
                <div class="yard-plan">
                  <YardSeventeen
                    v-if="getCurrentYard == 'C6E16D79-982D-4BFC-86B0-FF10251A5635'"
                    :layout="layoutList"
                    print
                  />
                  <YardTwelve
                    v-if="getCurrentYard == '35E02EE5-8478-4AA2-8EE0-41817EF91B4D'"
                    :layout="layoutList"
                    print
                  />
                  <YardFour
                    v-if="getCurrentYard == 'C62F4B69-011F-ED11-A87E-000D3A0DEDC7'"
                    :layout="layoutList"
                    print
                  />
                  <YardThirteen
                    v-if="getCurrentYard == 'F6513E84-8D61-4D20-8CCD-429AAEC6DDF4'"
                    :layout="layoutList"
                    print
                  />
                  <CoverWarehouse
                    v-if="isCoverWareHouse"
                    :layout="layoutList"
                    print
                  />
                  <GenericYard
                    v-if="yardInfo.FgGenericYard"
                    :layout="layoutList"
                    print
                  />
                </div>
                <div v-if="!isCoverWareHouse" class="legend">
                  <div class="area" v-for="area in areas" :key="area.YardAreaId">
                    <div class="item">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 40.74">
                        <path d="M41.64 16.27c0 9.78-7.43 12.72-11.45 18.91a18.68 18.68 0 0 0-2.69 7.69 20.52 20.52 0 0 0-2.92-7.69C20.26 29 13.36 26.26 13.36 16.27a14.14 14.14 0 1 1 28.28 0Z" data-name="Capa 2" :style="{fill: area.TpYardAreaColor}" transform="translate(-13.36 -2.13)"/>
                      </svg>
                      <CIcon class="icon" :src="iconSrc(area.TpYardAreaIconRoute)" size="sm" v-if="area.TpYardAreaIconRoute"/>
                    </div>
                    <div class="item-label">
                      {{ area.areaName }}
                    </div>
                  </div>
                  <div class="area area-border" v-for="leg in formatedLegend" :key="leg.YardSlotStatusId">
                    <div class="item">
                      <div :class="leg.Class || null"></div>
                    </div>
                    <div class="item-label">
                      {{ ' '}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataStorage from "@/_helpers/localStorage";
import YardSeventeen from '../yards/yard-seventeen';
import YardTwelve from '../yards/yard-twelve';
import YardFour from '../yards/yard-four';
import YardThirteen from '../yards/yard-thirteen';
import CoverWarehouse from '../yards/cover-warehouse';
import GenericYard from '../yards/pa-gen';
import { NumberFormater } from '@/_helpers/funciones';

//Data
function data() {
  return {
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function getDato(value) {
  if (!this.yardInfo) return "";

  return value ? value : "";
}

// function formatNumber(number) {
//   let format = Number.parseFloat(number).toFixed(2);
//   format = format.replace('.', ',');

//   return format.replace(/\d(?=(\d{3})+\,)/g, '$&.');
// }
function iconSrc(src) {
  return `${this.$store.getters["connection/getBase"]}${src}`;
}
function getYardSlotName(item) {
  return this.$i18n.locale == 'es' ? item.YardSlotStatusNameEs : item.YardSlotStatusNameEn
}
function formatNumber(num) {
  if (!this.yardInfo) return "";
  if(!num) return '';
  return NumberFormater.formatNumber(num, 2);
}

//Computeds:
function hasYardInfo() {
  return this.yardInfo ? true : false;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasYardImg() { //Change
  return `${this.$store.getters["connection/getBase"]}${
    this.yardInfo
      ? this.yardInfo.YardProfileRoute ? this.yardInfo.YardProfileRoute.replace("Public/", "", null, "i") : ""
      : ""
  }`;
}
function footerText() {
  return "";
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function titleDataSheet() {
  if (!this.yardInfo) return `${this.$t("label.mainFeatures")}`;

  let name = this.yardInfo.YardName;

  return `${name} - ${this.$t("label.mainFeatures")}`;
}
function totalPages() {
  return 1;
}
function getCurrentYard() {
  if(!this.yardInfo) return "";

  return this.yardInfo.YardId;
}
function formatedLegend(){
  return this.legend.filter((item) => item.Class != null);
}
function isCoverWareHouse() {
  return this.yardInfo?.YardTpId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE
}

export default {
  name: "yard-pdf",
  props: {
    yardInfo: {
      type: Object,
      default: null,
    },
    layoutList: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    areas: {
      type: Array,
      default: () => [],
    },
    teus: {
      type: Number,
      default: 0
    }
  },
  mixins: [],
  components: {
    YardSeventeen,
    YardTwelve,
    YardThirteen,
    YardFour,
    GenericYard,
    CoverWarehouse,
  },
  data,
  created,
  methods: {
    getDato,
    formatNumber,
    iconSrc,
    getYardSlotName,
  },
  computed: {
    footerText,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    hasLogo,
    hasYardImg,
    loginName,
    hasYardInfo,
    titleDataSheet,
    totalPages,
    getCurrentYard,
    formatedLegend,
    isCoverWareHouse,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
  },
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 650px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-yard,
.pdf .body .general-info .info-content .info-yard {
  height: 22%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-yard {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-yard img {
  height: 100%;
}
.info-content .info-yard {
  width: 100%;
  background-color: transparent;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #000; */
  height: 77% !important;
  margin-top: 10px;
}
.info-content .yard {
  width:78%;
  height: 22%;
  display: flex;
  align-items: flex-start;
}
.info-content .yard .center-text {
  text-align: center;
}
.info-content .yard > table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
}
.yard > table,
.yard > table td {
  border: 1px solid #000;
}
.yard > table td {
  padding: 2px 5px;
  width: 50%;
}
.info-content .yard > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .yard > table td > table td {
  border: 1px solid black;
}

.info-content .info-yard .yard-plan {
  /* border: 1px solid #000; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.info-content .info-yard .yard-plan .yard-svg {
  height: 95%;
}

.info-content .info-yard .legend {
  /* width: 80%; */
  /* height: 70px; */
  height: 40px;
  padding: 5px 10px;
  margin-right: auto;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 5px;
}
.info-yard .legend .area {
  /* min-height: 60px;						 */
  /* width:	calc((100% / 12) - 10px); */
  min-height: 15px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info-yard .legend .area .item {
  width: 100%;
  /* min-height: 44px; */
  min-height: 25px;
  /* filter: brightness(0%); */
  /* border-radius: 5px; */
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.info-yard .legend .area .item svg{
  width: 50%;
}
.info-yard .legend .area .item .icon {
  max-width: 30px;
  position: absolute;
  width: 28%;
  top: 14%;
}
.info-yard .legend .area .item-label {
  font-size: 7px;
  font-weight: bold;
  color: #000;
}

.area-border {
  border: 1px solid #000;
}

/* Iconos */
.flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  
  .imdg {
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    border: 1px solid red;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .high-cube,
  .hc-high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .high-cube-45,
  .footer-45 {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #373737;
  }
  .reefer-high-cube,
  .refrigerado-high-cube,
  .rt-refrigerado-high-cube  {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .disabled-position {
  background-image: url('/img/yards/blocks/DISABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.reefer-plug-status {
  background-image: url('/img/yards/blocks/RIFEL-PLUG-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-available {
  background-image: url('/img/yards/blocks/HAZARD-AVAILABLE-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hazard-restriction {
  background-image: url('/img/yards/blocks/HAZARD-RESTRINGED-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.special-container {
  background-image: url('/img/yards/blocks/SPECIAL-CONTAINER-NEGRO.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
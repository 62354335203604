<template>
    <CRow :style="rowStyles">
      <yard-slot-modal :modal.sync="modal" :container="containerData" :ContainerList="ContainerList" :ShowList="ShowList"/>
      <position-modal :modal.sync="modalPosition" :positionData="positionData" @submited="positionSubmit"/>
      <CCol sm="12" :class="{'center-map-pdf': print ? true: null, 'overflow-zoom': zoom != 100? true : null}" v-dragscroll="zoom != 100">
        <div class="map-yard" :class="{'map-pdf': print ? true: null}" :style="mapYardStyles">  
          <svg v-if="!print" id="PA_GEN" data-name="PA GEN" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1245.8 913.5" style="enable-background:new 0 0 1245.8 913.5;" xml:space="preserve">
            <g id="PA-GEN">
                <g id="FONDO_PATIO">
                    <path class="st0" d="M37.3,0h1176.6c14.2,0,25.8,11.6,25.8,25.8v861.9c0,14.2-11.6,25.8-25.8,25.8H37.3
                c-14.2,0-25.8-11.6-25.8-25.8V25.8C11.5,11.6,23.1,0,37.3,0z" />
                    <rect x="32.7" y="23.6" class="st1" width="1182.3" height="863.4" />
                    <path class="st2" d="M300,123.1" />
                    <path class="st2" d="M463,120.5" />
                    <path class="st2" d="M319.3,120.5" />
                </g>
                <g id="PERIMETRO">
                    <polygon class="st3" points="1193.5,345.5 1193.2,361.6 1182.2,346.1 1182.2,43.7 67.5,43.7 67.5,321.4 56.5,308.3 56.2,32.3 
                1193.5,32.3 		" />
                    <polygon class="st3" points="1193.5,871.7 56.2,871.7 55.2,409.5 66,420.9 67.5,860.3 1182.2,860.3 1182.2,468.9 1193.3,482.8 		
                " />
                    <g id="ACCESO_VEHICULAR">
                        <path class="st4" d="M1181.7,465.9c-0.3,0-0.6,0.2-0.8,0.4c-0.7,0.7-0.8,1.8-0.2,2.6l10.6,14c0.5,0.7,1.5,0.9,2.3,0.4l0,0
                    l0.1-0.1c0.7-0.7,0.8-1.8,0.2-2.6l-10.6-14C1182.9,466.1,1182.3,465.8,1181.7,465.9z" />
                        <line class="st5" x1="1191" y1="482" x2="1191.1" y2="477.5" />
                        <line class="st5" x1="1188.2" y1="478.3" x2="1188.4" y2="474" />
                        <line class="st5" x1="1185.4" y1="474.6" x2="1185.5" y2="470.1" />
                        <line class="st5" x1="1183" y1="471.4" x2="1183.2" y2="467" />
                        <path class="st6" d="M1183.5,466.3l10.6,14c0.7,1,0.6,2.3-0.3,3.1c-0.8,0.7-2,0.7-2.8-0.1l0,0l-0.2-0.2l-10.6-14
                    c-0.7-1-0.6-2.3,0.3-3.1c0.3-0.3,0.7-0.4,1.1-0.5C1182.3,465.4,1183.1,465.7,1183.5,466.3z M1193.3,482.8c0.6-0.5,0.6-1.4,0.2-2
                    l-10.6-14c-0.4-0.6-1.2-0.7-1.8-0.3c0,0,0,0,0,0l0,0c-0.6,0.5-0.6,1.4-0.2,2l10.6,14c0.2,0.3,0.5,0.5,0.9,0.5h0.3
                    C1192.9,483,1193.2,483,1193.3,482.8z" />
                        <path class="st7" d="M1235.5,467.4l-53.4,1.5l9.3,11.8l51.5-1.4c0.7,0,1.3-0.6,1.3-1.3c0-0.3-0.1-0.6-0.3-0.8l-7.4-9.4
                    C1236.2,467.5,1235.9,467.4,1235.5,467.4z" />
                        <path class="st8" d="M1243.2,476.7l-0.5-0.3l0.1-0.1l-0.3-0.3l-0.2,0.2l-1.5-1.1l0.6-0.6l-0.3-0.3l-0.7,0.7l-1.3-0.9l0.9-1
                    l0.5,0.4l-0.8-1.1l-0.7-0.5l0.2-0.2l-0.3-0.3l-0.3,0.3l-1.3-0.9l0.6-0.6l-0.3-0.3l-0.7,0.7l-1.1-0.8l1-1l-0.3-0.3l-1.1,1.1
                    l-1.3-0.9l0.8-0.9h-0.6l-0.6,0.6l-0.9-0.6h-0.9l-0.8,0.9l-1.2-0.8h-0.8l-0.8,0.9l-1.1-0.7l0.1-0.1h-0.9l0.2,0.1l-1,1l-1.2-0.8
                    l0.3-0.3h-1.4l0.5,0.3l-1,1.1l-1.1-0.8l0.6-0.6h-0.6l-0.3,0.3l-0.4-0.3h-0.7l0.9,0.6l-1,1l-1.1-0.8l0.8-0.8h-0.6l-0.5,0.5
                    l-0.8-0.5h-1l-0.8,0.8l-1.2-0.8h-0.7l-0.8,0.9l-1-0.7l0.1-0.1h-1l0.3,0.2l-1,1.1l-1.1-0.7l0.4-0.5h-0.6l-0.2,0.2l-0.3-0.2h-0.7
                    l0.7,0.5l-0.8,0.9l-1.2-0.8l0.5-0.5h-0.6l-0.3,0.3l-0.4-0.3h-0.7l0.8,0.6l-0.9,1l-1.2-0.5l0.7-0.7h-0.6l-0.4,0.5l-0.7-0.5h-0.7
                    l1.1,0.7l-0.9,1l-1.2-0.8l0.7-0.8l-0.1-0.1h-0.3l-0.6,0.7l-0.9-0.6h-0.7l1.3,0.9l-1,1.1l-1-0.7l0.9-1l-0.3-0.3l0,0l-1,1l-1.1-0.7
                    l0.2-0.2h-1.2l0.4,0.3l-0.9,0.9l-1.4-1l0.2-0.2h-1.1l0.3,0.2l-1.1,1.1l-1.2-0.8l0.5-0.5h-0.6l-0.2,0.2l-0.3-0.2h-0.7l0.7,0.5
                    l-0.9,0.9l-1.3-0.9l0.5-0.5h-0.6l-0.3,0.3l-0.3-0.2h-0.7l0.8,0.5l-1,1.1l-1.4-1l0.6-0.6h-0.6l-0.3,0.3l-0.4-0.3h-0.7l0.9,0.6
                    l-0.9,0.9l-1.6-1.1l0.9,1.1l0.4,0.3l-0.1,0.1l0.3,0.3l0.2-0.2l1.4,1l-0.5,0.5l0.3,0.3l0.6-0.6l1.3,0.9l-0.9,1l0.3,0.3l1-1.1
                    l1.2,0.8l-1.1,1.2l-0.8-0.5l0.9,1.1l1.3,0.9l-0.3,0.3l0.3,0.3l0.4-0.4l1,0.8l-0.7,0.7l0.3,0.3l0.8-0.8l1,0.7l-0.8,0.8l-0.9-0.6
                    l0.9,1.1l0,0l0.2,0.1h0.7l-0.6-0.4l0.8-0.8l1.2,0.8l-0.4,0.4h0.6l0.2-0.2l0.2,0.1h0.7l-0.6-0.4l1.1-1.2l1.2,0.8l-0.7,0.7h0.6
                    l0.4-0.5l0.6,0.5h0.7l-1-0.7l1-1.1l1.1,0.8l-1,1h0.6l0.7-0.8l1,0.7h0.7l1-1.1l1,0.7l-0.4,0.4h0.6l0.2-0.2l0.2,0.1h0.7l-0.6-0.4
                    l0.9-0.9l1.2,0.9l-0.5,0.5h0.6l0.2-0.2l0.3,0.2h0.7l-0.8-0.5l1.1-1.1l1,0.7l-0.9,1h0.6l0.7-0.7l1,0.7h0.8l0.8-0.9l1.1,0.8h0.8
                    l-0.1-0.1l1-1.1l1.2,0.8l-0.3,0.3h1.3l-0.4-0.3l0.9-1l1,0.7l-0.5,0.5h0.6l0.3-0.3l0.4,0.3h0.7l-0.8-0.5l1-1l1.3,0.9l-0.6,0.6h0.6
                    l0.4-0.4l0.5,0.4h0.7l-0.9-0.6l0.8-0.9l1.1,0.7l-0.7,0.8h0.6l0.5-0.5l0.7,0.5h0.7l-1.1-0.8l1-1.1l1.3,0.9l-0.9,1h0.6l0.7-0.7
                    l1,0.7h1l0.7-0.7l1,0.7h1.1l0.7-0.8l1,0.6l-0.1,0.1h0.9l-0.2-0.1l0.9-1l1.3,0.9l-0.2,0.2h1.1l-0.3-0.2l1-1.1l1.5,1l-0.2,0.2h1.1
                    l-0.3-0.2l1-1l1.2,0.8l-0.4,0.4h0.6l0.2-0.2l0.2,0.2c0.2,0,0.3-0.1,0.5-0.2l-0.4-0.3l0.8-0.9c0-0.1-0.1-0.2-0.1-0.3L1243.2,476.7
                    z M1238.4,477.7l1-1l1.5,1.1l-1,1L1238.4,477.7z M1238.1,477.4l-1.3-0.9l1-1l1.3,0.9L1238.1,477.4z M1235.1,475.4l-1.3-0.9l1-1
                    l1.3,0.9L1235.1,475.4z M1236.4,474.6l1,0.6l-1,1l-1-0.6L1236.4,474.6z M1233.4,474.3l-1.2-0.8l1-1l1.2,0.8L1233.4,474.3z
                    M1234.8,475.7l-1,1.1l-1.3-0.9l1-1.1L1234.8,475.7z M1232.2,475.7l-1.2-0.8l1-1l1.2,0.8L1232.2,475.7z M1230.6,474.7l-1.3-0.9
                    l1-1l1.3,0.9L1230.6,474.7z M1231.9,476l-0.9,1l-1.2-0.8l1-1L1231.9,476z M1229.4,476l-1.3-0.9l1-1l1.3,0.9L1229.4,476z
                    M1227.8,474.9l-1.1-0.7l1-1l1.1,0.7L1227.8,474.9z M1229.1,476.3l-0.9,1l-1.3-0.9l0.9-1L1229.1,476.3z M1226.6,476.1l-1.1-0.7
                    l0.9-1l1.1,0.7L1226.6,476.1z M1225.2,475.1l-1.3-0.9l0.9-1l1.3,0.9L1225.2,475.1z M1224.9,475.4l-0.8,0.9l-1.3-0.9l0.8-0.9
                    L1224.9,475.4z M1222.4,475.2l-1-0.7l0.8-0.9l1,0.7L1222.4,475.2z M1222.1,475.5l-1,1.1l-1.1-0.7l1-1.1L1222.1,475.5z
                    M1219.7,475.6l-1.2-0.8l1-1.1l1.2,0.8L1219.7,475.6z M1218.1,474.5l-1.1-0.8l1-1.1l1.1,0.8L1218.1,474.5z M1219.4,475.9
                    l-0.8,0.9l-1.2-0.8l0.8-0.9L1219.4,475.9z M1217,475.7l-1.1-0.8l0.8-0.9l1.1,0.8L1217,475.7z M1215.6,474.7l-1.1-0.8l0.8-0.9
                    l1.1,0.8L1215.6,474.7z M1216.7,476l-1,1l-1.1-0.8l1-1L1216.7,476z M1214.3,476l-1.1-0.8l1-1l1.1,0.8L1214.3,476z M1212.8,475
                    l-1-0.7l0.9-1l1,0.7L1212.8,475z M1214,476.3l-0.9,1l-1.1-0.8l0.9-1L1214,476.3z M1211.6,476.3l-1-0.7l0.9-1l1,0.7L1211.6,476.3z
                    M1210.3,475.4l-1.2-0.9l0.9-1l1.2,0.8L1210.3,475.4z M1211.3,476.6l-1,1.1l-1-0.7l1-1.1L1211.3,476.6z M1209,476.8l-1.2-0.9
                    l1-1.1l1.2,0.9L1209,476.8z M1207.4,475.7l-1-0.7l1-1.1l1,0.7L1207.4,475.7z M1207.1,476l-0.9,0.9l-1-0.7l0.9-0.9L1207.1,476z
                    M1204.9,476l-1.1-0.8l0.9-0.9l1.1,0.8L1204.9,476z M1204.6,476.3l-1.1,1.1l-1.1-0.7l1.1-1.1L1204.6,476.3z M1202.1,476.5
                    l-1.2-0.8l1.1-1.1l1.1,0.8L1202.1,476.5z M1201.8,476.8l-0.9,0.9l-1.1-0.8l0.9-0.9L1201.8,476.8z M1199.4,476.7l-1.2-0.8l0.9-0.9
                    l1.2,0.8L1199.4,476.7z M1197.9,475.6l-1.2-0.8l0.9-0.9l1.2,0.8L1197.9,475.6z M1197.6,475.9l-1,1.1l-1.2-0.8l1-1.1L1197.6,475.9
                    z M1195.1,476l-1-0.7l1-1.1l1,0.7L1195.1,476z M1194.8,476.3l-1,1l-1-0.7l1-1L1194.8,476.3z M1192.4,476.4l-1.1-0.8l1-1l1.1,0.7
                    L1192.4,476.4z M1191,475.4l-1.4-1l0.9-1l1.4,1L1191,475.4z M1190.9,473.1l1-1.1l1.4,1l-1,1.1L1190.9,473.1z M1193.6,473.2
                    l1.1,0.7l-1,1.1l-1.1-0.7L1193.6,473.2z M1193.9,472.9l0.9-0.9l1.1,0.7l-0.9,0.9L1193.9,472.9z M1196.2,472.9l1.1,0.7l-0.9,0.9
                    l-1-0.7L1196.2,472.9z M1196.5,472.6l1.1-1.1l1,0.7l-1.1,1.1L1196.5,472.6z M1199,472.4l1.2,0.8l-1.1,1.1l-1.2-0.8L1199,472.4z
                    M1200.5,473.4l1.2,0.8l-1.1,1.1l-1.2-0.8L1200.5,473.4z M1200.8,473.1l0.9-0.9l1.2,0.8l-0.9,0.9L1200.8,473.1z M1203.2,473.2
                    l1.2,0.8l-0.9,0.9l-1.1-0.8L1203.2,473.2z M1203.5,472.9l1-1.1l1.1,0.8l-1,1.1L1203.5,472.9z M1206,472.9l1.1,0.7l-1,1.1
                    l-1.1-0.7L1206,472.9z M1206.3,472.6l0.9-1l1.1,0.8l-0.9,1L1206.3,472.6z M1208.7,472.6l1,0.7l-0.9,1l-1-0.7L1208.7,472.6z
                    M1209,472.3l1-1l1,0.7l-1,1L1209,472.3z M1211.2,472.2l1.2,0.9l-1,1l-1.2-0.9L1211.2,472.2z M1211.5,471.9l0.8-0.9l1.2,0.9
                    l-0.8,0.9L1211.5,471.9z M1213.9,472.1l1,0.7l-0.8,0.9l-1-0.7L1213.9,472.1z M1214.2,471.8l1-1.1l1,0.7l-1,1.1L1214.2,471.8z
                    M1216.6,471.6l1.1,0.8l-1,1.1l-1.1-0.8L1216.6,471.6z M1216.8,471.3l0.8-0.9l1.1,0.8l-0.8,0.9L1216.8,471.3z M1219.2,471.5
                    l1.1,0.8l-0.8,0.9l-1.1-0.8L1219.2,471.5z M1220.6,472.5l1.2,0.8l-0.8,0.9l-1.2-0.8L1220.6,472.5z M1220.9,472.2l0.9-1l1.2,0.8
                    l-0.9,1L1220.9,472.2z M1223.4,472.3l1.1,0.7l-0.9,1l-1-0.8L1223.4,472.3z M1223.7,472l1-1l1.1,0.7l-1,1L1223.7,472z M1226,471.9
                    l1.3,0.9l-1,1l-1.3-0.9L1226,471.9z M1226.3,471.6l1-1l1.3,0.9l-1,1.1L1226.3,471.6z M1228.9,471.7l1.1,0.7l-1,1.1l-1.1-0.7
                    L1228.9,471.7z M1229.2,471.4l1-1l1.1,0.7l-1,1L1229.2,471.4z M1231.6,471.3l1.3,0.9l-1,1l-1.3-0.9L1231.6,471.3z M1231.9,471
                    l0.9-0.9l1.3,0.9l-0.9,0.9L1231.9,471z M1234.4,471.2l1.1,0.8l-0.9,0.9l-1.2-0.8L1234.4,471.2z M1235.9,472.2l1.3,0.9l-0.9,0.9
                    l-1.3-0.9L1235.9,472.2z M1237.5,473.4l1,0.7l-0.9,0.9l-1-0.7L1237.5,473.4z M1235.5,477.9l1-1.1l1.3,0.9l-1,1.1L1235.5,477.9z
                    M1235.1,477.7l-1-0.6l1-1.1l1,0.6L1235.1,477.7z M1233,478.3l0.9-1l1,0.7l-0.9,1L1233,478.3z M1232.6,478.1l-1.3-0.9l0.9-1
                    l1.3,0.9L1232.6,478.1z M1230.1,478.5l0.9-1l1.3,0.9l-0.9,1L1230.1,478.5z M1229.8,478.2l-1.3-0.9l0.9-1l1.2,0.8L1229.8,478.2z
                    M1227.4,478.6l0.8-0.9l1.3,0.9l-0.8,0.9L1227.4,478.6z M1225.8,477.5l0.8-0.9l1.3,0.9l-0.8,0.9L1225.8,477.5z M1225.6,477.2
                    l-1.1-0.7l0.8-0.9l1.1,0.7L1225.6,477.2z M1223.2,477.9l1-1.1l1.1,0.7l-1,1.1L1223.2,477.9z M1222.8,477.6l-1.3-0.9l1-1.1
                    l1.3,0.9L1222.8,477.6z M1220.4,477.9l0.8-0.9l1.3,0.9l-0.8,0.9L1220.4,477.9z M1220.1,477.7l-1-0.7l0.8-0.9l1,0.7L1220.1,477.7z
                    M1217.8,478.2l1-1l1.1,0.7l-1,1L1217.8,478.2z M1217.5,478l-1.2-0.8l0.9-1l1.2,0.8L1217.5,478z M1215,478.5l0.9-1l1.2,0.8l-1,1
                    L1215,478.5z M1214.7,478.2l-1.1-0.8l0.9-1l1.1,0.8L1214.7,478.2z M1212.3,478.8l1-1.1l1.1,0.8l-1,1.1L1212.3,478.8z
                    M1211.9,478.6l-1.1-0.8l1-1.1l1.1,0.8L1211.9,478.6z M1209.6,479l0.9-0.9l1.1,0.8l-0.9,0.9L1209.6,479z M1208.3,478.1l0.9-0.9
                    l1,0.7l-0.9,0.9L1208.3,478.1z M1208,477.9l-1.2-0.9l0.9-0.9l1.2,0.8L1208,477.9z M1205.3,478.5l1.1-1.1l1.2,0.8l-1.1,1.1
                    L1205.3,478.5z M1205,478.3l-1-0.7l1.1-1.1l1,0.7L1205,478.3z M1202.8,478.8l0.9-0.9l1,0.7l-0.9,0.9L1202.8,478.8z M1202.5,478.6
                    l-1.1-0.7l0.9-0.9l1.1,0.7L1202.5,478.6z M1200.1,479.3l1.1-1.1l1.1,0.7l-1.1,1.1L1200.1,479.3z M1198.6,478.3l1-1.1l1.2,0.8
                    l-1.1,1.1L1198.6,478.3z M1197.1,477.2l1-1.1l1.2,0.8l-1,1.1L1197.1,477.2z M1195.8,478.6l1-1.1l1.2,0.8l-1,1.1L1195.8,478.6z
                    M1194.3,477.5l1-1l1.2,0.8l-1,1L1194.3,477.5z M1192.9,479l1.1-1.2l1.2,0.8l-1.1,1.2L1192.9,479z M1191.5,478l1.1-1.2l1,0.7
                    l-1.1,1.2L1191.5,478z M1190.1,477l1.1-1.2l1.1,0.8l-1.1,1.2L1190.1,477z M1189.5,474.6l1.4,1l-1.1,1.2l-1.4-1L1189.5,474.6z
                    M1188.3,473.2l0.9-1l1.2,0.8l-0.9,1L1188.3,473.2z M1187.2,470.9l-0.9,1l-1.4-1l0.9-0.9L1187.2,470.9z M1188.8,472l-0.9,1
                    l-1.3-0.9l0.9-1L1188.8,472z M1190.1,470.6l-1,1.1l-1.3-0.9l1-1.1L1190.1,470.6z M1190.5,470.9l1.2,0.8l-1,1.1l-1.2-0.8
                    L1190.5,470.9z M1192.8,470.4l-0.9,0.9l-1.2-0.8l0.9-0.9L1192.8,470.4z M1193.2,470.7l1.4,1l-0.9,0.9l-1.4-1L1193.2,470.7z
                    M1196,470.2l-1.1,1.1l-1.4-1l1.1-1.1L1196,470.2z M1196.3,470.4l1.1,0.7l-1.1,1.1l-1.1-0.7L1196.3,470.4z M1198.5,469.9
                    l-0.9,0.9l-1.1-0.7l0.9-0.9L1198.5,469.9z M1199.9,470.9l-0.9,0.9l-1.1-0.7l0.9-0.9L1199.9,470.9z M1200.3,471.1l1.2,0.8
                    l-0.9,0.9l-1.2-0.8L1200.3,471.1z M1202.8,470.6l-1,1.1l-1.2-0.8l1-1.1L1202.8,470.6z M1204.3,471.6l-1,1.1l-1.2-0.8l1-1.1
                    L1204.3,471.6z M1205.5,470.3l-0.9,1l-1.2-0.8l0.9-1L1205.5,470.3z M1205.9,470.6l1.2,0.8l-1,1l-1.1-0.8L1205.9,470.6z
                    M1208.2,470.1l-1,1l-1.1-0.8l1-1L1208.2,470.1z M1208.6,470.3l1.1,0.7l-1,1l-1.1-0.7L1208.6,470.3z M1210.8,469.9l-0.8,0.9
                    l-1.1-0.7l0.8-0.9L1210.8,469.9z M1211.1,470.1l1,0.7l-0.8,0.9l-1-0.7L1211.1,470.1z M1213.4,469.4l-1,1.1l-1-0.7l1-1.1
                    L1213.4,469.4z M1213.8,469.6l1.2,0.9l-1,1.1l-1.2-0.8L1213.8,469.6z M1216.1,469.3l-0.8,0.9l-1.2-0.8l0.8-0.9L1216.1,469.3z
                    M1216.5,469.5l1,0.7l-0.8,0.9l-1-0.7L1216.5,469.5z M1218.6,469l-0.9,1l-1-0.7l0.9-1L1218.6,469z M1220.1,470l-0.9,1l-1.1-0.8
                    l0.9-1L1220.1,470z M1220.5,470.2l1.1,0.8l-0.9,1l-1.1-0.8L1220.5,470.2z M1222.8,469.7l-1,1l-1.1-0.8l1-1L1222.8,469.7z
                    M1223.2,469.9l1.2,0.8l-1,1l-1.2-0.8L1223.2,469.9z M1225.7,469.4l-1,1.1l-1.2-0.8l1-1L1225.7,469.4z M1226,469.6l1,0.7l-1,1.1
                    l-1-0.7L1226,469.6z M1228.3,469l-1,1l-1.1-0.7l1-1L1228.3,469z M1228.7,469.2l1.3,0.9l-1,1l-1.3-0.9L1228.7,469.2z
                    M1231.1,468.9l-0.9,0.9l-1.3-0.9l0.9-0.9L1231.1,468.9z M1231.5,469.1l1.1,0.7l-0.9,0.9l-1.1-0.7L1231.5,469.1z M1233.7,468.6
                    l-0.9,0.9l-1.1-0.7l0.9-0.9L1233.7,468.6z M1235.3,469.7l-0.9,1l-1.3-0.9l0.9-0.9L1235.3,469.7z M1236.8,470.7l-0.9,1l-1.1-0.8
                    l0.9-0.9L1236.8,470.7z M1238.5,471.8l-0.9,1l-1.3-0.9l0.9-1L1238.5,471.8z M1238.9,473.7l-1-0.6l0.9-1l1,0.6L1238.9,473.7z
                    M1240.3,475.1l-0.9,0.9l-1.3-0.9l0.9-0.9L1240.3,475.1z M1242.2,476.4l-0.9,0.9l-1.5-1l0.9-0.9L1242.2,476.4z M1242.5,476.7
                    l1.2,0.8l-0.9,0.9l-1.2-0.8L1242.5,476.7z" />
                    </g>
                    <g id="ACCESO_VEHICULAR-3">
                        <path class="st4" d="M66.3,323.2c0.3,0,0.6-0.2,0.8-0.4c0.7-0.7,0.8-1.8,0.2-2.6l-10.6-14c-0.5-0.7-1.5-0.9-2.3-0.4l0,0l-0.1,0.1
                    c-0.7,0.7-0.8,1.8-0.2,2.6l10.6,14C65.1,323,65.7,323.2,66.3,323.2z" />
                        <line class="st5" x1="57" y1="307" x2="56.8" y2="311.5" />
                        <line class="st5" x1="59.8" y1="310.8" x2="59.6" y2="315.1" />
                        <line class="st5" x1="62.5" y1="314.4" x2="62.5" y2="318.9" />
                        <line class="st5" x1="65" y1="317.6" x2="64.8" y2="322" />
                        <path class="st6" d="M64.4,322.7l-10.6-14c-0.7-1-0.6-2.3,0.3-3.1c0.4-0.4,1-0.5,1.5-0.5c0.6,0.1,1.1,0.4,1.5,0.8l10.6,14
                    c0.7,1,0.6,2.3-0.3,3.1c-0.3,0.3-0.7,0.4-1.1,0.5C65.6,323.6,64.8,323.3,64.4,322.7z M54.7,306.2c-0.6,0.5-0.6,1.4-0.2,2l10.6,14
                    c0.4,0.5,1.1,0.7,1.6,0.3c0,0,0,0,0.1,0h0.1c0.6-0.5,0.6-1.4,0.2-2l-10.6-14c-0.2-0.3-0.5-0.5-0.9-0.5h-0.3
                    C55.1,305.9,54.8,306,54.7,306.2z" />
                        <path class="st7" d="M12.4,321.6l53.4-1.4l-9.3-11.9L5,309.8c-0.7,0-1.2,0.6-1.2,1.3c0,0,0,0,0,0l0,0c0,0.3,0.1,0.5,0.3,0.7
                    l7.3,9.4C11.7,321.4,12,321.6,12.4,321.6z" />
                        <path class="st8"
                            d="M4.8,312.4l0.5,0.3l-0.2,0.1l0.3,0.3l0.2-0.2l1.5,1.1l-0.6,0.6l0.3,0.3l0.7-0.8l1.3,0.9l-0.9,1l-0.5-0.4
                    l0.8,1.1l0.7,0.5l-0.2,0.2l0.3,0.3l0.3-0.3l1.3,0.9l-0.5,0.7l0.3,0.3l0.7-0.7l1.1,0.8l-1,1l0.3,0.3l1-1.1l1.3,0.9l-0.8,0.9h0.6
                    l0.6-0.6l0.9,0.6H16l0.8-0.9l1.2,0.8h0.8l0.8-0.9l1.1,0.7l-0.1,0.1h0.9l-0.4-0.2l1-1l1.2,0.8l-0.3,0.3h1.4l-0.5-0.3l1-1.1
                    l1.1,0.8l-0.5,0.6h0.6l0.3-0.3l0.5,0.3h0.7l-0.9-0.6l1-1l1.1,0.8l-0.8,0.8h0.6l0.5-0.5l0.8,0.5h1l0.8-0.8l1.1,0.8h0.7l0.8-0.9
                    l1,0.7l-0.1,0.1h1l-0.3-0.2l1-1.1l1.1,0.7l-0.5,0.5h0.6l0.2-0.2l0.3,0.2h0.7l-0.7-0.5l0.8-0.9l1.1,0.8l-0.5,0.6h0.6l0.3-0.3
                    l0.4,0.3H42l-0.8-0.6l1-1l1.2,0.8l-0.7,0.7h0.6l0.5-0.5l0.7,0.5h0.7l-1.1-0.7l0.9-1l1.2,0.8l-0.8,0.8l0.1,0.1h0.3l0.6-0.7
                    l0.9,0.6h0.8l-1.3-0.9l1-1.1l1.1,0.7l-0.9,1l0.3,0.3l0,0l1-1l1.1,0.7l-0.2,0.2h1.2l-0.4-0.3l0.9-0.9l1.4,1l-0.2,0.2h1l-0.3-0.2
                    l1.1-1.1l1.2,0.8l-0.5,0.5h0.6l0.2-0.2l0.3,0.2h0.7l-0.7-0.5l0.9-0.9l1.3,0.9l-0.5,0.5h0.7l0.2-0.3l0.3,0.2h0.7l-0.8-0.5l1-1.1
                    l1.5,1l-0.5,0.6h0.6l0.3-0.3l0.5,0.3h0.7l-0.8-0.6l0.9-0.9l1.5,1.1l-0.9-1.2l-0.4-0.3l0.1-0.1l-0.3-0.3l-0.2,0.2l-1.4-1l0.5-0.5
                    l-0.3-0.3l-0.6,0.6l-1.3-0.9l0.9-1l-0.2-0.3l-1,1.1l-1.2-0.8l1.1-1.2l0.8,0.5l-0.9-1.1l-1.3-0.9l0.3-0.4l-0.3-0.3l-0.4,0.4
                    l-1.1-0.8l0.7-0.7l-0.3-0.3l-0.8,0.8l-1-0.7l0.8-0.8l0.8,0.6l-0.9-1.1l0,0l-0.2-0.1h-0.7l0.6,0.4l-0.8,0.8l-1.2-0.8l0.4-0.4h-0.4
                    l-0.2,0.2l-0.2-0.1h-0.6l0.6,0.4l-1.2,0.6l-1.2-0.8l0.7-0.7h-0.6l-0.5,0.5l-0.6-0.5h-0.7l1,0.7l-1,1.1l-1.1-0.8l1-1h-0.6
                    l-0.7,0.8l-1-0.7h-0.7l-1,1.2l-1-0.7l0.4-0.4h-0.6l-0.2,0.2l-0.2-0.1h-0.7l0.6,0.4l-0.9,0.9l-1.2-0.9l0.5-0.5h-0.6l-0.2,0.3
                    l-0.3-0.2h-0.7l0.7,0.5l-1.1,1.2l-1-0.7l0.9-1h-0.6l-0.7,0.7l-1-0.7h-0.9l-0.8,0.9l-1.1-0.6H34l0.1,0.1l-1,1.1l-1.2-0.8l0.3-0.3
                    h-1.1l0.4,0.3l-0.9,1l-1.1-0.7l0.5-0.5h-0.6l-0.3,0.3l-0.4-0.3h-0.6l0.8,0.5l-1,1l-1.3-0.8l0.6-0.6h-0.6l-0.4,0.4l-0.5-0.4h-0.6
                    l0.9,0.6l-0.8,0.9l-1.1-0.8l0.7-0.8h-0.6l-0.5,0.5l-0.7-0.5h-0.7l1.1,0.8l-1,1.1l-1.3-0.9l0.9-1h-0.6l-0.7,0.7l-1-0.7h-1.1
                    l-0.7,0.7l-1-0.7h-1.1l-0.7,0.8l-1-0.6l0.1-0.1h-0.9l0.2,0.1l-0.9,1l-1.3-0.9l0.2-0.2h-1.1l0.3,0.2l-1,1.1l-1.5-1l0.2-0.2H7.3
                    l0.3,0.2l-1,1l-1.2-0.8l0.4-0.4H5.1l-0.2-0.1c-0.2,0-0.3,0.1-0.5,0.2l0.4,0.3L4,311c0,0.1,0.1,0.2,0.1,0.3L4.8,312.4z M9.5,311.4
                    l-1,1L7,311.3l1-1L9.5,311.4z M9.9,311.6l1.3,0.9l-1,1l-1.3-0.9L9.9,311.6z M12.9,313.6l1.3,0.9l-1,1l-1.3-0.9L12.9,313.6z
                    M11.6,314.4l-1-0.6l1-1l1,0.6L11.6,314.4z M14.6,314.8l1.2,0.8l-1,1l-1.2-0.8L14.6,314.8z M13.2,313.3l1-1.1l1.3,0.9l-1,1.1
                    L13.2,313.3z M15.8,313.4l1.2,0.8l-1,1l-1.2-0.8L15.8,313.4z M17.4,314.4l1.3,0.9l-1,1l-1.3-0.9L17.4,314.4z M16.1,313.1l0.9-1
                    l1.2,0.8l-1,1L16.1,313.1z M18.6,313.1l1.3,0.9l-1,1l-1.3-0.9L18.6,313.1z M20.2,314.2l1.1,0.7l-1,1l-1.1-0.7L20.2,314.2z
                    M18.9,312.8l0.9-1l1.3,0.9l-0.9,1L18.9,312.8z M21.4,313l1.1,0.7l-0.9,1l-1-0.7L21.4,313z M22.8,314l1.3,0.9l-0.9,1l-1.3-0.9
                    L22.8,314z M23.1,313.7l0.8-0.9l1.3,0.9l-0.8,0.9L23.1,313.7z M25.6,313.9l1.1,0.7l-0.8,0.9l-1.1-0.7L25.6,313.9z M25.8,313.6
                    l1-1.1l1,0.7l-1,1.1L25.8,313.6z M28.3,313.5l1.2,0.8l-1,1.1l-1.2-0.8L28.3,313.5z M29.8,314.5l1.1,0.8l-1,1.1l-1.1-0.8
                    L29.8,314.5z M28.5,313.2l0.8-0.9l1.2,0.9l-0.8,0.9L28.5,313.2z M30.9,313.4l1.1,0.8l-0.8,0.9l-1.1-0.8L30.9,313.4z M32.4,314.4
                    l1.2,0.8l-0.8,0.9l-1.2-0.8L32.4,314.4z M31.2,313.1l1-1l1.1,0.8l-1,1L31.2,313.1z M33.6,313.1l1.1,0.8l-1,1l-1.1-0.8L33.6,313.1
                    z M35.1,314.1l1,0.7l-1,1l-1-0.7L35.1,314.1z M33.9,312.8l1-1l1.1,0.8l-0.9,1L33.9,312.8z M36.3,312.8l1,0.7l-0.9,1l-1-0.7
                    L36.3,312.8z M37.6,313.7l1.2,0.9l-0.9,1l-1.2-0.9L37.6,313.7z M36.6,312.5l1-1.1l1,0.7l-1.1,1.1L36.6,312.5z M39,312.3l1.2,0.8
                    l-1,1.1l-1.2-0.9L39,312.3z M40.5,313.4l1,0.7l-1,1.1l-1-0.7L40.5,313.4z M40.8,313.1l0.9-0.9l1,0.7l-0.9,0.9L40.8,313.1z
                    M43,313.1l1.1,0.7l-0.9,0.9l-1.1-0.8L43,313.1z M43.3,312.8l1.1-1.1l1.1,0.7l-1.1,1.1L43.3,312.8z M45.8,312.6l1.1,0.8l-1.1,1.1
                    l-1.1-0.8L45.8,312.6z M46.1,312.3l0.9-0.9l1.2,0.8l-0.9,0.9L46.1,312.3z M48.5,312.4l1.2,0.8l-0.9,0.9l-1.2-0.8L48.5,312.4z
                    M50.1,313.5l1.2,0.8l-0.9,0.9l-1.2-0.8L50.1,313.5z M50.4,313.1l1-1.1l1.2,0.8l-1.1,1.1L50.4,313.1z M52.9,313.1l1,0.7l-1,1.1
                    l-1-0.7L52.9,313.1z M53.2,312.8l1-1l1,0.7l-1,1L53.2,312.8z M55.6,312.8l1,0.8l-1,1l-1.1-0.7L55.6,312.8z M57,313.8l1.4,1
                    l-0.9,1l-1.4-1L57,313.8z M57.1,316.1l-1,1.1l-1.4-1l1-1.1L57.1,316.1z M54.3,315.9l-1.1-0.7l1-1.1l1.1,0.7L54.3,315.9z
                    M54.1,316.2l-0.9,0.9l-1.1-0.7l0.9-0.9L54.1,316.2z M51.8,316.2l-1-0.7l0.9-0.9l1,0.7L51.8,316.2z M51.5,316.5l-1.1,1.1
                    l-1.1-0.7l1.1-1.1L51.5,316.5z M49,316.7l-1.2-0.8l1.1-1.1l1.2,0.8L49,316.7z M47.5,315.7l-1.2-0.8l1.1-1.1l1.2,0.8L47.5,315.7z
                    M47.2,316l-0.9,0.9l-1.2-0.8l0.9-0.9L47.2,316z M44.8,315.9l-1.1-0.8l0.9-0.9l1.1,0.8L44.8,315.9z M44.5,316.2l-1,1.1l-1.1-0.8
                    l1-1.1L44.5,316.2z M42,316.3l-1.1-0.7l1-1.1l1.1,0.7L42,316.3z M41.7,316.6l-0.9,1l-1.1-0.7l1-1L41.7,316.6z M39.3,316.6l-1-0.7
                    l0.9-1l1,0.7L39.3,316.6z M39.1,316.9l-1,1l-1-0.7l1-1L39.1,316.9z M36.8,316.9l-1.2-0.8l1-1l1.2,0.8L36.8,316.9z M36.5,317.2
                    l-0.8,0.9l-1.2-0.9l0.8-0.9L36.5,317.2z M34.1,317l-1-0.7l0.8-0.9l1,0.7L34.1,317z M33.8,317.3l-1,1.1l-1-0.7l1-1.1L33.8,317.3z
                    M31.4,317.5l-1.1-0.8l1-1.1l1.1,0.8L31.4,317.5z M31.1,317.8l-0.8,0.9l-1.1-0.8L30,317L31.1,317.8z M28.8,317.6l-1.1-0.8
                    l0.8-0.9l1.1,0.8L28.8,317.6z M27.4,316.6l-1.2-0.8l0.8-0.9l1.2,0.8L27.4,316.6z M27.1,317l-0.9,1l-1.2-0.8l0.9-1L27.1,317z
                    M24.6,316.9l-1.1-0.7l0.9-1l1,0.7L24.6,316.9z M24.3,317.2l-1,1l-1-0.7l1-1L24.3,317.2z M22,317.2l-1.3-0.9l1-1l1.3,0.9
                    L22,317.2z M21.7,317.5l-1,1l-1.3-0.9l1-1L21.7,317.5z M19.1,317.5l-1.1-0.7l1-1.1l1.1,0.7L19.1,317.5z M18.8,317.8l-1,1
                    l-1.1-0.8l1-1L18.8,317.8z M16.4,317.8l-1.3-0.9l1-1l1.3,0.9L16.4,317.8z M16.1,318.1l-0.9,0.9l-1.3-0.9l0.9-0.9L16.1,318.1z
                    M13.6,317.9l-1.1-0.8l0.9-0.9l1.1,0.8L13.6,317.9z M12.1,316.9l-1.3-0.9l0.9-0.9L13,316L12.1,316.9z M10.5,315.8l-0.9-0.6
                    l0.9-0.9l1,0.6L10.5,315.8z M12.5,311.2l-1,1.1l-1.3-0.9l1-1.1L12.5,311.2z M12.9,311.5l1,0.6l-1,1.1l-0.9-0.6L12.9,311.5z
                    M15.1,310.8l-0.9,1l-0.9-0.6l0.9-1L15.1,310.8z M15.4,311l1.3,0.9l-0.9,1l-1.4-0.9L15.4,311z M17.9,310.7l-0.9,1l-1.3-0.9l0.9-1
                    L17.9,310.7z M18.3,310.9l1.2,0.9l-0.9,1l-1.2-0.8L18.3,310.9z M20.6,310.6l-0.8,0.9l-1.3-0.9l0.8-0.9L20.6,310.6z M22.2,311.7
                    l-0.8,0.9l-1.3-0.9l0.8-0.9L22.2,311.7z M22.6,311.9l1.1,0.7l-0.8,0.9l-1.1-0.7L22.6,311.9z M25,311.3l-1,1.1l-1.1-0.7l1-1.1
                    L25,311.3z M25.3,311.5l1.3,0.9l-1,1.1l-1.3-0.9L25.3,311.5z M27.7,311.2l-0.8,0.9l-1.3-0.9l0.8-0.9L27.7,311.2z M28.1,311.5
                    l1.1,0.7l-0.8,0.9l-1.1-0.7L28.1,311.5z M30.3,310.9l-1,1l-1-0.7l1-1L30.3,310.9z M30.7,311.1l1.2,0.8l-1,1l-1.2-0.8L30.7,311.1z
                    M33.1,310.7l-0.9,1l-1.2-0.8l0.9-1L33.1,310.7z M33.5,310.9l1.1,0.8l-0.9,1l-1.1-0.8L33.5,310.9z M35.9,310.3l-1,1.1l-1.1-0.8
                    l1-1.1L35.9,310.3z M36.2,310.5l1.1,0.8l-1,1.1l-1.1-0.8L36.2,310.5z M38.5,310.1l-0.9,0.9l-1.1-0.8l0.9-0.9L38.5,310.1z
                    M39.9,311l-0.9,0.9l-1-0.7l0.9-0.9L39.9,311z M40.2,311.2l1.2,0.9l-0.9,0.9l-1.2-0.9L40.2,311.2z M42.8,310.6l-1.1,1.1l-1.2-0.9
                    l1.1-1.1L42.8,310.6z M43.1,310.9l1,0.7l-1.1,1.1l-1-0.7L43.1,310.9z M45.3,310.3l-0.9,0.9l-1-0.7l0.9-0.9L45.3,310.3z
                    M45.7,310.5l1.1,0.7l-0.9,0.9l-1.1-0.7L45.7,310.5z M48.1,309.9L47,311l-1.1-0.8l1.1-1.1L48.1,309.9z M49.6,310.9l-1,1.1
                    l-1.1-0.8l1-1.1L49.6,310.9z M51.1,311.9l-1,1.1l-1.2-0.8l1-1.1L51.1,311.9z M52.4,310.6l-1,1.1l-1.2-0.8l1-1.1L52.4,310.6z
                    M53.9,311.6l-1,1l-1.2-0.8l1-1.1L53.9,311.6z M55.3,310.2l-1.1,1.2l-1.2-0.8l1.1-1.2L55.3,310.2z M56.6,311.2l-1.1,1.2l-1-0.7
                    l1.1-1.2L56.6,311.2z M58,312.2l-1.1,1.2l-1.1-0.8l1.1-1.2L58,312.2z M58.7,314.6l-1.4-1l1.1-1.2l1.4,1L58.7,314.6z M59.9,315.9
                    l-0.9,1l-1.2-0.8l0.9-1L59.9,315.9z M61,318.3l0.9-1l1.4,1l-0.9,0.9L61,318.3z M59.3,317.1l0.9-1l1.3,0.9l-0.9,1L59.3,317.1z
                    M58,318.5l1-1.1l1.3,0.9l-1,1.1L58,318.5z M57.6,318.2l-1.2-0.8l1-1.1l1.2,0.8L57.6,318.2z M55.2,318.6l0.9-0.9l1.2,0.8
                    l-0.9,0.9L55.2,318.6z M54.9,318.4l-1.4-1l0.9-0.9l1.4,1L54.9,318.4z M52.1,318.9l1.1-1.1l1.4,1l-1,1.1L52.1,318.9z M51.8,318.6
                    l-1.1-0.7l1.1-1.1l1.1,0.7L51.8,318.6z M49.5,319.1l0.9-0.9l1.1,0.7l-0.9,0.9L49.5,319.1z M48.1,318.1l0.9-0.9l1,0.7l-0.9,0.9
                    L48.1,318.1z M47.8,317.9l-1.2-0.8l0.9-0.9l1.2,0.8L47.8,317.9z M45.3,318.4l1-1.1l1.2,0.8l-1,1.1L45.3,318.4z M43.8,317.4l1-1.1
                    l1.2,0.8l-1,1.1L43.8,317.4z M42.6,318.7l0.9-1l1.2,0.8l-1,1L42.6,318.7z M42.2,318.5l-1.1-0.8l0.9-1l1.1,0.8L42.2,318.5z
                    M39.9,319l0.9-1l1.2,0.8l-1,1L39.9,319z M39.5,318.8l-1.1-0.8l1-1l1.1,0.7L39.5,318.8z M37.3,319.2l0.8-0.9l1.1,0.7l-0.8,0.9
                    L37.3,319.2z M37,319l-1-0.7l0.8-0.9l1,0.7L37,319z M34.7,319.7l1-1.1l1,0.7l-1,1.1L34.7,319.7z M34.3,319.4l-1.2-0.8l1-1.1
                    l1.2,0.9L34.3,319.4z M32,319.8l0.8-0.9l1.2,0.9l-0.8,0.9L32,319.8z M31.6,319.5l-1-0.7l0.8-0.9l1,0.7L31.6,319.5z M29.5,320.1
                    l0.9-1l1,0.7l-0.9,1L29.5,320.1z M28,319.1l0.9-1l1.1,0.8l-0.9,1L28,319.1z M27.6,318.9l-1.1-0.8l0.9-1l1.1,0.8L27.6,318.9z
                    M25.3,319.4l1-1l1.1,0.8l-1,1L25.3,319.4z M24.9,319.2l-1.2-0.8l1-1l1.2,0.8L24.9,319.2z M22.4,319.7l1-1l1.2,0.8l-1,1
                    L22.4,319.7z M22.1,319.5l-1.1-0.7l1-1.1l1.1,0.7L22.1,319.5z M19.8,320.1l1-1l1,0.7l-1,1L19.8,320.1z M19.4,319.9l-1.3-0.9l1-1
                    l1.3,0.9L19.4,319.9z M17,320.2l0.9-0.9l1.3,0.9l-0.9,0.9L17,320.2z M16.6,320l-1.1-0.8l0.9-0.9l1.1,0.7L16.6,320z M14.4,320.5
                    l0.9-0.9l1.1,0.7l-0.9,1L14.4,320.5z M12.8,319.4l0.9-0.9l1.3,0.9l-0.9,0.9L12.8,319.4z M11.3,318.4l0.9-1l1.1,0.8l-0.9,0.9
                    L11.3,318.4z M9.6,317.2l0.9-1l1.3,0.9l-0.9,1L9.6,317.2z M9.2,315.3l0.9,0.7l-0.9,1l-0.9-0.6L9.2,315.3z M7.8,313.9l0.9-0.9
                    l1.3,0.9l-0.9,0.9L7.8,313.9z M5.9,312.6l0.9-0.9l1.5,1.1l-0.9,0.9L5.9,312.6z M5.5,312.4l-1.2-0.8l0.9-0.9l1.2,0.8L5.5,312.4z" />
                    </g>
                    <g id="ACCESO_VEHICULAR-2">
                        <path class="st4" d="M1182.5,344.3c-0.3,0-0.6,0.2-0.8,0.4c-0.7,0.7-0.8,1.8-0.2,2.6l10.6,14c0.5,0.7,1.5,0.9,2.3,0.4l0,0
                    l0.1-0.1c0.7-0.7,0.8-1.8,0.2-2.6l-10.6-14C1183.7,344.5,1183.1,344.3,1182.5,344.3z" />
                        <line class="st5" x1="1191.8" y1="360.5" x2="1191.9" y2="355.9" />
                        <line class="st5" x1="1189" y1="356.7" x2="1189.1" y2="352.4" />
                        <line class="st5" x1="1186.2" y1="353.1" x2="1186.3" y2="348.6" />
                        <line class="st5" x1="1183.8" y1="349.9" x2="1184" y2="345.5" />
                        <path class="st6" d="M1184.4,344.8l10.6,14c0.7,1,0.6,2.3-0.3,3.1c-0.4,0.4-1,0.5-1.5,0.5c-0.6-0.1-1.1-0.4-1.5-0.8l-10.6-14
                    c-0.7-1-0.6-2.3,0.3-3.1c0.3-0.3,0.7-0.4,1.1-0.5C1183.2,343.9,1183.9,344.2,1184.4,344.8z M1194.1,361.3c0.6-0.5,0.7-1.4,0.2-2
                    l-10.6-14c-0.4-0.5-1.1-0.7-1.6-0.3c0,0,0,0-0.1,0h-0.1c-0.6,0.5-0.6,1.4-0.2,2l10.6,14c0.2,0.3,0.5,0.5,0.9,0.5h0.3
                    C1193.7,361.5,1193.9,361.4,1194.1,361.3L1194.1,361.3z" />
                        <path class="st7" d="M1236.4,345.9l-53.4,1.5l9.3,11.8l51.5-1.5c0.7,0,1.3-0.6,1.3-1.3c0-0.3-0.1-0.6-0.3-0.8l-7.4-9.4
                    C1237.1,345.9,1236.8,345.8,1236.4,345.9z" />
                        <path class="st8" d="M1244,355.1l-0.5-0.3l0.1-0.1l-0.3-0.3l-0.2,0.2l-1.5-1l0.6-0.6l-0.3-0.3l-0.7,0.7l-1.3-0.9l0.9-1l0.5,0.4
                    l-0.8-1.1l-0.7-0.5l0.2-0.2l-0.3-0.3l-0.3,0.3l-1.3-0.9l0.6-0.6l-0.3-0.3l-0.7,0.7l-1.1-0.8l1-1l-0.3-0.3l-1.1,1.1l-1.3-0.8
                    l0.8-0.9h-0.6l-0.6,0.6l-0.9-0.6h-0.9l-0.8,0.9l-1.2-0.8h-0.8l-0.8,0.9l-1-0.7l0.1-0.1h-0.9l0.2,0.1l-1,1l-1.2-0.8l0.3-0.3h-1.5
                    l0.5,0.3l-1,1l-1.1-0.8l0.6-0.6h-0.6l-0.3,0.3l-0.5-0.3h-0.7l0.9,0.6l-1,1l-1.1-0.8l0.8-0.8h-0.6l-0.5,0.5l-0.8-0.5h-1l-0.8,0.8
                    l-1.2-0.8h-0.7l-0.8,0.9l-1-0.7l0.1-0.1h-1l0.3,0.2l-1,1.1l-1.1-0.7l0.4-0.5h-0.6l-0.2,0.2l-0.3-0.2h-0.7l0.7,0.5l-0.8,0.9
                    l-1.1-0.8l0.5-0.6h-0.5l-0.3,0.3l-0.4-0.3h-0.7l0.8,0.6l-1,1l-1.2-0.8l0.7-0.7h-0.6l-0.4,0.5l-0.7-0.5h-0.7l1.1,0.7l-1,1
                    l-1.2-0.8l0.7-0.8l-0.1-0.1h-0.3l-0.6,0.7l-0.9-0.6h-0.7l1.3,0.9l-1,1.1l-1-0.7l0.9-1l-0.3-0.3l0,0l-1,1l-1.1-0.7l0.2-0.2h-1.2
                    l0.4,0.3l-0.9,0.9l-1.4-1l0.2-0.2h-1.1l0.3,0.2l-1.1,1.1l-1.2-0.8l0.5-0.5h-0.5l-0.2,0.2l-0.3-0.2h-0.7l0.8,0.5l-0.9,0.9
                    l-1.3-0.9l0.5-0.5h-0.6l-0.2,0.3l-0.3-0.2h-0.7l0.8,0.5l-1,1.1l-1.4-1l0.6-0.6h-0.6l-0.3,0.3l-0.4-0.3h-0.7l0.8,0.6l-0.9,0.9
                    l-1.5-1.1l0.9,1.1l0.4,0.3l-0.1,0.1l0.3,0.3l0.2-0.2l1.4,1l-0.5,0.5l0.3,0.3l0.6-0.6l1.3,0.9l-0.9,1l0.3,0.3l1-1.1l1.2,0.8
                    l-1.1,1.2l-0.8-0.5l0.9,1.1l1.3,0.9l-0.3,0.4l0.3,0.3l0.4-0.4l1.1,0.8l-0.7,0.7l0.3,0.3l0.8-0.8l1,0.7l-0.8,0.8l-0.8-0.6l0.9,1.1
                    l0,0l0.2,0.1h0.7l-0.6-0.4l0.8-0.8l1.2,0.8l-0.4,0.4h0.6l0.2-0.2l0.2,0.1h0.7l-0.6-0.4l1.1-1.2l1.2,0.8l-0.7,0.7h0.6l0.5-0.5
                    l0.6,0.5h0.7l-1-0.7l1-1.1l1.1,0.8l-1,1h0.6l0.7-0.8l1,0.7h0.7l1.1-1.1l1,0.7l-0.4,0.4h0.6l0.2-0.2l0.2,0.1h0.7l-0.6-0.4l0.9-0.9
                    l1.2,0.9l-0.5,0.5h0.6l0.2-0.3l0.3,0.2h0.7l-0.7-0.5l1.1-1.1l1,0.7l-0.9,1h0.6l0.7-0.7l1,0.7h0.8l0.8-0.9l1.1,0.8h0.8l-0.1-0.1
                    l1-1.1l1.2,0.8l-0.3,0.3h1.3l-0.4-0.3l0.9-1l1.1,0.7l-0.5,0.5h0.6l0.3-0.3l0.4,0.3h0.7l-0.8-0.5l1-1l1.3,0.9l-0.6,0.6h0.6
                    l0.4-0.4l0.5,0.4h0.7l-0.9-0.6l0.8-0.9l1.1,0.7l-0.7,0.8h0.6l0.5-0.5l0.7,0.5h0.7l-1.1-0.8l1-1.1l1.3,0.9l-0.9,1h0.6l0.7-0.7
                    l1,0.7h1l0.7-0.7l1,0.7h1.1l0.7-0.8l1,0.6l-0.1,0.1h0.9l-0.2-0.1l0.9-1l1.3,0.9l-0.2,0.2h1.1l-0.3-0.2l1-1.1l1.5,1l-0.2,0.2h1.1
                    l-0.3-0.2l1-1l1.2,0.8l-0.4,0.4h0.6l0.2-0.2l0.2,0.1c0.2,0,0.3-0.1,0.5-0.2l-0.4-0.3l0.8-0.9c0-0.1-0.1-0.2-0.1-0.3L1244,355.1z
                    M1239.3,356.1l1-1l1.5,1l-1,1L1239.3,356.1z M1238.9,355.9l-1.3-0.9l1-1l1.3,0.9L1238.9,355.9z M1235.9,353.9l-1.3-0.9l1-1
                    l1.3,0.9L1235.9,353.9z M1237.2,353.1l1,0.6l-1,1l-1-0.6L1237.2,353.1z M1234.2,352.7l-1.2-0.8l1-1l1.2,0.8L1234.2,352.7z
                    M1235.6,354.2l-1,1.1l-1.3-0.9l1-1.1L1235.6,354.2z M1233,354.1l-1.2-0.8l1-1l1.2,0.8L1233,354.1z M1231.4,353.1l-1.3-0.9l1-1
                    l1.3,0.9L1231.4,353.1z M1232.7,354.4l-0.9,1l-1.2-0.8l1-1L1232.7,354.4z M1230.2,354.4l-1.3-0.9l1-1l1.3,0.9L1230.2,354.4z
                    M1228.5,353.3l-1.1-0.7l1-1l1.1,0.7L1228.5,353.3z M1229.9,354.7l-0.9,1l-1.3-0.9l0.9-1L1229.9,354.7z M1227.4,354.5l-1.1-0.7
                    l0.9-1l1.1,0.7L1227.4,354.5z M1225.9,353.5l-1.3-0.9l0.9-1l1.3,0.9L1225.9,353.5z M1225.7,353.8l-0.8,0.9l-1.3-0.9l0.8-0.9
                    L1225.7,353.8z M1223.2,353.6l-1.1-0.7l0.8-0.9l1.1,0.7L1223.2,353.6z M1222.9,353.9l-1,1.1l-1-0.7l1-1.1L1222.9,353.9z
                    M1220.5,354l-1.2-0.8l1-1.1l1.2,0.8L1220.5,354z M1218.9,352.9l-1.1-0.8l1-1.1l1.1,0.8L1218.9,352.9z M1220.2,354.3l-0.8,0.9
                    l-1.2-0.8l0.8-0.9L1220.2,354.3z M1217.8,354.1l-1.1-0.8l0.8-0.9l1.1,0.8L1217.8,354.1z M1216.3,353.1l-1.1-0.8l0.8-0.9l1.1,0.8
                    L1216.3,353.1z M1217.5,354.4l-0.9,1l-1.1-0.8l0.9-1L1217.5,354.4z M1215.1,354.4l-1.1-0.8l1-1l1.1,0.8L1215.1,354.4z
                    M1213.6,353.4l-1-0.7l1-1l1,0.7L1213.6,353.4z M1214.8,354.7l-0.9,1l-1.1-0.8l0.9-1L1214.8,354.7z M1212.4,354.7l-1-0.7l1-1
                    l1,0.7L1212.4,354.7z M1211.1,353.8l-1.2-0.9l1-1l1.2,0.9L1211.1,353.8z M1212.1,355l-1,1.1l-1-0.7l1-1.1L1212.1,355z
                    M1209.7,355.2l-1.2-0.8l1-1.1l1.2,0.9L1209.7,355.2z M1208.2,354.1l-1-0.7l1-1.1l1,0.7L1208.2,354.1z M1207.9,354.4l-0.9,0.9
                    l-1-0.7l0.9-0.9L1207.9,354.4z M1205.7,354.4l-1.1-0.7l0.9-0.9l1.1,0.7L1205.7,354.4z M1205.4,354.7l-1.1,1.1l-1.1-0.7l1.1-1.1
                    L1205.4,354.7z M1202.9,354.8l-1.1-0.8l1.1-1.1l1.1,0.8L1202.9,354.8z M1202.6,355.2l-0.9,0.9l-1.2-0.8l0.9-0.9L1202.6,355.2z
                    M1200.2,355.1l-1.2-0.8l0.9-0.9l1.2,0.8L1200.2,355.1z M1198.7,354l-1.2-0.8l0.9-0.9l1.2,0.8L1198.7,354z M1198.4,354.3l-1,1.1
                    l-1.2-0.8l1-1.1L1198.4,354.3z M1195.8,354.3l-1-0.7l1-1.1l1,0.7L1195.8,354.3z M1195.5,354.6l-1,1l-1-0.7l1-1L1195.5,354.6z
                    M1193.2,354.7l-1.1-0.7l1-1l1.1,0.7L1193.2,354.7z M1191.8,353.7l-1.4-1l0.9-1l1.4,1L1191.8,353.7z M1191.6,351.4l1-1.1l1.4,1
                    l-1,1.1L1191.6,351.4z M1194.4,351.5l1.1,0.7l-1,1.1l-1.1-0.7L1194.4,351.5z M1194.7,351.2l0.9-0.9l1.1,0.7l-0.9,0.9
                    L1194.7,351.2z M1197,351.2l1,0.7l-0.9,0.9l-1-0.7L1197,351.2z M1197.3,350.9l1.1-1.1l1.1,0.7l-1.1,1.1L1197.3,350.9z
                    M1199.7,350.7l1.2,0.8l-1.1,1.1l-1.2-0.8L1199.7,350.7z M1201.3,351.7l1.2,0.8l-1.1,1.1l-1.2-0.8L1201.3,351.7z M1201.6,351.4
                    l0.9-0.9l1.2,0.8l-0.9,0.9L1201.6,351.4z M1204,351.6l1.1,0.8l-0.9,0.9l-1.1-0.8L1204,351.6z M1204.3,351.3l1-1.1l1.1,0.8l-1,1.1
                    L1204.3,351.3z M1206.8,351.2l1.1,0.7l-1,1.1l-1.1-0.7L1206.8,351.2z M1207,350.9l0.9-1l1.1,0.7l-1,1L1207,350.9z M1209.4,350.9
                    l1,0.7l-0.9,1l-1-0.7L1209.4,350.9z M1209.7,350.6l1-1l1,0.7l-1,1L1209.7,350.6z M1212,350.5l1.2,0.8l-1,1l-1.2-0.8L1212,350.5z
                    M1212.3,350.2l0.8-0.9l1.2,0.9l-0.8,0.9L1212.3,350.2z M1214.7,350.4l1,0.7l-0.8,0.9l-1-0.7L1214.7,350.4z M1215,350.1l1-1.1
                    l1,0.7l-1,1.1L1215,350.1z M1217.3,350l1.1,0.8l-1,1.1l-1.1-0.8L1217.3,350z M1217.6,349.7l0.8-0.9l1.1,0.8l-0.8,0.9
                    L1217.6,349.7z M1219.9,349.8l1.1,0.8l-0.8,0.9l-1.1-0.8L1219.9,349.8z M1221.4,350.8l1.2,0.8l-0.8,0.9l-1.2-0.8L1221.4,350.8z
                    M1221.7,350.5l0.9-1l1.2,0.8l-0.9,1L1221.7,350.5z M1224.1,350.6l1.1,0.7l-0.9,1l-1-0.7L1224.1,350.6z M1224.4,350.3l1-1
                    l1.1,0.7l-1,1L1224.4,350.3z M1226.8,350.3l1.3,0.9l-1,1l-1.3-0.9L1226.8,350.3z M1227,350l1-1l1.3,0.9l-1,1L1227,350z
                    M1229.7,350l1.1,0.7l-1,1.1l-1.1-0.7L1229.7,350z M1230,349.7l1-1l1.1,0.7l-1,1L1230,349.7z M1232.4,349.7l1.3,0.9l-1,1
                    l-1.3-0.9L1232.4,349.7z M1232.6,349.4l0.9-0.9l1.3,0.9l-0.9,0.9L1232.6,349.4z M1235.1,349.5l1.1,0.8l-0.9,0.9l-1.2-0.8
                    L1235.1,349.5z M1236.6,350.5l1.3,0.9l-0.9,0.9l-1.3-0.9L1236.6,350.5z M1238.3,351.7l1,0.6l-0.9,0.9l-1-0.6L1238.3,351.7z
                    M1236.2,356.2l1-1.1l1.3,0.9l-1,1.1L1236.2,356.2z M1235.9,356l-1-0.6l1-1.1l1,0.6L1235.9,356z M1233.7,356.7l0.9-1l1,0.6
                    l-0.9,1L1233.7,356.7z M1233.4,356.4l-1.3-0.9l0.9-1l1.3,0.9L1233.4,356.4z M1230.9,356.8l0.9-1l1.3,0.9l-0.9,1L1230.9,356.8z
                    M1230.5,356.6l-1.3-0.9l0.9-1l1.2,0.8L1230.5,356.6z M1228.2,356.9l0.8-0.9l1.3,0.9l-0.8,0.9L1228.2,356.9z M1226.5,355.8
                    l0.8-0.9l1.3,0.9l-0.8,0.9L1226.5,355.8z M1226.2,355.5l-1.1-0.7l0.8-0.9l1.1,0.7L1226.2,355.5z M1223.8,356.2l1-1.1l1.1,0.7
                    l-1,1.1L1223.8,356.2z M1223.5,356l-1.3-0.9l1-1.1l1.3,0.9L1223.5,356z M1221.1,356.2l0.8-0.9l1.3,0.9l-0.8,0.9L1221.1,356.2z
                    M1220.7,356l-1.1-0.7l0.8-0.9l1.1,0.7L1220.7,356z M1218.4,356.6l1-1l1,0.7l-0.9,1L1218.4,356.6z M1218.1,356.3l-1.2-0.8l1-1
                    l1.2,0.8L1218.1,356.3z M1215.7,356.8l0.9-1l1.2,0.8l-0.9,1L1215.7,356.8z M1215.3,356.6l-1.1-0.8l0.9-1l1.1,0.8L1215.3,356.6z
                    M1212.9,357.2l1-1.1l1.1,0.8l-1,1.1L1212.9,357.2z M1212.6,356.9l-1.1-0.8l1-1.1l1.1,0.8L1212.6,356.9z M1210.3,357.4l0.9-0.9
                    l1.1,0.8l-0.9,0.9L1210.3,357.4z M1208.9,356.5l0.9-0.9l1,0.7l-0.9,0.9L1208.9,356.5z M1208.6,356.2l-1.2-0.9l0.9-0.9l1.2,0.9
                    L1208.6,356.2z M1206,356.8l1.1-1.1l1.2,0.9l-1.1,1.1L1206,356.8z M1205.6,356.6l-1-0.7l1.1-1.1l1,0.7L1205.6,356.6z
                    M1203.5,357.2l0.9-0.9l1,0.7l-0.9,0.9L1203.5,357.2z M1203.1,356.9l-1.1-0.7l0.9-0.9l1.1,0.7L1203.1,356.9z M1200.7,357.6l1-1.1
                    l1.1,0.7l-1.1,1.1L1200.7,357.6z M1199.2,356.6l1-1.1l1.1,0.8l-1,1.1L1199.2,356.6z M1197.7,355.5l1-1.1l1.2,0.8l-1,1.1
                    L1197.7,355.5z M1196.5,357l1-1.1l1.2,0.8l-1,1.1L1196.5,357z M1195,355.9l1-1l1.2,0.8l-1,1.1L1195,355.9z M1193.7,357.4l1.1-1.2
                    l1.2,0.8l-1.1,1.2L1193.7,357.4z M1192.3,356.4l1.1-1.2l1,0.7l-1.1,1.2L1192.3,356.4z M1190.9,355.4l1.1-1.2l1.1,0.8l-1.1,1.2
                    L1190.9,355.4z M1190.3,353l1.4,1l-1.1,1.2l-1.4-1L1190.3,353z M1189,351.6l0.9-1l1.2,0.8l-0.9,1L1189,351.6z M1187.9,349.3
                    l-0.9,1l-1.4-1l0.9-0.9L1187.9,349.3z M1189.6,350.4l-0.9,1l-1.3-0.9l0.9-1L1189.6,350.4z M1190.9,349l-1,1.1l-1.3-0.9l1-1.1
                    L1190.9,349z M1191.3,349.3l1.2,0.8l-1,1.1l-1.2-0.8L1191.3,349.3z M1193.6,348.8l-0.9,0.9l-1.2-0.8l0.9-0.9L1193.6,348.8z
                    M1194,349.1l1.4,1l-0.9,0.9l-1.4-1L1194,349.1z M1196.8,348.6l-1.1,1.1l-1.4-1l1.1-1.1L1196.8,348.6z M1197.1,348.8l1.1,0.7
                    l-1.1,1.1l-1.1-0.7L1197.1,348.8z M1199.3,348.3l-0.9,0.9l-1.1-0.7l0.9-0.9L1199.3,348.3z M1200.7,349.3l-0.9,0.9l-1-0.7l0.9-0.9
                    L1200.7,349.3z M1201.1,349.6l1.2,0.8l-0.9,0.9l-1.2-0.8L1201.1,349.6z M1203.5,349l-1,1.1l-1.2-0.8l1-1.1L1203.5,349z
                    M1205.1,350.1l-1,1.1l-1.2-0.8l1-1.1L1205.1,350.1z M1206.3,348.8l-1,1l-1.2-0.8l0.9-1L1206.3,348.8z M1206.7,349l1.1,0.8
                    l-0.9,1l-1.1-0.8L1206.7,349z M1209,348.5l-1,1l-1.2-0.8l1-1L1209,348.5z M1209.4,348.7l1.1,0.8l-0.9,1l-1.1-0.7L1209.4,348.7z
                    M1211.6,348.3l-0.8,0.9l-1.1-0.7l0.8-0.9L1211.6,348.3z M1211.9,348.5l1,0.7l-0.8,0.9l-1-0.7L1211.9,348.5z M1214.2,347.8
                    l-1,1.1l-1-0.7l1-1.1L1214.2,347.8z M1214.6,348l1.2,0.9l-1,1.1l-1.2-0.9L1214.6,348z M1216.9,347.7l-0.8,0.9l-1.2-0.9l0.8-0.9
                    L1216.9,347.7z M1217.2,348l1,0.7l-0.8,0.9l-1-0.7L1217.2,348z M1219.4,347.4l-0.9,1l-1-0.7l0.9-1L1219.4,347.4z M1220.9,348.4
                    l-0.9,1l-1.1-0.8l0.9-1L1220.9,348.4z M1221.2,348.6l1.1,0.8l-0.9,1l-1.1-0.8L1221.2,348.6z M1223.6,348.1l-1,1l-1.1-0.8l1-1
                    L1223.6,348.1z M1224,348.3l1.2,0.8l-1,1l-1.2-0.8L1224,348.3z M1226.4,347.8l-1,1l-1.2-0.8l1-1L1226.4,347.8z M1226.8,348
                    l1.1,0.7l-1,1.1l-1.1-0.7L1226.8,348z M1229.1,347.4l-1,1l-1-0.7l1-1L1229.1,347.4z M1229.5,347.6l1.3,0.9l-1,1l-1.3-0.9
                    L1229.5,347.6z M1231.9,347.3l-0.9,0.9l-1.3-0.9l0.9-0.9L1231.9,347.3z M1232.3,347.5l1.1,0.7l-0.9,0.9l-1.1-0.7L1232.3,347.5z
                    M1234.5,347l-0.9,0.9l-1.1-0.7l0.9-1L1234.5,347z M1236.1,348.1l-0.9,0.9l-1.3-0.9l0.9-0.9L1236.1,348.1z M1237.6,349.1l-0.9,1
                    l-1.1-0.8l0.9-0.9L1237.6,349.1z M1239.3,350.3l-0.9,1l-1.3-0.9l0.9-1L1239.3,350.3z M1239.7,352.1l-1-0.7l0.9-1l1,0.6
                    L1239.7,352.1z M1241.1,353.6l-0.9,0.9l-1.3-0.9l0.9-0.9L1241.1,353.6z M1243,354.9l-0.9,0.9l-1.5-1.1l0.9-0.9L1243,354.9z
                    M1243.3,355.1l1.2,0.8l-0.9,0.9l-1.2-0.8L1243.3,355.1z" />
                    </g>
                    <path class="st4" d="M64.1,423c0.3,0,0.6-0.2,0.9-0.4c0.7-0.7,0.8-1.8,0.2-2.6l-10.6-14c-0.5-0.7-1.5-0.9-2.2-0.4l0,0
                c-0.1,0-0.1,0-0.1,0.1l0,0c-0.7,0.7-0.8,1.8-0.2,2.6l10.6,14C62.9,422.8,63.5,423.1,64.1,423z" />
                    <path class="st9" d="M54.7,411.9L54.7,411.9c-0.3,0-0.5-0.2-0.5-0.4c0,0,0,0,0-0.1l0,0l0.2-4.5c0-0.3,0.2-0.5,0.4-0.5
                c0,0,0,0,0.1,0l0,0c0.3,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0.1l0,0l-0.2,4.5C55.2,411.7,55,411.9,54.7,411.9
                C54.7,411.9,54.7,411.9,54.7,411.9z" />
                    <path class="st9" d="M57.5,415.4L57.5,415.4c-0.3,0-0.5-0.2-0.5-0.4c0,0,0,0,0-0.1l0,0l0.2-4.3c0-0.3,0.2-0.5,0.5-0.5
                s0.5,0.2,0.5,0.5l0,0l-0.2,4.3C57.9,415.3,57.7,415.4,57.5,415.4z" />
                    <path class="st9" d="M60.3,419.3L60.3,419.3c-0.3,0-0.5-0.2-0.5-0.4c0,0,0,0,0-0.1l0,0l0.1-4.5c0-0.3,0.2-0.5,0.4-0.5
                c0,0,0,0,0.1,0l0,0c0.3,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0.1l0,0l-0.1,4.5C60.8,419.1,60.6,419.3,60.3,419.3
                C60.3,419.3,60.3,419.3,60.3,419.3z" />
                    <path class="st9" d="M62.6,422.4L62.6,422.4c-0.3,0-0.5-0.2-0.5-0.4c0,0,0,0,0-0.1l0,0l0.2-4.4c0-0.3,0.2-0.5,0.4-0.5
                c0,0,0,0,0.1,0l0,0c0.3,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0.1l0,0l-0.2,4.4C63.1,422.2,62.9,422.4,62.6,422.4
                C62.6,422.4,62.6,422.4,62.6,422.4z" />
                    <path class="st6" d="M62.2,422.6l-10.6-14c-0.7-1-0.6-2.3,0.3-3.1c0.4-0.4,1-0.5,1.5-0.5c0.6,0.1,1.1,0.4,1.5,0.9l10.6,14
                c0.7,1,0.6,2.3-0.3,3.1c-0.8,0.7-2,0.7-2.8-0.1l0,0C62.3,422.8,62.3,422.7,62.2,422.6z M52.5,406.1c-0.6,0.5-0.7,1.4-0.2,2
                l10.6,14c0.4,0.6,1.2,0.7,1.8,0.3c0,0,0,0,0,0l0,0c0.6-0.5,0.6-1.4,0.2-2l-10.6-14c-0.2-0.3-0.5-0.5-0.9-0.5h-0.3
                C52.9,405.9,52.6,405.9,52.5,406.1z" />
                    <path class="st8" d="M8.8,424.1c-0.4,0-0.9-0.2-1.1-0.6l-7.4-9.4c-0.4-0.5-0.4-1.3,0.1-1.8c0.2-0.3,0.6-0.5,1-0.5l51.6-1.5
                l9.5,12.1L8.8,424.1z M52.9,410.7l-51.5,1.4c-0.6,0-1.1,0.5-1.1,1.1c0,0.2,0.1,0.4,0.2,0.6l7.4,9.4c0.2,0.3,0.5,0.5,0.9,0.5l0,0
                l53.1-1.5L52.9,410.7z" />
                    <path class="st8" d="M1.2,414.7l0.5,0.3l-0.2,0.2l0.3,0.3l0.2-0.2l1.5,1l-0.6,0.6l0.3,0.3l0.7-0.7l1.3,0.9l-0.9,1L3.8,418l0.8,1.1
                l0.7,0.5l-0.2,0.2l0.3,0.3l0.3-0.3l1.3,0.9l-0.6,0.6l0.3,0.3l0.7-0.7l1.1,0.8l-1,1l0.3,0.3l1.1-1l1.3,0.9l-0.8,0.9H10l0.6-0.6
                l0.9,0.6h0.9l0.8-0.9l1.2,0.8h0.8l0.8-0.9l1,0.7l-0.1,0.1h0.9l-0.2-0.1l1-1l1.2,0.8l-0.3,0.3h1.3l-0.5-0.3l1-1l1.1,0.8l-0.5,0.6
                h0.6l0.3-0.3l0.5,0.3H24l-0.9-0.7l1-1l1.1,0.8l-0.8,0.8H25l0.5-0.5l0.8,0.5h1l0.8-0.8l1.1,0.8h0.7l0.8-0.9l1,0.7l-0.1,0.1h1
                l-0.2-0.2l1-1.1l1.1,0.7l-0.5,0.5h0.6l0.2-0.2l0.3,0.2h0.7l-0.7-0.5l0.8-0.9l1.1,0.8l-0.5,0.6h0.6l0.3-0.3l0.4,0.3h0.7l-0.8-0.6
                l1-1l1.2,0.8l-0.7,0.7h0.6l0.5-0.5l0.7,0.5h0.7l-1.1-0.7l0.9-1l1.2,0.8l-0.7,0.8l0.1,0.1h0.3l0.6-0.6l0.9,0.6h0.7l-1.3-0.9l1-1.1
                l1,0.7l-0.9,1l0.3,0.3l0,0l1-1l1.1,0.7l-0.2,0.2h1.2l-0.4-0.3l0.8-1l1.4,1l-0.2,0.2h1.1l-0.4-0.1l1.1-1.1l1.2,0.8l-0.5,0.5h0.6
                l0.2-0.3l0.3,0.2h0.7l-0.7-0.5l0.9-0.9l1.3,0.9l-0.5,0.5h0.7l0.2-0.3l0.5,0.2h0.7l-0.8-0.5l1-1.1l1.4,1l-0.5,0.6h0.6l0.3-0.3
                l0.5,0.3H60l-0.8-0.6l0.9-0.9l1.5,1.1l-0.9-1.1l-0.4-0.3l0.1-0.1l-0.3-0.3l-0.2,0.2l-1.4-1l0.5-0.6l-0.3-0.3l-0.6,0.6l-1.3-0.9
                l0.9-1l-0.3-0.3l-1,1.1l-1.2-0.8l1.1-1.2l0.8,0.5l-0.9-1.1l-1.1-1l0.3-0.4l-0.3-0.3l-0.4,0.4l-1.1-0.8l0.7-0.7l-0.2-0.2l-0.8,0.8
                l-1-0.7l0.8-0.8l0.8,0.6l-0.9-1.1l0,0l-0.2-0.1h-0.7l0.6,0.4l-0.8,0.8l-1.2-0.8l0.4-0.4h-0.7l-0.2,0.2l-0.2-0.1h-0.7l0.8,0.2
                l-1.1,1.2l-1.2-0.8l0.7-0.7h-0.6l-0.5,0.5l-0.6-0.5h-0.7l1,0.7l-1,1.1l-1.1-0.8l1-1h-0.6l-0.7,0.8l-1-0.7H43l-1.1,1.1l-1-0.7
                l0.4-0.4h-0.6l-0.2,0.2l-0.2-0.1h-0.7l0.6,0.4l-0.9,0.9l-1.2-0.9l0.5-0.5h-0.5l-0.2,0.3l-0.3-0.2h-0.7l0.7,0.5l-1.1,1.1l-1-0.7
                l0.9-1h-0.6l-0.7,0.7l-1-0.7h-0.8l-0.8,0.9l-1.1-0.8h-0.8l0.1,0.1l-1,1.1l-1.2-0.8l0.3-0.3h-1.3l0.4,0.3l-1,1l-1-0.7l0.5-0.5h-0.6
                l-0.3,0.3l-0.4-0.3h-0.7l0.8,0.5l-1,1l-1.3-0.9l0.6-0.6h-0.4l-0.4,0.4l-0.5-0.4h-0.7l1,0.6l-0.8,0.9l-1.1-0.7l0.7-0.8h-0.6
                l-0.5,0.5l-0.7-0.5h-0.7l1.1,0.8l-1,1.1l-1.3-0.9l0.9-1h-0.6l-0.7,0.7l-1-0.7h-1l-0.7,0.7l-1-0.7h-1.2l-0.7,0.8l-0.9-0.6l0.1-0.1
                H10l0.2,0.1l-0.9,1L8,412.4l0.2-0.2H7l0.3,0.2l-1,1.1l-1.5-1.1l0.2-0.2H3.8l0.3,0.2l-1,1l-1.2-0.8l0.4-0.4H1.8l-0.2,0.2l-0.2-0.1
                c-0.2,0-0.3,0.1-0.5,0.2l0.4,0.3l-0.8,0.9c0,0.1,0.1,0.2,0.1,0.3L1.2,414.7z M6,413.7l-1,1l-1.5-1l1-1L6,413.7z M6.3,413.9
                l1.3,0.9l-1,1l-1.3-0.9L6.3,413.9z M9.3,415.9l1.3,0.9l-1,1L8.3,417L9.3,415.9z M8,416.7l-1-0.6l1-1l1,0.6L8,416.7z M11,417.1
                l1.2,0.8l-1,1l-1.1-0.8L11,417.1z M9.5,415.7l1-1.1l1.3,0.9l-1,1.1L9.5,415.7z M12.2,415.7l1.2,0.8l-1,1l-1.2-0.8L12.2,415.7z
                M13.7,416.8l1.3,0.9l-1,1l-1.3-0.9L13.7,416.8z M12.5,415.4l0.9-1l1.2,0.8l-0.9,1L12.5,415.4z M15,415.5l1.3,0.9l-1,1l-1.2-0.9
                L15,415.5z M16.7,416.6l1.1,0.7l-1,1l-1.1-0.7L16.7,416.6z M15.3,415.2l0.9-1l1.3,0.9l-0.9,1L15.3,415.2z M17.8,415.3l1.1,0.7
                l-0.9,1l-1.1-0.7L17.8,415.3z M19.2,416.3l1.3,0.9l-0.9,1l-1.3-0.9L19.2,416.3z M19.5,416l0.8-0.9l1.3,0.9l-0.8,0.9L19.5,416z
                M22,416.3l1.1,0.7l-0.8,0.9l-1.1-0.7L22,416.3z M22.2,416l1-1.1l1,0.7l-1,1.1L22.2,416z M24.7,415.9l1.2,0.8l-1,1.1l-1.2-0.8
                L24.7,415.9z M26.2,416.9l1.1,0.8l-1,1.1l-1.1-0.8L26.2,416.9z M25,415.6l0.8-0.9l1.2,0.8l-0.8,0.9L25,415.6z M27.3,415.8l1.1,0.8
                l-0.8,0.9l-1.1-0.8L27.3,415.8z M28.8,416.8l1.1,0.8l-0.8,0.9l-1.1-0.8L28.8,416.8z M27.6,415.4l1-1l1.1,0.8l-1,1L27.6,415.4z
                M30,415.4l1.1,0.8l-0.9,1l-1.2-0.8L30,415.4z M31.5,416.4l1,0.7l-1,1l-1-0.7L31.5,416.4z M30.3,415.1l0.9-1l1.1,0.8l-0.9,1
                L30.3,415.1z M32.7,415.1l1,0.7l-0.9,1l-1-0.7L32.7,415.1z M34.1,416l1.2,0.9l-0.9,1l-1.3-0.9L34.1,416z M33.1,414.8l1-1.1l1,0.7
                l-1,1.1L33.1,414.8z M35.4,414.6l1.2,0.9l-1,1.1l-1.2-0.9L35.4,414.6z M37,415.7l1,0.7l-1,1.1l-1-0.7L37,415.7z M37.3,415.4
                l0.9-0.9l1,0.7l-0.9,0.9L37.3,415.4z M39.5,415.4l1,0.7l-0.9,0.9l-1.1-0.7L39.5,415.4z M39.8,415.1l1.1-1.1l1.1,0.7l-1.1,1.1
                L39.8,415.1z M42.3,415l1.1,0.8l-1.1,1.1l-1.2-0.8L42.3,415z M42.6,414.7l0.9-0.9l1.1,0.8l-0.9,0.9L42.6,414.7z M45,414.7l1.2,0.8
                l-0.9,0.9l-1.2-0.8L45,414.7z M46.5,415.8l1.2,0.8l-0.9,0.9l-1.2-0.8L46.5,415.8z M46.8,415.5l1-1.1l1.2,0.8l-1,1.1L46.8,415.5z
                M49.3,415.4l1,0.7l-1,1.1l-1.1-0.7L49.3,415.4z M49.6,415.1l1-1l1,0.7l-1,1L49.6,415.1z M52,415.1l1.1,0.8l-1,1l-1.1-0.7
                L52,415.1z M53.4,416.1l1.4,1l-0.9,1l-1.4-1L53.4,416.1z M53.6,418.3l-1,1.1l-1.4-1l1-1.1L53.6,418.3z M50.8,418.2l-1.1-0.7l1-1.1
                l1.1,0.7L50.8,418.2z M50.5,418.5l-0.9,0.9l-1.1-0.7l0.9-0.9L50.5,418.5z M48.2,418.5l-1-0.7l0.9-0.9l1,0.7L48.2,418.5z
                M47.9,418.8l-1.1,1.2l-1-0.7l1.1-1.1L47.9,418.8z M45.4,419l-1.2-0.8l1.1-1.1l1.2,0.8L45.4,419z M43.9,418l-1.2-0.8l1.1-1.1
                l1.2,0.8L43.9,418z M43.6,418.3l-0.9,0.9l-1.2-0.8l0.9-0.9L43.6,418.3z M41.1,418.1l-1.1-0.8l0.9-0.9l1.1,0.8L41.1,418.1z
                M40.9,418.4l-1,1.1l-1.2-0.8l1-1.1L40.9,418.4z M38.4,418.4l-1.1-0.7l1-1.1l1.1,0.7L38.4,418.4z M38.1,418.7l-1,1L36,419l0.9-1
                L38.1,418.7z M35.7,418.7l-1-0.7l0.9-1l1,0.7L35.7,418.7z M35.4,419l-0.9,1l-1-0.7l1-1L35.4,419z M33.1,419.1l-1.2-0.8l1-1
                l1.2,0.9L33.1,419.1z M32.9,419.4l-0.8,0.9l-1.2-0.9l0.8-0.9L32.9,419.4z M30.5,419.2l-1-0.7l0.8-0.9l1,0.7L30.5,419.2z
                M30.2,419.5l-1,1.1l-1-0.7l1-1.1L30.2,419.5z M27.8,419.7l-1.1-0.7l1-1.1l1.1,0.8L27.8,419.7z M27.5,420l-0.8,0.9l-1.2-0.8
                l0.8-0.9L27.5,420z M25.2,419.9l-1.1-0.7l0.8-0.9l1.1,0.8L25.2,419.9z M23.8,418.9l-1.2-0.8l0.8-0.9l1.2,0.8L23.8,418.9z
                M23.5,419.2l-0.9,1l-1.2-0.8l0.9-1L23.5,419.2z M21,419.1l-1.1-0.7l0.9-1l1.1,0.7L21,419.1z M20.7,419.4l-1,1l-1.1-0.7l1-1
                L20.7,419.4z M18.4,419.4l-1.3-0.9l1-1l1.3,0.9L18.4,419.4z M18.1,419.7l-1,1l-1.3-0.9l1-1L18.1,419.7z M15.4,419.6l-1.1-0.6
                l1-1.1l1.1,0.7L15.4,419.6z M15.2,419.9l-1,1l-1.1-0.6l1-1L15.2,419.9z M12.8,420l-1.2-0.9l1-1l1.3,0.9L12.8,420z M12.5,420.3
                l-0.9,0.9l-1.3-0.9l0.9-0.9L12.5,420.3z M10,420.1l-1.1-0.8l0.9-0.9l1.1,0.8L10,420.1z M8.5,419.1l-1.3-0.9l0.9-0.9l1.3,0.9
                L8.5,419.1z M6.9,418l-1-0.6l0.9-0.9l1,0.6L6.9,418z M8.9,413.5l-1,1.1l-1.3-0.9l1-1.1L8.9,413.5z M9.3,413.7l1,0.7l-1,1.1l-1-0.6
                L9.3,413.7z M11.5,413l-0.9,1l-0.9-0.6l0.9-1L11.5,413z M11.8,413.3l1.3,0.9l-0.9,1l-1.3-0.9L11.8,413.3z M14.3,412.9l-0.9,1
                l-1.3-0.9l0.9-1L14.3,412.9z M14.6,413.1l1.3,0.9l-0.9,1l-1.2-0.8L14.6,413.1z M17,412.8l-0.8,0.9l-1.3-0.9l0.8-0.9L17,412.8z
                M18.6,414l-0.8,0.9l-1.3-0.9l0.8-0.9L18.6,414z M19,414.2l1.1,0.7l-0.8,0.9l-1.1-0.7L19,414.2z M21.3,413.6l-1,1.1l-1.1-0.7
                l1-1.1L21.3,413.6z M21.7,413.8l1.3,0.9l-1,1.1l-1.3-0.9L21.7,413.8z M24.1,413.5l-0.8,0.9l-1.3-0.9l0.8-0.9L24.1,413.5z
                M24.4,413.8l1.1,0.7l-0.8,0.9l-1.1-0.7L24.4,413.8z M26.7,413.2l-1,1l-1.1-0.7l1-1L26.7,413.2z M27.1,413.4l1.2,0.8l-1,1
                l-1.2-0.9L27.1,413.4z M29.5,413l-0.9,1l-1.2-0.8l0.9-1L29.5,413z M29.8,413.2l1.1,0.8l-0.9,1l-1.1-0.8L29.8,413.2z M32.3,412.6
                l-1,1.1l-1.1-0.8l1-1.1L32.3,412.6z M32.6,412.8l1.1,0.8l-1,1.1l-1.1-0.8L32.6,412.8z M34.9,412.4l-0.9,0.9l-1.1-0.8l0.9-0.9
                L34.9,412.4z M36.2,413.3l-0.9,0.9l-1-0.7l0.9-0.9L36.2,413.3z M36.6,413.5l1.2,0.9l-0.9,0.9l-1.2-0.9L36.6,413.5z M39.2,412.9
                l-1.1,1.1l-1.2-0.9L38,412L39.2,412.9z M39.5,413.2l1,0.7l-1.1,1.1l-1-0.7L39.5,413.2z M41.7,412.6l-0.9,0.9l-1-0.7l0.9-0.9
                L41.7,412.6z M42,412.8l1.1,0.7l-0.9,0.9l-1.1-0.7L42,412.8z M44.5,412.2l-1.1,1.1l-1.1-0.7l1.1-1.1L44.5,412.2z M45.9,413.2
                l-1,1.1l-1.1-0.8l1-1.1L45.9,413.2z M47.4,414.2l-1,1.1l-1.2-0.8l1-1.1L47.4,414.2z M48.7,412.9l-1,1.1l-1.2-0.8l1-1.1L48.7,412.9
                z M50.2,413.9l-1,1l-1.1-0.9l1-1.1L50.2,413.9z M51.6,412.5l-1.1,1.1l-1.2-0.8l1.1-1.2L51.6,412.5z M53,413.5l-1.1,1.2l-1-0.7
                l1.1-1.2L53,413.5z M54.4,414.5l-1.1,1.2l-1.1-0.8l1.1-1.2L54.4,414.5z M55,416.9l-1.4-1l1.1-1.2l1.4,1L55,416.9z M56.2,418.2
                l-0.9,1l-1.2-0.8l0.9-1L56.2,418.2z M57.3,420.6l0.9-1l1.4,1l-0.9,0.9L57.3,420.6z M55.6,419.4l0.9-1l1.3,0.9l-0.9,1L55.6,419.4z
                M54.3,420.8l1-1.1l1.3,0.9l-1,1.1L54.3,420.8z M54,420.6l-1.2-0.8l1-1.1l1.2,0.8L54,420.6z M51.6,421l0.9-0.9l1.2,0.8l-0.9,0.9
                L51.6,421z M51.3,420.8l-1.4-1l0.9-0.9l1.4,1L51.3,420.8z M48.5,421.2l1.1-1.2l1.5,1l-1.1,1.1L48.5,421.2z M48.2,421l-1.1-0.7
                l1.1-1.1l1.1,0.7L48.2,421z M46,421.5l0.9-0.9l1.1,0.7l-0.9,0.9L46,421.5z M44.6,420.5l0.9-0.9l1.1,0.7l-0.9,0.9L44.6,420.5z
                M44.2,420.3l-1.1-0.9l0.9-0.9l1.2,0.8L44.2,420.3z M41.7,420.8l1-1.1l1.2,0.8l-1,1.1L41.7,420.8z M40.2,419.8l1-1.1l1.2,0.8
                l-1,1.1L40.2,419.8z M39,421l0.9-1l1.2,0.8l-0.9,1L39,421z M38.6,420.8l-1.1-0.8l0.9-1l1.1,0.8L38.6,420.8z M36.3,421.3l1-1
                l1.1,0.8l-0.9,1L36.3,421.3z M35.9,421l-1.1-0.7l1-1l1.1,0.7L35.9,421z M33.7,421.5l0.8-0.9l1.1,0.8l-0.8,0.9L33.7,421.5z
                M33.4,421.2l-1-0.7l0.8-0.9l1,0.7L33.4,421.2z M31.1,422l1-1.1l1,0.7l-1,1.1L31.1,422z M30.7,421.8l-1.2-0.9l1-1.1l1.2,0.9
                L30.7,421.8z M28.4,422.1l0.8-0.9l1.2,0.9l-0.8,0.9L28.4,422.1z M28,421.9l-1-0.7l0.8-0.9l1,0.7L28,421.9z M25.9,422.4l0.9-1
                l1,0.7l-0.9,1L25.9,422.4z M24.4,421.4l0.9-1l1.1,0.8l-0.9,1L24.4,421.4z M24,421.2l-1.1-0.8l0.9-1l1.1,0.8L24,421.2z M21.7,421.7
                l1-1l1.1,0.8l-1,1L21.7,421.7z M21.3,421.5l-1.2-0.8l1-1l1.2,0.8L21.3,421.5z M18.8,422l1-1l1.2,0.8l-1,1L18.8,422z M18.5,421.8
                l-1.1-0.8l1-1l1,0.7L18.5,421.8z M16.2,422.4l1-1l1.1,0.7l-1,1L16.2,422.4z M15.8,422.2l-1.3-0.9l1-1l1.3,0.9L15.8,422.2z
                M13.4,422.5l0.9-0.9l1.3,0.9l-0.9,0.9L13.4,422.5z M13,422.3l-1.1-0.7l0.9-0.9l1.1,0.7L13,422.3z M10.8,422.8l0.9-0.9l1.1,0.7
                l-0.9,1L10.8,422.8z M9.2,421.7l0.9-0.9l1.3,0.9l-0.9,0.9L9.2,421.7z M7.7,420.7l0.9-1l1.1,0.8l-0.9,0.9L7.7,420.7z M6.1,419.5
                l0.9-1l1.3,0.9l-0.9,1L6.1,419.5z M5.7,417.6l1,0.7l-0.9,1l-1-0.6L5.7,417.6z M4.3,416.2l0.9-0.9l1.3,0.9l-0.9,1L4.3,416.2z
                M2.4,414.9l0.9-0.9l1.5,1.1L3.9,416L2.4,414.9z M2,414.7l-1.2-0.8l0.9-0.9l1.2,0.8L2,414.7z" />
                </g>
            </g>
            <g id="AREA_GENERAL">
                <rect id="AREA_1" x="67.7" y="44.6" class="st10" width="1112.1" height="815.7" />
            </g>
          </svg>

          <svg v-if="print" id="YARD_GEN" data-name="YARD GEN" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 502.5 373.9" style="enable-background:new 0 0 502.5 373.9;" xml:space="preserve">
            <g id="Capa_1">
              <path class="st0-print" d="M10.8,0.3h480.8c5.8,0,10.5,4.7,10.5,10.5V363c0,5.8-4.7,10.5-10.5,10.5H10.8c-5.8,0-10.5-4.7-10.5-10.5V10.8
            C0.3,5,5,0.3,10.8,0.3z" />
              <rect x="5.3" y="9.4" class="st0-print" width="483.1" height="352.8" />
              <path class="st0-print" d="M118.1,50.6" />
              <path class="st0-print" d="M184.8,49.5" />
              <path class="st0-print" d="M126.1,49.5" />
              <polygon class="st0-print"
                points="483.3,141.5 478.7,146.4 478.7,18.2 23.2,18.2 23.2,127.6 18.4,133.7 18.5,13.5 483.3,13.5 	" />
              <polygon class="st0-print"
                points="483.3,356.5 18.5,356.5 18.5,173.9 23.2,167.8 23.2,351.9 478.7,351.9 478.7,196.6 483.1,190.7 	" />
              <line class="st1-print" x1="22" y1="166.4" x2="22" y2="168.6" />
              <line class="st1-print" x1="20.6" y1="168.2" x2="20.7" y2="170.3" />
              <line class="st1-print" x1="19.3" y1="170" x2="19.4" y2="172.2" />
              <line class="st1-print" x1="18.1" y1="171.5" x2="18.2" y2="173.7" />
              <path class="st2-print"
                d="M17.5,174.4c-0.2,0-0.4-0.1-0.5-0.2c-0.4-0.4-0.5-1-0.1-1.5l5.1-6.8c0.3-0.4,1-0.5,1.4-0.2c0,0,0,0,0,0
            c0.4,0.4,0.5,1,0.1,1.5l-5.1,6.8C18.2,174.3,17.8,174.4,17.5,174.4z M22.8,165.9h-0.1c-0.2,0-0.3,0.1-0.4,0.2l-5.1,6.7
            c-0.3,0.3-0.2,0.7,0.1,1c0.2,0.2,0.6,0.2,0.8-0.1l0,0l5.1-6.8c0.3-0.3,0.2-0.7-0.1-1C23,165.9,22.9,165.9,22.8,165.9L22.8,165.9z" />
              <path class="st3-print" d="M44.3,174.3l-25.7-0.7l4.5-5.7l24.8,0.7c0.3,0,0.6,0.3,0.6,0.6l0,0c0,0.1-0.1,0.3-0.2,0.4l-3.5,4.5
            C44.7,174.3,44.5,174.3,44.3,174.3z" />
              <path class="st4-print" d="M48.3,169.5l0.1-0.2l-0.4-0.4l0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.1l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.6,0.4
            l-0.5-0.5l0.2-0.1h-0.5l0.1,0.1l-0.7,0.5l-0.5-0.5l0.2-0.1h-0.5l0.1,0.1l-0.6,0.4l-0.5-0.5l0.1-0.1h-0.4l-0.5,0.3l-0.3-0.4H42
            l-0.5,0.3l-0.3-0.4h-0.5l-0.5,0.3l-0.3-0.4h-0.3L40,169l-0.6,0.4l-0.4-0.5l0.5-0.4h-0.3l-0.3,0.2l-0.2-0.2h-0.3l0.3,0.4l-0.5,0.4
            l-0.4-0.4l0.5-0.3h-0.3l-0.2,0.2l-0.2-0.2h-0.3l0.3,0.3l-0.6,0.4l-0.5-0.5l0.4-0.3h-0.3l-0.2,0.1l-0.1-0.1h-0.3l0.2,0.3l-0.5,0.3
            l-0.5-0.5l0.2-0.1h-0.6l0.1,0.1l-0.6,0.4l-0.5-0.5h-0.3l-0.5,0.4l-0.4-0.4h-0.4l-0.5,0.3l-0.3-0.4H31l0.5,0.5L31,169l-0.5-0.6
            l0.4-0.2h-0.3l-0.2,0.1l-0.1-0.1H30l0.2,0.2l-0.6,0.4l-0.4-0.5l0.3-0.2h-0.3l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.5,0.3l-0.5-0.5
            h-0.3l-0.5,0.4l-0.4-0.4h-0.3l0.5,0.5l-0.5,0.4l-0.5-0.5l0.5-0.4H26l-0.3,0.2l-0.2-0.2h-0.3l0.3,0.4l-0.6,0.4l-0.5-0.6l0.3-0.2
            h-0.3l-0.1,0.1l-0.1-0.1H24l0.2,0.2l-0.6,0.4l-0.4-0.4l0.3-0.2h-0.3l-0.1,0.1l0,0l-0.4,0.6l0.4-0.3l0.4,0.4l-0.5,0.4l-0.4-0.4
            l-0.1,0.2l0.3,0.3l-0.5,0.4l-0.2-0.2l-0.1,0.2l0.1,0.2l-0.6,0.4l-0.4,0.6l0.4-0.3l0.5,0.6l-0.6,0.4l-0.5-0.5l-0.1,0.2l0.5,0.5
            l-0.6,0.4l-0.3-0.3l-0.1,0.2l0.2,0.3l-0.7,0.5l-0.1-0.1l-0.1,0.2l0,0l-0.2,0.1l-0.4,0.6l0.8-0.5l0.4,0.4l-0.4,0.3H20l0.2-0.1
            l0.1,0.2h0.3l-0.3-0.3l0.7-0.5l0.5,0.5l-0.4,0.3h0.3l0.2-0.1l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.4,0.4l-0.4,0.2H23l0.2-0.1l0.1,0.1
            h0.3l-0.2-0.2l0.6-0.4l0.5,0.6l-0.1,0.1h0.5l-0.1-0.1l0.7-0.5l0.4,0.4l-0.2,0.1h0.6l-0.1-0.1l0.5-0.4l0.5,0.5l0,0l0.1-0.1l-0.4-0.5
            l0.5-0.3l0.5,0.5l-0.6,0.4h0.3l0.4-0.3l0.3,0.3h0.2l0.1-0.1l-0.4-0.4l0.6-0.4l0.5,0.5l-0.5,0.4h0.3l0.3-0.2l0.2,0.2h0.3l-0.3-0.4
            L30,173l0.5,0.5l-0.4,0.3h0.3l0.2-0.1l0.1,0.1H31l-0.3-0.3l0.6-0.4l0.4,0.4l-0.4,0.2h0.3l0.1-0.1l0.1,0.1h0.3l-0.2-0.2l0.5-0.4
            l0.5,0.5l-0.1,0.1h0.5l-0.1-0.1l0.5-0.3l0.4,0.4h0.4l0.5-0.4l0.4,0.4h0.5l0.4-0.2l0.2,0.3h0.3l-0.4-0.4l0.5-0.4l0.5,0.5L37,174h0.3
            l0.2-0.1l0.2,0.2H38l-0.3-0.3l0.5-0.4l0.5,0.5l-0.2,0.2h0.6l-0.1-0.1l0.6-0.4l0.5,0.5l-0.1,0h0.5l0.5-0.4l0.4,0.4h0.4l0.6-0.4
            l0.4,0.4h0.4l0.4-0.3l0.3,0.3h0.3l-0.4-0.4l0.6-0.4l0.5,0.5l0.1-0.1l-0.5-0.5l0.5-0.4l0.3,0.4l0.1-0.1l-0.3-0.3l0.6-0.4l0.1,0.1
            l0.1-0.1l-0.1-0.1l0.3-0.2l0.4-0.5l-0.2,0.2l-0.4-0.5l0.6-0.4L47,171l0.1-0.1l-0.3-0.3l0.8-0.5l0.1,0.1l0.1-0.2l-0.1-0.1l0.2-0.2
            L48.3,169.5z M46.5,168.9l0.5,0.5l-0.7,0.5l-0.5-0.5L46.5,168.9z M46,170l-0.6,0.4l-0.5-0.5l0.6-0.4L46,170z M44.6,171l-0.6,0.4
            l-0.5-0.5l0.6-0.4L44.6,171z M44.3,170.4l0.5-0.3l0.5,0.5l-0.5,0.3L44.3,170.4z M43.8,171.6l-0.6,0.4l-0.5-0.5l0.6-0.4L43.8,171.6z
            M43.3,170.8l-0.5-0.5l0.6-0.4l0.5,0.5L43.3,170.8z M43.2,170.9l-0.6,0.4l-0.5-0.5l0.6-0.4L43.2,170.9z M42.4,171.4l-0.6,0.4
            l-0.5-0.5l0.6-0.4L42.4,171.4z M42,170.6l-0.5-0.5l0.6-0.4l0.5,0.5L42,170.6z M41.8,170.7l-0.6,0.4l-0.5-0.5l0.6-0.4L41.8,170.7z
            M41,171.3l-0.5,0.4l-0.5-0.5l0.5-0.4L41,171.3z M40.6,170.5l-0.4-0.4l0.6-0.4l0.4,0.5L40.6,170.5z M40.4,170.7l-0.5,0.4l-0.4-0.5
            l0.5-0.4L40.4,170.7z M39.8,171.1l-0.6,0.4l-0.4-0.5l0.6-0.4L39.8,171.1z M38.6,170.9l-0.4-0.4l0.6-0.4l0.4,0.4L38.6,170.9z
            M38.4,171.1l-0.5,0.4l-0.4-0.4l0.5-0.4L38.4,171.1z M37.4,170.8l-0.5-0.5l0.5-0.4l0.5,0.5L37.4,170.8z M37.2,171l-0.6,0.4
            l-0.5-0.5l0.6-0.4L37.2,171z M36.5,171.5l-0.5,0.4l-0.5-0.5l0.5-0.4L36.5,171.5z M36,170.7l-0.4-0.4l0.6-0.4l0.4,0.4L36,170.7z
            M35.9,170.8l-0.5,0.4l-0.4-0.4l0.5-0.4L35.9,170.8z M35.1,171.3l-0.5,0.4l-0.4-0.4l0.5-0.4L35.1,171.3z M34.8,170.6l-0.5-0.5
            l0.5-0.4l0.5,0.5L34.8,170.6z M34.6,170.7l-0.5,0.4l-0.5-0.5l0.5-0.4L34.6,170.7z M33.9,171.2l-0.5,0.3L33,171l0.5-0.3L33.9,171.2z
            M33.5,170.5L33,170l0.5-0.4l0.5,0.5L33.5,170.5z M33.3,170.6l-0.5,0.3l-0.5-0.5l0.5-0.3L33.3,170.6z M32.7,171l-0.6,0.4l-0.5-0.5
            l0.6-0.4L32.7,171z M32.2,170.3l-0.5-0.5l0.5-0.3l0.5,0.5L32.2,170.3z M32.1,170.4l-0.6,0.4l-0.5-0.5l0.6-0.4L32.1,170.4z
            M31.3,170.9l-0.5,0.3l-0.5-0.5l0.5-0.3L31.3,170.9z M30.2,170.6l-0.4-0.4l0.5-0.3l0.4,0.4L30.2,170.6z M30.1,170.7l-0.5,0.4
            l-0.4-0.4l0.5-0.4L30.1,170.7z M29,170.5l-0.5-0.6l0.5-0.4l0.5,0.6L29,170.5z M28.8,170.6l-0.5,0.4l-0.5-0.6l0.5-0.4L28.8,170.6z
            M27.6,170.3l-0.4-0.4l0.5-0.4l0.4,0.4L27.6,170.3z M27.5,170.4l-0.6,0.4l-0.4-0.5l0.6-0.4L27.5,170.4z M26.7,170.9l-0.6,0.4
            l-0.4-0.4l0.6-0.4L26.7,170.9z M25.6,170.7l-0.5-0.5l0.6-0.4l0.5,0.5L25.6,170.7z M25.4,170.8l-0.5,0.4l-0.5-0.5l0.5-0.4
            L25.4,170.8z M24.3,170.5l-0.5-0.5l0.5-0.4l0.5,0.5L24.3,170.5z M24.1,170.6l-0.5,0.4l-0.5-0.5l0.5-0.4L24.1,170.6z M23.4,171
            l-0.7,0.5l-0.5-0.5l0.7-0.5L23.4,171z M23.6,171.2l0.5,0.5l-0.7,0.5l-0.5-0.5L23.6,171.2z M23.7,171.1l0.5-0.4l0.5,0.5l-0.5,0.4
            L23.7,171.1z M24.9,171.4l0.4,0.4l-0.5,0.4l-0.4-0.5L24.9,171.4z M25.1,171.3l0.5-0.4l0.4,0.4l-0.5,0.4L25.1,171.3z M26.1,171.5
            l0.5,0.6l-0.5,0.3l-0.5-0.6L26.1,171.5z M26.3,171.4l0.6-0.4l0.5,0.6l-0.6,0.4L26.3,171.4z M27,170.9l0.6-0.4l0.5,0.6l-0.6,0.4
            L27,170.9z M28.3,171.2l0.4,0.4l-0.6,0.4l-0.4-0.4L28.3,171.2z M28.4,171.1l0.5-0.4l0.4,0.4l-0.5,0.4L28.4,171.1z M29.5,171.3
            l0.5,0.5l-0.5,0.4l-0.5-0.5L29.5,171.3z M29.7,171.2l0.5-0.4l0.5,0.5l-0.5,0.4L29.7,171.2z M30.9,171.5l0.5,0.5l-0.5,0.4l-0.5-0.5
            L30.9,171.5z M31,171.4l0.5-0.3l0.5,0.5l-0.5,0.3L31,171.4z M32.1,171.7l0.5,0.5l-0.5,0.3l-0.5-0.5L32.1,171.7z M32.2,171.6
            l0.6-0.4l0.5,0.5l-0.6,0.4L32.2,171.6z M33.4,171.8l0.4,0.4l-0.6,0.4l-0.4-0.4L33.4,171.8z M33.6,171.7l0.5-0.3l0.4,0.4l-0.5,0.3
            L33.6,171.7z M34.6,171.9l0.5,0.5l-0.5,0.3l-0.5-0.5L34.6,171.9z M34.8,171.8l0.5-0.4l0.5,0.5l-0.5,0.4L34.8,171.8z M35.9,172.1
            l0.4,0.4l-0.5,0.4l-0.4-0.4L35.9,172.1z M36,172l0.5-0.4l0.4,0.4l-0.5,0.4L36,172z M36.7,171.5l0.6-0.4l0.4,0.4l-0.6,0.4
            L36.7,171.5z M37.8,171.7l0.4,0.5l-0.6,0.4l-0.4-0.4L37.8,171.7z M38,171.6l0.5-0.3l0.4,0.4l-0.5,0.4L38,171.6z M39.1,171.8
            l0.5,0.5l-0.5,0.4l-0.5-0.5L39.1,171.8z M39.2,171.7l0.6-0.4l0.5,0.5l-0.6,0.4L39.2,171.7z M40.5,171.9l0.5,0.5l-0.6,0.4l-0.5-0.5
            L40.5,171.9z M40.6,171.8l0.5-0.4l0.5,0.5l-0.5,0.4L40.6,171.8z M41.8,172.1l0.5,0.5l-0.5,0.4l-0.5-0.5L41.8,172.1z M42,172
            l0.6-0.4l0.5,0.5l-0.6,0.4L42,172z M43.2,172.2l0.4,0.4l-0.6,0.4l-0.4-0.4L43.2,172.2z M43.4,172.1l0.5-0.4l0.4,0.4l-0.5,0.4
            L43.4,172.1z M44.1,171.6l0.6-0.4l0.4,0.4l-0.6,0.4L44.1,171.6z M44.9,171.1l0.5-0.3l0.4,0.5l-0.5,0.3L44.9,171.1z M45,168.9
            l0.5,0.5l-0.6,0.4l-0.5-0.5L45,168.9z M44.6,170l-0.5,0.3l-0.5-0.5l0.5-0.3L44.6,170z M43.6,168.9l0.5,0.5l-0.5,0.3l-0.5-0.5
            L43.6,168.9z M43.4,169.8l-0.6,0.4l-0.5-0.5l0.6-0.4L43.4,169.8z M42.4,168.7l0.4,0.5l-0.6,0.4l-0.4-0.5L42.4,168.7z M42,169.7
            l-0.6,0.4l-0.4-0.5l0.6-0.4L42,169.7z M41,168.7l0.4,0.4l-0.6,0.4l-0.4-0.4L41,168.7z M40.3,169.2l0.4,0.4l-0.6,0.4l-0.4-0.4
            L40.3,169.2z M39.9,170.1l-0.5,0.4L39,170l0.5-0.4L39.9,170.1z M38.9,169.1l0.5,0.5l-0.5,0.4l-0.5-0.5L38.9,169.1z M38.7,170.1
            l-0.6,0.4l-0.5-0.5l0.6-0.4L38.7,170.1z M37.7,169l0.4,0.4l-0.6,0.4l-0.4-0.4L37.7,169z M37.3,169.9l-0.5,0.4l-0.4-0.4l0.5-0.4
            L37.3,169.9z M36.3,168.9l0.5,0.5l-0.5,0.4l-0.5-0.5L36.3,168.9z M36.1,169.9l-0.6,0.4l-0.5-0.5l0.6-0.4L36.1,169.9z M35,168.6
            l0.5,0.5l-0.6,0.4l-0.5-0.5L35,168.6z M34.7,169.6l-0.5,0.4l-0.5-0.5l0.5-0.4L34.7,169.6z M33.6,168.5l0.5,0.5l-0.5,0.4l-0.5-0.5
            L33.6,168.5z M33.4,169.5l-0.5,0.4l-0.5-0.5l0.5-0.4L33.4,169.5z M32.4,168.4l0.4,0.4l-0.5,0.4l-0.4-0.4L32.4,168.4z M31.7,168.9
            l0.4,0.4l-0.5,0.3l-0.4-0.4L31.7,168.9z M31.5,169.7l-0.6,0.4l-0.4-0.4l0.6-0.4L31.5,169.7z M30.4,168.6l0.5,0.6l-0.6,0.4l-0.5-0.6
            L30.4,168.6z M30.2,169.7l-0.5,0.3l-0.5-0.6l0.5-0.3L30.2,169.7z M29,168.5l0.4,0.4l-0.5,0.3l-0.4-0.4L29,168.5z M28.8,169.4
            l-0.5,0.4l-0.4-0.4l0.5-0.4L28.8,169.4z M27.7,168.3l0.5,0.5l-0.5,0.4l-0.5-0.5L27.7,168.3z M27,168.7l0.5,0.5l-0.5,0.4l-0.5-0.5
            L27,168.7z M26.3,169.2l0.5,0.5l-0.6,0.4l-0.5-0.5L26.3,169.2z M25.7,168.6l0.5,0.5l-0.6,0.4l-0.5-0.5L25.7,168.6z M25,169.1
            l0.5,0.5l-0.6,0.4l-0.5-0.5L25,169.1z M24.3,168.4l0.5,0.6l-0.6,0.4l-0.5-0.6L24.3,168.4z M23.6,168.9l0.5,0.6l-0.5,0.4l-0.5-0.6
            L23.6,168.9z M22.9,169.4l0.5,0.6l-0.5,0.4l-0.5-0.6L22.9,169.4z M21.6,170.3l0.7-0.5l0.5,0.6l-0.7,0.5L21.6,170.3z M22.1,171.1
            l0.5,0.5l-0.6,0.4l-0.4-0.5L22.1,171.1z M20.3,173.1l-0.4-0.4l0.7-0.5l0.4,0.5L20.3,173.1z M21.1,172.5l-0.4-0.4l0.6-0.4l0.5,0.5
            L21.1,172.5z M21.8,173.2l-0.5-0.5l0.6-0.4l0.5,0.5L21.8,173.2z M22.1,172.1l0.6-0.4l0.5,0.5l-0.6,0.4L22.1,172.1z M23.1,173.2
            l-0.4-0.5l0.6-0.4l0.4,0.5L23.1,173.2z M23.4,172.2l0.7-0.5l0.4,0.4l-0.7,0.5L23.4,172.2z M24.5,173.4l-0.5-0.6l0.7-0.5l0.5,0.6
            L24.5,173.4z M24.9,172.3l0.5-0.4l0.5,0.6l-0.5,0.4L24.9,172.3z M25.9,173.4l-0.4-0.4l0.5-0.4l0.4,0.4L25.9,173.4z M26.6,172.9
            l-0.4-0.4l0.5-0.4l0.4,0.4L26.6,172.9z M26.9,172l0.6-0.4l0.4,0.4l-0.6,0.4L26.9,172z M27.9,173.1l-0.5-0.5l0.6-0.4l0.5,0.5
            L27.9,173.1z M28.6,172.6l-0.5-0.5l0.6-0.4l0.5,0.5L28.6,172.6z M29.2,173.3l-0.5-0.5l0.6-0.4l0.5,0.5L29.2,173.3z M29.5,172.3
            l0.5-0.4l0.5,0.5l-0.5,0.4L29.5,172.3z M30.5,173.4l-0.5-0.5l0.5-0.4l0.5,0.5L30.5,173.4z M30.8,172.4l0.5-0.4l0.5,0.5l-0.5,0.4
            L30.8,172.4z M31.8,173.4l-0.4-0.4l0.5-0.4l0.4,0.4L31.8,173.4z M32.1,172.5l0.5-0.3l0.4,0.4l-0.5,0.3L32.1,172.5z M33.1,173.6
            l-0.5-0.5l0.5-0.3l0.5,0.5L33.1,173.6z M33.2,172.6l0.6-0.4l0.5,0.5l-0.6,0.4L33.2,172.6z M34.2,173.7l-0.4-0.4l0.6-0.4l0.4,0.4
            L34.2,173.7z M34.6,172.8l0.5-0.3l0.4,0.4l-0.5,0.3L34.6,172.8z M35.6,173.8l-0.4-0.5l0.5-0.3l0.4,0.5L35.6,173.8z M36.2,173.3
            l-0.4-0.5l0.5-0.4l0.4,0.5L36.2,173.3z M36.5,172.3L37,172l0.4,0.5L37,173L36.5,172.3z M37.5,173.4l-0.5-0.5l0.5-0.4l0.5,0.5
            L37.5,173.4z M37.8,172.4l0.6-0.4l0.5,0.5l-0.6,0.4L37.8,172.4z M38.8,173.6l-0.5-0.5l0.6-0.4l0.5,0.5L38.8,173.6z M39.1,172.6
            l0.5-0.4l0.5,0.5l-0.5,0.4L39.1,172.6z M40.2,173.7l-0.5-0.5l0.5-0.4l0.5,0.5L40.2,173.7z M40.4,172.7l0.6-0.4l0.5,0.5l-0.6,0.4
            L40.4,172.7z M41.4,173.8l-0.4-0.4l0.6-0.4l0.4,0.4L41.4,173.8z M41.8,172.8l0.5-0.4l0.4,0.4l-0.5,0.4L41.8,172.8z M42.8,173.9
            l-0.4-0.5l0.5-0.4l0.4,0.5L42.8,173.9z M43.4,173.4l-0.4-0.4l0.6-0.4l0.4,0.4L43.4,173.4z M44.2,172.9l-0.4-0.5l0.5-0.4l0.4,0.5
            L44.2,172.9z M44.9,172.4l-0.4-0.5l0.6-0.4l0.4,0.5L44.9,172.4z M46.2,171.5l-0.5,0.3l-0.4-0.5l0.5-0.3L46.2,171.5z M45.8,170.8
            l-0.4-0.4l0.6-0.4l0.4,0.4L45.8,170.8z M46.6,170.2l-0.4-0.4l0.8-0.5l0.4,0.4L46.6,170.2z M47.1,169.2l0.6-0.4l0.4,0.5l-0.6,0.4
            L47.1,169.2z" />
              <line class="st1-print" x1="23.1" y1="128" x2="23.1" y2="130.1" />
              <line class="st1-print" x1="21.7" y1="129.8" x2="21.8" y2="131.9" />
              <line class="st1-print" x1="20.4" y1="131.5" x2="20.5" y2="133.7" />
              <line class="st1-print" x1="19.2" y1="133.1" x2="19.3" y2="135.2" />
              <path class="st2-print"
                d="M18.6,135.9c-0.2,0-0.4-0.1-0.5-0.2c-0.4-0.4-0.5-1-0.1-1.5l5.1-6.7c0.3-0.4,1-0.5,1.4-0.2c0,0,0,0,0,0
            c0.4,0.4,0.5,1,0.1,1.5l-5.1,6.7C19.3,135.8,18.9,136,18.6,135.9z M23.9,127.4h-0.1c-0.2,0-0.3,0.1-0.4,0.3l-5.1,6.7
            c-0.3,0.3-0.2,0.7,0.1,1c0.3,0.2,0.6,0.2,0.8-0.1c0,0,0,0,0,0l0,0l5.1-6.8c0.3-0.3,0.2-0.7-0.1-1C24.1,127.5,24,127.5,23.9,127.4z" />
              <path class="st3-print" d="M45.4,135.9l-25.7-0.7l4.5-5.7l24.8,0.7c0.3,0,0.6,0.3,0.6,0.6l0,0c0,0.1-0.1,0.3-0.2,0.4l-3.5,4.5
            C45.8,135.8,45.6,135.9,45.4,135.9z" />
              <path class="st4-print"
                d="M49.4,131c0,0,0.1-0.1,0.1-0.1l-0.4-0.4l0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2
            l-0.6,0.4l-0.5-0.5l0.2-0.1h-0.5l0.1,0.1l-0.7,0.5l-0.5-0.5l0.2-0.1h-0.5l0.1,0.1l-0.7,0.4l-0.5-0.5l0.1-0.1h-0.4l-0.5,0.3
            l-0.3-0.4h-0.5l-0.5,0.3l-0.3-0.4h-0.5l-0.5,0.3L41,130h-0.3l0.4,0.5l-0.6,0.4l-0.5-0.5l0.5-0.4h-0.3l-0.3,0.2l-0.2-0.2h-0.3
            l0.3,0.4l-0.5,0.4l-0.4-0.4l0.5-0.3H39l-0.2,0.2l-0.2-0.2h-0.3l0.3,0.3l-0.6,0.4l-0.5-0.5l0.4-0.3h-0.3l-0.2,0.1l-0.1-0.1H37
            l0.2,0.3l-0.5,0.4l-0.5-0.5l0.2-0.1h-0.6l0.1,0.1l-0.6,0.4l-0.5-0.5h-0.3l-0.5,0.4l-0.4-0.4h-0.4l-0.5,0.3l-0.3-0.3h-0.3l0.5,0.5
            l-0.5,0.3l-0.5-0.6l0.4-0.2h-0.3l-0.2,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.6,0.4l-0.4-0.5l0.3-0.2h-0.3l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2
            l-0.5,0.3l-0.5-0.5h-0.3l-0.5,0.4l-0.4-0.4h-0.3l0.5,0.5l-0.5,0.4l-0.5-0.5l0.5-0.4h-0.3l-0.3,0.2l-0.2-0.2h-0.3l0.3,0.4l-0.6,0.4
            l-0.5-0.6l0.3-0.2h-0.3l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.6,0.4l-0.4-0.4l0.3-0.2h-0.3l-0.1,0.1l0,0l-0.4,0.6l0.4-0.3l0.4,0.4
            l-0.5,0.4l-0.4-0.4l-0.1,0.1l0.3,0.3l-0.5,0.4l-0.2-0.2l-0.1,0.1l0.1,0.2l-0.6,0.4l-0.4,0.6l0.4-0.3l0.5,0.6l-0.6,0.4l-0.5-0.8
            l-0.1,0.2l0.4,0.5l-0.6,0.4l-0.3-0.3l-0.1,0.2l0.2,0.3l-0.7,0.5l-0.1-0.1l-0.1,0.2l0,0l-0.2,0.1l-0.4,0.6l0.8-0.5l0.4,0.4l-0.4,0.3
            h0.3l0.2-0.1l0.1,0.2h0.3l-0.3-0.3l0.7-0.5l0.5,0.5l-0.4,0.2h0.3l0.2-0.1l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.4,0.5l-0.4,0.2H24
            l0.2-0.1l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.5,0.6l-0.1,0.1h0.5l-0.1-0.1l0.7-0.5l0.4,0.4l-0.2,0.1h0.6l-0.1-0.1l0.5-0.4l0.5,0.5l0,0
            l0.1-0.1l-0.4-0.5l0.5-0.4l0.5,0.5l-0.6,0.4h0.3L29,135l0.3,0.3h0.2h0.1l-0.4-0.4l0.6-0.4l0.5,0.5l-0.5,0.4H30l0.3-0.2l0.2,0.2h0.3
            l-0.3-0.4l0.6-0.4l0.5,0.5l-0.4,0.3h0.3l0.2-0.1l0.1,0.1h0.3l-0.3-0.3l0.6-0.4l0.4,0.4l-0.3,0.2h0.3l0.2-0.1l0.1,0.1h0.3l-0.2-0.2
            l0.5-0.4l0.5,0.5l-0.1,0.1h0.5l-0.1-0.1l0.5-0.3l0.4,0.4h0.4l0.5-0.4l0.4,0.4H37l0.4-0.2l0.2,0.3h0.3l-0.4-0.4l0.5-0.4l0.5,0.5
            l-0.4,0.3h0.3l0.2-0.1l0.2,0.2h0.3l-0.3-0.3l0.5-0.4l0.5,0.5l-0.2,0.2h0.7l-0.1-0.1l0.6-0.4l0.5,0.5l-0.1,0.1h0.5l0,0l0.5-0.4
            l0.4,0.4h0.4l0.6-0.4l0.4,0.4h0.4l0.4-0.3l0.3,0.3h0.3l-0.4-0.4l0.6-0.4l0.5,0.5l0.1-0.2l-0.5-0.5l0.5-0.4l0.3,0.4l0.1-0.2
            l-0.3-0.3l0.6-0.4l0.1,0.1l0.1-0.2l-0.1-0.1l0.3-0.2l0.4-0.5l-0.2,0.2l-0.4-0.5l0.6-0.4l0.3,0.4l0.1-0.2L48,132l0.8-0.5l0.1,0.1
            l0.1-0.2l-0.1-0.1l0.2-0.1L49.4,131z M47.6,130.4l0.5,0.5l-0.7,0.5l-0.5-0.5L47.6,130.4z M47.1,131.5l-0.6,0.4l-0.5-0.5l0.6-0.4
            L47.1,131.5z M45.7,132.5L45,133l-0.5-0.5l0.6-0.4L45.7,132.5z M45.4,131.9l0.5-0.3l0.5,0.5l-0.5,0.3L45.4,131.9z M44.9,133.1
            l-0.6,0.4l-0.5-0.5l0.6-0.4L44.9,133.1z M44.4,132.3l-0.5-0.5l0.6-0.4l0.5,0.5L44.4,132.3z M44.3,132.4l-0.6,0.4l-0.5-0.5l0.6-0.4
            L44.3,132.4z M43.5,132.9l-0.6,0.4l-0.5-0.5l0.6-0.4L43.5,132.9z M43.1,132.1l-0.5-0.5l0.6-0.4l0.5,0.5L43.1,132.1z M42.9,132.3
            l-0.6,0.4l-0.5-0.5l0.6-0.4L42.9,132.3z M42.1,132.8l-0.5,0.4l-0.5-0.5l0.5-0.4L42.1,132.8z M41.7,132l-0.4-0.5l0.6-0.4l0.4,0.5
            L41.7,132z M41.5,132.2l-0.5,0.4l-0.4-0.5l0.5-0.4L41.5,132.2z M40.9,132.6l-0.6,0.4l-0.4-0.5l0.6-0.4L40.9,132.6z M39.7,132.4
            l-0.4-0.4l0.6-0.4l0.4,0.4L39.7,132.4z M39.5,132.6l-0.5,0.4l-0.4-0.4l0.5-0.3L39.5,132.6z M38.5,132.3l-0.5-0.5l0.5-0.3L39,132
            L38.5,132.3z M38.3,132.5l-0.6,0.4l-0.5-0.5l0.6-0.4L38.3,132.5z M37.6,133l-0.5,0.4l-0.5-0.5l0.5-0.4L37.6,133z M37.1,132.2
            l-0.4-0.4l0.6-0.4l0.4,0.4L37.1,132.2z M36.9,132.3l-0.5,0.4l-0.4-0.4l0.5-0.4L36.9,132.3z M36.2,132.8l-0.5,0.4l-0.4-0.4l0.5-0.4
            L36.2,132.8z M35.9,132.1l-0.5-0.5l0.5-0.4l0.5,0.5L35.9,132.1z M35.7,132.2l-0.5,0.4l-0.5-0.5l0.5-0.4L35.7,132.2z M35,132.7
            l-0.5,0.3l-0.5-0.5l0.5-0.3L35,132.7z M34.6,132l-0.5-0.5l0.5-0.4l0.5,0.5L34.6,132z M34.4,132.1l-0.5,0.3l-0.5-0.5l0.5-0.3
            L34.4,132.1z M33.8,132.5l-0.6,0.4l-0.5-0.5l0.6-0.4L33.8,132.5z M33.3,131.8l-0.5-0.5l0.5-0.3l0.5,0.5L33.3,131.8z M33.2,131.9
            l-0.6,0.4l-0.5-0.5l0.6-0.4L33.2,131.9z M32.4,132.4l-0.5,0.3l-0.5-0.5l0.5-0.3L32.4,132.4z M31.3,132.1l-0.4-0.4l0.5-0.3l0.4,0.4
            L31.3,132.1z M31.2,132.2l-0.5,0.4l-0.4-0.4l0.5-0.4L31.2,132.2z M30,132l-0.5-0.6l0.5-0.4l0.5,0.6L30,132z M29.8,132.1l-0.5,0.4
            l-0.5-0.6l0.6-0.4L29.8,132.1z M28.6,131.8l-0.4-0.5l0.5-0.4l0.4,0.4L28.6,131.8z M28.5,131.9l-0.6,0.4l-0.4-0.4l0.6-0.4
            L28.5,131.9z M27.7,132.4l-0.6,0.4l-0.4-0.5l0.6-0.4L27.7,132.4z M26.6,132.2l-0.5-0.5l0.6-0.4l0.5,0.5L26.6,132.2z M26.4,132.3
            l-0.5,0.4l-0.5-0.5l0.5-0.4L26.4,132.3z M25.3,132l-0.5-0.5l0.5-0.4l0.5,0.5L25.3,132z M25.1,132.1l-0.5,0.4l-0.5-0.5l0.5-0.4
            L25.1,132.1z M24.5,132.6l-0.7,0.5l-0.5-0.5l0.7-0.5L24.5,132.6z M24.6,132.7l0.5,0.5l-0.7,0.5l-0.5-0.5L24.6,132.7z M24.8,132.6
            l0.5-0.4l0.5,0.5l-0.5,0.4L24.8,132.6z M25.9,132.9l0.4,0.4l-0.5,0.4l-0.4-0.4L25.9,132.9z M26.1,132.8l0.5-0.3l0.4,0.4l-0.5,0.4
            L26.1,132.8z M27.1,133.1l0.5,0.6l-0.5,0.4l-0.5-0.6L27.1,133.1z M27.3,133l0.6-0.4l0.5,0.6l-0.6,0.4L27.3,133z M28,132.5l0.6-0.4
            l0.5,0.6l-0.6,0.4L28,132.5z M29.3,132.8l0.4,0.4l-0.6,0.4l-0.4-0.4L29.3,132.8z M29.4,132.7l0.5-0.4l0.4,0.4l-0.5,0.4L29.4,132.7z
            M30.6,132.9l0.5,0.5l-0.5,0.4l-0.5-0.5L30.6,132.9z M30.7,132.8l0.5-0.4l0.5,0.5l-0.5,0.4L30.7,132.8z M31.9,133.1l0.5,0.5
            l-0.5,0.4l-0.5-0.5L31.9,133.1z M32,132.9l0.5-0.3l0.5,0.5l-0.5,0.3L32,132.9z M33.1,133.2l0.5,0.5l-0.5,0.3l-0.5-0.5L33.1,133.2z
            M33.3,133.1l0.6-0.4l0.5,0.5l-0.6,0.4L33.3,133.1z M34.5,133.3l0.4,0.4l-0.6,0.4l-0.4-0.4L34.5,133.3z M34.6,133.2l0.5-0.3
            l0.4,0.4l-0.5,0.3L34.6,133.2z M35.6,133.5l0.5,0.5l-0.5,0.3l-0.5-0.5L35.6,133.5z M35.8,133.4l0.5-0.4l0.5,0.5l-0.5,0.4
            L35.8,133.4z M37,133.6l0.4,0.4l-0.5,0.4l-0.4-0.4L37,133.6z M37.1,133.5l0.5-0.4l0.4,0.4l-0.5,0.4L37.1,133.5z M37.8,133.1
            l0.6-0.4l0.4,0.4l-0.6,0.4L37.8,133.1z M39,133.2l0.4,0.5l-0.6,0.4l-0.4-0.5L39,133.2z M39.1,133.1l0.5-0.4l0.4,0.5l-0.5,0.4
            L39.1,133.1z M40.2,133.4l0.5,0.5l-0.5,0.4l-0.5-0.5L40.2,133.4z M40.4,133.2l0.6-0.4l0.5,0.5l-0.6,0.4L40.4,133.2z M41.6,133.5
            l0.5,0.5l-0.6,0.4l-0.5-0.5L41.6,133.5z M41.8,133.3l0.5-0.4l0.5,0.5l-0.5,0.4L41.8,133.3z M42.9,133.6l0.5,0.5l-0.5,0.4l-0.5-0.5
            L42.9,133.6z M43.1,133.5l0.6-0.4l0.5,0.5l-0.6,0.4L43.1,133.5z M44.3,133.7l0.4,0.4l-0.6,0.4l-0.4-0.4L44.3,133.7z M44.4,133.6
            l0.5-0.4l0.4,0.4l-0.5,0.4L44.4,133.6z M45.2,133.1l0.6-0.4l0.4,0.4l-0.6,0.4L45.2,133.1z M46,132.6l0.5-0.3l0.4,0.4l-0.5,0.3
            L46,132.6z M46,130.4l0.5,0.5l-0.6,0.4l-0.5-0.5L46,130.4z M45.7,131.5l-0.5,0.3l-0.5-0.5l0.5-0.3L45.7,131.5z M44.6,130.3l0.5,0.5
            l-0.5,0.3l-0.5-0.5L44.6,130.3z M44.4,131.2l-0.6,0.4l-0.5-0.5l0.6-0.4L44.4,131.2z M43.4,130.1l0.4,0.5l-0.6,0.4l-0.4-0.5
            L43.4,130.1z M43.1,131.1l-0.6,0.4l-0.4-0.5l0.6-0.4L43.1,131.1z M42.1,130.1l0.4,0.4l-0.6,0.4l-0.4-0.4L42.1,130.1z M41.3,130.7
            l0.4,0.4l-0.6,0.4l-0.4-0.4L41.3,130.7z M40.9,131.7l-0.5,0.4l-0.4-0.4l0.5-0.4L40.9,131.7z M39.9,130.6l0.5,0.5l-0.5,0.4l-0.5-0.5
            L39.9,130.6z M39.7,131.6l-0.6,0.4l-0.5-0.5l0.6-0.4L39.7,131.6z M38.7,130.5l0.4,0.4l-0.6,0.4l-0.4-0.4L38.7,130.5z M38.3,131.5
            l-0.5,0.4l-0.4-0.4l0.5-0.4L38.3,131.5z M37.3,130.5l0.5,0.5l-0.5,0.4l-0.5-0.5L37.3,130.5z M37.1,131.4l-0.6,0.4l-0.5-0.5l0.6-0.4
            L37.1,131.4z M36.1,130.3l0.5,0.5L36,131l-0.5-0.5L36.1,130.3z M35.8,131.3l-0.5,0.4l-0.5-0.5l0.5-0.4L35.8,131.3z M34.7,130
            l0.5,0.5l-0.5,0.4l-0.5-0.5L34.7,130z M34.5,131l-0.5,0.4l-0.5-0.5l0.5-0.4L34.5,131z M33.5,129.9l0.4,0.4l-0.5,0.4l-0.4-0.4
            L33.5,129.9z M32.8,130.4l0.4,0.4l-0.5,0.3l-0.4-0.4L32.8,130.4z M32.5,131.3l-0.6,0.4l-0.4-0.4l0.6-0.4L32.5,131.3z M31.5,130.1
            l0.5,0.6l-0.6,0.4l-0.5-0.6L31.5,130.1z M31.2,131.2l-0.5,0.3l-0.5-0.6l0.5-0.3L31.2,131.2z M30.1,130l0.4,0.5l-0.5,0.3l-0.4-0.5
            L30.1,130z M29.9,130.9l-0.5,0.4l-0.4-0.5l0.5-0.4L29.9,130.9z M28.8,129.8l0.5,0.5l-0.5,0.4l-0.5-0.5L28.8,129.8z M28.1,130.2
            l0.5,0.5l-0.5,0.4l-0.5-0.5L28.1,130.2z M27.4,130.7l0.5,0.5l-0.6,0.4l-0.5-0.5L27.4,130.7z M26.8,130.1l0.5,0.5l-0.6,0.4l-0.5-0.5
            L26.8,130.1z M26.1,130.6l0.5,0.5l-0.6,0.4l-0.5-0.5L26.1,130.6z M25.4,129.9l0.5,0.6l-0.6,0.4l-0.5-0.6L25.4,129.9z M24.7,130.4
            l0.5,0.6l-0.5,0.4l-0.5-0.6L24.7,130.4z M24,130.9l0.5,0.6l-0.5,0.4l-0.5-0.6L24,130.9z M22.7,131.9l0.7-0.5l0.5,0.6l-0.7,0.5
            L22.7,131.9z M23.2,132.7l0.5,0.5l-0.6,0.4l-0.4-0.5L23.2,132.7z M21.4,134.7l-0.4-0.4l0.7-0.5l0.4,0.5L21.4,134.7z M22.2,134.1
            l-0.4-0.5l0.6-0.4l0.4,0.5L22.2,134.1z M22.9,134.8l-0.5-0.5l0.6-0.4l0.5,0.5L22.9,134.8z M23.2,133.7l0.6-0.4l0.5,0.5l-0.6,0.4
            L23.2,133.7z M24.2,134.8l-0.4-0.4l0.6-0.4l0.4,0.5L24.2,134.8z M24.5,133.8l0.7-0.5l0.4,0.5l-0.7,0.5L24.5,133.8z M25.6,135
            l-0.5-0.6l0.7-0.5l0.5,0.6L25.6,135z M26,133.9l0.5-0.4l0.5,0.6l-0.5,0.4L26,133.9z M27,135l-0.4-0.4l0.5-0.4l0.4,0.4L27,135z
            M27.7,134.5l-0.4-0.4l0.5-0.4l0.4,0.4L27.7,134.5z M27.9,133.6l0.6-0.4l0.4,0.4l-0.6,0.4L27.9,133.6z M29,134.7l-0.5-0.5l0.6-0.4
            l0.5,0.5L29,134.7z M29.7,134.2l-0.5-0.5l0.6-0.4l0.5,0.5L29.7,134.2z M30.3,134.9l-0.5-0.5l0.6-0.4l0.5,0.5L30.3,134.9z
            M30.6,133.9l0.5-0.4l0.5,0.5l-0.5,0.4L30.6,133.9z M31.6,135l-0.5-0.5l0.5-0.4l0.5,0.5L31.6,135z M31.9,134l0.5-0.4l0.5,0.5
            l-0.5,0.4L31.9,134z M32.9,135l-0.4-0.4l0.5-0.4l0.4,0.4L32.9,135z M33.2,134.1l0.5-0.3l0.4,0.4l-0.5,0.3L33.2,134.1z M34.2,135.2
            l-0.5-0.5l0.5-0.3l0.5,0.5L34.2,135.2z M34.3,134.2l0.6-0.4l0.5,0.5l-0.6,0.4L34.3,134.2z M35.3,135.2L35,135l0.6-0.4L36,135
            L35.3,135.2z M35.7,134.3l0.5-0.3l0.4,0.4l-0.5,0.3L35.7,134.3z M36.7,135.3l-0.4-0.5l0.5-0.3l0.4,0.5L36.7,135.3z M37.3,134.9
            l-0.4-0.5l0.5-0.4l0.4,0.5L37.3,134.9z M37.6,133.9l0.5-0.4l0.4,0.4l-0.5,0.4L37.6,133.9z M38.6,135l-0.5-0.5l0.5-0.4l0.5,0.5
            L38.6,135z M38.9,134l0.6-0.4l0.5,0.5l-0.6,0.4L38.9,134z M40,135.3l-0.5-0.5l0.6-0.4l0.5,0.5L40,135.3z M40.3,134.3l0.5-0.3
            l0.5,0.5l-0.5,0.4L40.3,134.3z M41.3,135.5l-0.5-0.5l0.5-0.4l0.5,0.5L41.3,135.5z M41.5,134.5l0.6-0.4l0.5,0.5L42,135L41.5,134.5z
            M42.5,135.6l-0.4-0.4l0.6-0.4l0.4,0.4L42.5,135.6z M42.9,134.6l0.5-0.4l0.4,0.4l-0.5,0.4L42.9,134.6z M43.9,135.6l-0.4-0.5
            l0.5-0.4l0.4,0.4L43.9,135.6z M44.6,135.2l-0.4-0.4l0.6-0.4l0.4,0.5L44.6,135.2z M45.4,134.6l-0.4-0.4l0.5-0.4l0.4,0.5L45.4,134.6z
            M46.1,134.1l-0.4-0.4l0.6-0.4l0.4,0.5L46.1,134.1z M47.3,133.3l-0.5,0.3l-0.4-0.5l0.5-0.3L47.3,133.3z M46.9,132.6l-0.4-0.4
            l0.6-0.4l0.4,0.4L46.9,132.6z M47.8,132l-0.4-0.4l0.8-0.5l0.4,0.4L47.8,132z M48.2,130.9l0.6-0.4l0.4,0.4l-0.6,0.4L48.2,130.9z" />
              <line class="st1-print" x1="479.5" y1="148.5" x2="479.4" y2="146.3" />
              <line class="st1-print" x1="480.8" y1="146.7" x2="480.8" y2="144.6" />
              <line class="st1-print" x1="482.2" y1="144.9" x2="482.1" y2="142.8" />
              <line class="st1-print" x1="483.3" y1="143.4" x2="483.2" y2="141.3" />
              <path class="st2-print" d="M484,140.5c0.2,0,0.4,0.1,0.5,0.2c0.4,0.4,0.5,1,0.1,1.5l-5.1,6.7c-0.2,0.2-0.4,0.4-0.7,0.4
            c-0.3,0-0.5-0.1-0.7-0.2c-0.4-0.4-0.5-1-0.1-1.5l5.1-6.7C483.3,140.6,483.6,140.5,484,140.5z M478.7,149h0.1c0.2,0,0.3-0.1,0.4-0.2
            l5.1-6.8c0.2-0.3,0.2-0.7-0.1-1c-0.3-0.2-0.6-0.2-0.9,0.1l0,0l-5.1,6.7c-0.2,0.3-0.2,0.7,0,0.9C478.5,148.9,478.6,148.9,478.7,149
            L478.7,149z" />
              <path class="st3-print" d="M457.1,140.6l25.7,0.7l-4.5,5.7l-24.8-0.7c-0.3,0-0.6-0.3-0.6-0.6c0,0,0,0,0,0c0-0.2,0-0.3,0.1-0.4l3.5-4.5
            C456.8,140.7,456.9,140.6,457.1,140.6z" />
              <path class="st4-print"
                d="M453.2,145.4c0,0-0.1,0.1-0.1,0.2l0.4,0.4l-0.2,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1-0.1l0.1,0.1h0.3l-0.2-0.2
            l0.6-0.4l0.5,0.5l-0.1,0.1h0.5l-0.1-0.1l0.7-0.5l0.5,0.5l-0.2,0.1h0.5l-0.1-0.1l0.6-0.4l0.5,0.5l-0.1,0.1h0.4l0.5-0.3l0.3,0.4h0.5
            l0.5-0.3l0.3,0.4h0.5l0.5-0.3l0.3,0.3h0.3l-0.5-0.5l0.6-0.4l0.5,0.5l-0.5,0.4h0.3l0.3-0.2l0.2,0.2h0.3l-0.4-0.4l0.5-0.4l0.4,0.4
            l-0.5,0.3h0.3l0.3-0.2l0.2,0.2h0.3l-0.3-0.3l0.6-0.4l0.5,0.5l-0.4,0.3h0.3l0.2-0.1l0.1,0.1h0.3l-0.2-0.3l0.5-0.4l0.5,0.5l-0.2,0.1
            h0.6l-0.1-0.1l0.6-0.4l0.5,0.5h0.3l0.5-0.4l0.4,0.4h0.4l0.5-0.3l0.3,0.4h0.3l-0.4-0.5l0.5-0.3l0.5,0.6l-0.4,0.2h0.4l0.2-0.1
            l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.4,0.4l-0.3,0.2h0.3l0.1-0.1l0.1,0.1h0.3l-0.2-0.2l0.5-0.3l0.5,0.5h0.3l0.5-0.3l0.4,0.4h0.2
            l-0.5-0.5l0.5-0.4l0.5,0.5l-0.5,0.4h0.3l0.3-0.2l0.2,0.2h0.3l-0.3-0.4l0.6-0.4l0.5,0.6l-0.3,0.2h0.3l0.1-0.1l0.1,0.1h0.3l-0.2-0.2
            l0.6-0.4l0.4,0.4l-0.3,0.2h0.3l0.1-0.1l0,0l0.4-0.6l-0.4,0.3l-0.4-0.4l0.5-0.4l0.4,0.4h0.1l-0.3-0.3l0.5-0.4l0.2,0.2l0.1-0.2
            l-0.2-0.2l0.6-0.4l0.4-0.5l-0.4,0.3l-0.5-0.6l0.6-0.4l0.5,0.5l0.1-0.2l-0.4-0.5l0.6-0.4l0.3,0.3l0.1-0.2l-0.2-0.3l0.7-0.5l0.1,0.1
            l0.1-0.1h0l0.2-0.1l0.4-0.5l-0.7,0.5l-0.4-0.4l0.4-0.3h-0.3l-0.2,0.1l-0.1-0.2h-0.3l0.3,0.3l-0.7,0.5l-0.5-0.5l0.4-0.2h-0.3
            l-0.2,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.6,0.4l-0.4-0.4l0.4-0.2h-0.3l-0.2,0.1l-0.1-0.1H478l0.2,0.2l-0.6,0.4l-0.5-0.6l0.1-0.1h-0.5
            l0.1,0.1l-0.7,0.5l-0.4-0.4l0.2-0.1h-0.6l0.1,0.1l-0.5,0.4l-0.5-0.5l0,0l-0.1,0.1l0.5,0.5l-0.5,0.4l-0.5-0.5l0.6-0.4H474l-0.4,0.3
            l-0.3-0.3h-0.2l-0.1,0.1l0.4,0.4l-0.6,0.4l-0.5-0.5l0.5-0.4h-0.3l-0.3,0.2l-0.2-0.2h-0.3l0.3,0.4l-0.6,0.4l-0.5-0.5l0.4-0.3h-0.3
            l-0.2,0.1l-0.1-0.1h-0.3l0.2,0.3l-0.5,0.4l-0.4-0.4l0.4-0.2h-0.3l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.5,0.4l-0.5-0.5l0.1-0.1h-0.5
            l0.1,0.1l-0.5,0.3l-0.4-0.4H467l-0.6,0.4l-0.4-0.4h-0.5l-0.4,0.2L465,141h-0.3l0.4,0.4l-0.5,0.4l-0.5-0.5l0.4-0.3h-0.3l-0.2,0.1
            l-0.1-0.2h-0.3l0.3,0.3l-0.5,0.4l-0.5-0.5l0.2-0.1h-0.6l0.1,0.1l-0.6,0.4l-0.5-0.5l0.1-0.1H461v0l-0.5,0.4l-0.4-0.4h-0.4l-0.6,0.4
            l-0.4-0.4h-0.4l-0.4,0.3l-0.3-0.3h-0.3l0.4,0.4l-0.6,0.4l-0.5-0.5l-0.1,0.2l0.5,0.5l-0.5,0.4l-0.3-0.4L456,142l0.3,0.3l-0.6,0.4
            l-0.1-0.1l-0.1,0.2l0.1,0.1l-0.3,0.2l-0.4,0.5l0.3-0.2l0.4,0.4l-0.6,0.4l-0.3-0.4l-0.1,0.2l0.3,0.3l-0.7,0.5l-0.1-0.1l-0.1,0.2
            l0,0.1l-0.2,0.1L453.2,145.4z M455,146l-0.5-0.5l0.8-0.5l0.5,0.5L455,146z M455.4,144.9l0.6-0.4l0.5,0.5l-0.6,0.4L455.4,144.9z
            M456.9,143.9l0.6-0.4l0.5,0.5l-0.6,0.4L456.9,143.9z M457.2,144.5l-0.5,0.3l-0.5-0.5l0.5-0.3L457.2,144.5z M457.7,143.3l0.5-0.4
            l0.5,0.5l-0.6,0.4L457.7,143.3z M458.1,144.1l0.5,0.5l-0.6,0.4l-0.5-0.5L458.1,144.1z M458.3,144l0.6-0.4l0.5,0.5l-0.6,0.4
            L458.3,144z M459,143.5l0.6-0.4l0.5,0.5l-0.6,0.4L459,143.5z M459.5,144.2l0.5,0.5l-0.6,0.4l-0.5-0.5L459.5,144.2z M459.6,144.1
            l0.6-0.4l0.5,0.5l-0.6,0.4L459.6,144.1z M460.4,143.6l0.5-0.4l0.5,0.5l-0.5,0.4L460.4,143.6z M460.8,144.3l0.4,0.5l-0.6,0.4
            l-0.4-0.5L460.8,144.3z M461,144.2l0.5-0.4l0.4,0.5l-0.5,0.4L461,144.2z M461.7,143.8l0.6-0.4l0.4,0.5l-0.6,0.4L461.7,143.8z
            M462.9,143.9l0.4,0.4l-0.6,0.4l-0.4-0.4L462.9,143.9z M463.1,143.8l0.5-0.3l0.4,0.4l-0.5,0.4L463.1,143.8z M464.1,144l0.5,0.5
            l-0.5,0.4l-0.5-0.5L464.1,144z M464.2,143.9l0.6-0.4l0.5,0.5l-0.6,0.4L464.2,143.9z M465,143.4l0.5-0.4l0.5,0.5l-0.5,0.4L465,143.4
            z M465.4,144.2l0.4,0.4l-0.6,0.4l-0.4-0.4L465.4,144.2z M465.6,144.1l0.5-0.4l0.4,0.4l-0.5,0.4L465.6,144.1z M466.3,143.6l0.5-0.4
            l0.4,0.4l-0.5,0.4L466.3,143.6z M466.7,144.3l0.5,0.5l-0.5,0.4l-0.5-0.5L466.7,144.3z M466.8,144.2l0.5-0.4l0.5,0.5l-0.5,0.4
            L466.8,144.2z M467.6,143.7l0.5-0.3l0.5,0.5l-0.5,0.3L467.6,143.7z M468,144.4l0.5,0.5l-0.5,0.4l-0.5-0.5L468,144.4z M468.2,144.3
            l0.5-0.3l0.5,0.5l-0.5,0.3L468.2,144.3z M468.8,143.9l0.6-0.4l0.5,0.5l-0.6,0.4L468.8,143.9z M469.2,144.6l0.5,0.5l-0.5,0.3
            l-0.5-0.5L469.2,144.6z M469.4,144.5l0.6-0.4l0.5,0.5l-0.6,0.4L469.4,144.5z M470.1,144l0.5-0.3l0.5,0.5l-0.5,0.3L470.1,144z
            M471.2,144.3l0.4,0.4l-0.5,0.3l-0.4-0.4L471.2,144.3z M471.4,144.2l0.5-0.4l0.4,0.4l-0.5,0.4L471.4,144.2z M472.5,144.4l0.5,0.6
            l-0.5,0.4l-0.5-0.6L472.5,144.4z M472.7,144.3l0.5-0.4l0.5,0.6l-0.5,0.4L472.7,144.3z M473.9,144.6l0.4,0.4l-0.6,0.4l-0.4-0.5
            L473.9,144.6z M474,144.5l0.6-0.4l0.4,0.4l-0.6,0.4L474,144.5z M474.8,144l0.6-0.4l0.4,0.4l-0.6,0.4L474.8,144z M475.9,144.2
            l0.5,0.5l-0.6,0.4l-0.5-0.5L475.9,144.2z M476.1,144.1l0.5-0.4l0.5,0.5l-0.5,0.4L476.1,144.1z M477.2,144.4l0.5,0.5l-0.5,0.4
            l-0.5-0.5L477.2,144.4z M477.4,144.3l0.5-0.4l0.5,0.5l-0.5,0.4L477.4,144.3z M478.1,143.8l0.7-0.5l0.5,0.5l-0.7,0.5L478.1,143.8z
            M477.9,143.7l-0.5-0.5l0.7-0.5l0.5,0.5L477.9,143.7z M477.8,143.8l-0.5,0.4l-0.5-0.5l0.5-0.4L477.8,143.8z M476.6,143.5l-0.4-0.5
            l0.5-0.4l0.4,0.4L476.6,143.5z M476.4,143.6l-0.5,0.4l-0.4-0.5l0.5-0.3L476.4,143.6z M475.4,143.4l-0.5-0.6l0.5-0.4l0.5,0.6
            L475.4,143.4z M475.2,143.5l-0.6,0.4l-0.5-0.6l0.6-0.4L475.2,143.5z M474.5,144l-0.6,0.4l-0.5-0.6l0.6-0.4L474.5,144z M473.2,143.7
            l-0.4-0.4l0.6-0.4l0.4,0.4L473.2,143.7z M473.1,143.8l-0.5,0.4l-0.4-0.4l0.6-0.4L473.1,143.8z M472,143.6l-0.5-0.5l0.5-0.4l0.5,0.5
            L472,143.6z M471.8,143.7l-0.5,0.4l-0.5-0.5l0.5-0.4L471.8,143.7z M470.6,143.4l-0.5-0.5l0.5-0.4l0.5,0.5L470.6,143.4z
            M470.5,143.5l-0.5,0.3l-0.5-0.5l0.5-0.3L470.5,143.5z M469.4,143.2l-0.5-0.5l0.5-0.3l0.5,0.5L469.4,143.2z M469.2,143.3l-0.6,0.4
            l-0.5-0.5l0.6-0.4L469.2,143.3z M468.1,143.1l-0.4-0.4l0.6-0.4l0.4,0.4L468.1,143.1z M467.9,143.2l-0.5,0.3l-0.4-0.4l0.5-0.3
            L467.9,143.2z M466.9,143l-0.5-0.5l0.5-0.3l0.5,0.5L466.9,143z M466.7,143.1l-0.5,0.4l-0.5-0.5l0.5-0.4L466.7,143.1z M465.5,142.8
            l-0.4-0.4l0.5-0.4l0.4,0.4L465.5,142.8z M465.4,142.9l-0.5,0.4l-0.4-0.4l0.5-0.4L465.4,142.9z M464.7,143.4l-0.6,0.4l-0.4-0.4
            l0.6-0.4L464.7,143.4z M463.5,143.2l-0.4-0.5l0.6-0.4l0.4,0.5L463.5,143.2z M463.4,143.3l-0.5,0.4l-0.4-0.5l0.5-0.4L463.4,143.3z
            M462.3,143.1l-0.5-0.5l0.5-0.4l0.5,0.5L462.3,143.1z M462.1,143.2l-0.6,0.4l-0.5-0.5l0.6-0.4L462.1,143.2z M461,143l-0.5-0.5
            l0.6-0.4l0.5,0.5L461,143z M460.8,143.1l-0.5,0.4l-0.5-0.5l0.5-0.4L460.8,143.1z M459.7,142.8l-0.5-0.5l0.5-0.4l0.5,0.5
            L459.7,142.8z M459.5,142.9l-0.6,0.4l-0.5-0.5l0.6-0.4L459.5,142.9z M458.3,142.7l-0.4-0.4l0.6-0.4l0.4,0.4L458.3,142.7z
            M458.1,142.8l-0.6,0.4l-0.4-0.4l0.5-0.4L458.1,142.8z M457.4,143.3l-0.6,0.4l-0.4-0.4l0.6-0.4L457.4,143.3z M456.6,143.9l-0.5,0.3
            l-0.4-0.4l0.5-0.3L456.6,143.9z M456.6,146l-0.5-0.5l0.6-0.4l0.5,0.5L456.6,146z M456.9,145l0.5-0.3l0.5,0.5l-0.5,0.3L456.9,145z
            M457.9,146.1l-0.5-0.5l0.5-0.3l0.5,0.5L457.9,146.1z M458.1,145.2l0.6-0.4l0.5,0.5l-0.6,0.4L458.1,145.2z M459.1,146.3l-0.4-0.5
            l0.6-0.4l0.4,0.5L459.1,146.3z M459.5,145.3l0.6-0.4l0.4,0.4l-0.6,0.4L459.5,145.3z M460.5,146.3l-0.4-0.4l0.6-0.4l0.4,0.4
            L460.5,146.3z M461.2,145.8l-0.4-0.4l0.6-0.4l0.4,0.4L461.2,145.8z M461.6,144.8l0.5-0.4l0.4,0.4l-0.5,0.4L461.6,144.8z
            M462.6,145.9l-0.5-0.5l0.5-0.4l0.5,0.5L462.6,145.9z M462.8,144.9l0.6-0.4l0.5,0.5l-0.6,0.4L462.8,144.9z M463.8,146l-0.4-0.4
            l0.6-0.4l0.4,0.4L463.8,146z M464.2,145l0.5-0.4l0.4,0.4l-0.5,0.4L464.2,145z M465.2,146l-0.5-0.5l0.5-0.4l0.5,0.5L465.2,146z
            M465.4,145l0.6-0.4l0.5,0.5l-0.6,0.4L465.4,145z M466.4,146.1l-0.5-0.5l0.6-0.4l0.5,0.5L466.4,146.1z M466.7,145.1l0.5-0.4
            l0.5,0.5l-0.5,0.4L466.7,145.1z M467.8,146.3l-0.5-0.5l0.5-0.4l0.5,0.5L467.8,146.3z M468,145.3l0.5-0.4l0.5,0.5l-0.5,0.4
            L468,145.3z M469,146.4l-0.4-0.4l0.5-0.4l0.4,0.4L469,146.4z M469.7,145.9l-0.4-0.4l0.5-0.3l0.4,0.4L469.7,145.9z M470,145l0.6-0.4
            l0.4,0.4l-0.6,0.4L470,145z M471.1,146.1l-0.5-0.6l0.6-0.4l0.5,0.6L471.1,146.1z M471.3,145.1l0.5-0.3l0.5,0.6l-0.5,0.3
            L471.3,145.1z M472.4,146.2L472,146l0.5-0.3l0.4,0.4L472.4,146.2z M472.6,145.3l0.5-0.4l0.4,0.4l-0.5,0.4L472.6,145.3z
            M473.7,146.5l-0.5-0.5l0.5-0.4l0.5,0.5L473.7,146.5z M474.4,146l-0.5-0.5l0.5-0.4l0.5,0.5L474.4,146z M475.1,145.5l-0.5-0.5
            l0.6-0.4l0.5,0.5L475.1,145.5z M475.7,146.2l-0.5-0.5l0.6-0.4l0.5,0.5L475.7,146.2z M476.5,145.6l-0.5-0.5l0.6-0.4l0.5,0.5
            L476.5,145.6z M477.1,146.3l-0.5-0.6l0.6-0.4l0.5,0.6L477.1,146.3z M477.9,145.8l-0.5-0.6l0.5-0.4l0.5,0.6L477.9,145.8z
            M478.5,145.4L478,145l0.5-0.4l0.5,0.6L478.5,145.4z M479.9,144.4l-0.7,0.5l-0.5-0.6l0.7-0.5L479.9,144.4z M479.4,143.5l-0.5-0.5
            l0.6-0.4l0.5,0.5L479.4,143.5z M481.1,141.5l0.4,0.4l-0.7,0.5l-0.4-0.4L481.1,141.5z M480.3,142.1l0.4,0.5L480,143l-0.4-0.5
            L480.3,142.1z M479.7,141.5l0.5,0.5l-0.6,0.4l-0.5-0.5L479.7,141.5z M479.3,142.5l-0.6,0.4l-0.5-0.5l0.6-0.4L479.3,142.5z
            M478.3,141.4l0.4,0.4l-0.6,0.4l-0.4-0.4L478.3,141.4z M478,142.4l-0.7,0.5l-0.4-0.4l0.7-0.5L478,142.4z M476.9,141.3l0.5,0.6
            l-0.7,0.5l-0.5-0.6L476.9,141.3z M476.6,142.4l-0.5,0.4l-0.5-0.6l0.5-0.4L476.6,142.4z M475.5,141.3l0.4,0.4l-0.5,0.4l-0.4-0.4
            L475.5,141.3z M474.8,141.7l0.4,0.4l-0.5,0.3l-0.4-0.4L474.8,141.7z M474.6,142.6L474,143l-0.4-0.4l0.6-0.4L474.6,142.6z
            M473.6,141.5l0.5,0.5l-0.6,0.4l-0.5-0.5L473.6,141.5z M472.8,142l0.5,0.5l-0.6,0.4l-0.5-0.5L472.8,142z M472.2,141.4l0.5,0.5
            l-0.6,0.4l-0.5-0.5L472.2,141.4z M471.9,142.4l-0.6,0.4l-0.5-0.5l0.5-0.4L471.9,142.4z M470.9,141.3l0.5,0.5l-0.5,0.4l-0.5-0.5
            L470.9,141.3z M470.6,142.3l-0.5,0.4l-0.5-0.5l0.5-0.4L470.6,142.3z M469.6,141.3l0.4,0.4l-0.5,0.4l-0.4-0.4L469.6,141.3z
            M469.3,142.2l-0.5,0.3l-0.4-0.4l0.5-0.3L469.3,142.2z M468.3,141.1l0.5,0.5l-0.5,0.3l-0.5-0.5L468.3,141.1z M468.2,142.1l-0.6,0.4
            l-0.5-0.5l0.6-0.4L468.2,142.1z M467.2,141l0.4,0.4l-0.6,0.4l-0.4-0.4L467.2,141z M466.8,142l-0.5,0.3l-0.4-0.4l0.5-0.3L466.8,142z
            M465.8,141l0.4,0.5l-0.5,0.3l-0.4-0.5L465.8,141z M465.2,141.4l0.4,0.5l-0.5,0.4l-0.4-0.5L465.2,141.4z M464.9,142.3l-0.5,0.4
            l-0.4-0.5l0.5-0.4L464.9,142.3z M463.9,141.3l0.5,0.5l-0.5,0.4l-0.5-0.5L463.9,141.3z M463.7,142.3l-0.6,0.4l-0.5-0.5l0.6-0.4
            L463.7,142.3z M462.6,141.1l0.5,0.5l-0.6,0.4l-0.5-0.5L462.6,141.1z M462.3,142.1l-0.5,0.4l-0.5-0.5l0.5-0.4L462.3,142.1z
            M461.2,141l0.5,0.5l-0.5,0.4l-0.5-0.5L461.2,141z M461,142l-0.6,0.4l-0.5-0.5l0.6-0.4L461,142z M460,140.9l0.4,0.4l-0.6,0.4
            l-0.4-0.4L460,140.9z M459.6,141.9l-0.5,0.4l-0.4-0.4l0.5-0.4L459.6,141.9z M458.6,140.9l0.4,0.4l-0.5,0.4l-0.4-0.4L458.6,140.9z
            M457.9,141.4l0.4,0.5l-0.6,0.4l-0.4-0.5L457.9,141.4z M457.1,141.9l0.4,0.4l-0.5,0.4l-0.4-0.5L457.1,141.9z M456.4,142.4l0.4,0.5
            l-0.6,0.4l-0.4-0.4L456.4,142.4z M455.2,143.2l0.5-0.3l0.4,0.5l-0.5,0.3L455.2,143.2z M455.6,144l0.4,0.4l-0.6,0.4l-0.4-0.4
            L455.6,144z M454.8,144.5l0.4,0.5l-0.7,0.5L454,145L454.8,144.5z M454.3,145.6l-0.6,0.4l-0.4-0.4l0.6-0.4L454.3,145.6z" />
              <line class="st1-print" x1="478.1" y1="197.2" x2="478" y2="195.1" />
              <line class="st1-print" x1="479.4" y1="195.4" x2="479.3" y2="193.4" />
              <line class="st1-print" x1="480.7" y1="193.7" x2="480.7" y2="191.5" />
              <line class="st1-print" x1="481.9" y1="192.1" x2="481.8" y2="190" />
              <path class="st2-print"
                d="M482.5,189.3c0.2,0,0.4,0.1,0.5,0.2c0.4,0.4,0.5,1,0.1,1.5l-5.1,6.8c-0.3,0.4-1,0.5-1.4,0.2c0,0,0,0,0,0
            c-0.4-0.4-0.5-1-0.1-1.5l5.1-6.8C481.8,189.4,482.2,189.2,482.5,189.3z M477.2,197.8h0.1c0.2,0,0.3-0.1,0.4-0.2l5.1-6.8
            c0.3-0.3,0.2-0.7-0.1-1c-0.2-0.2-0.6-0.2-0.8,0.1l0,0l-5.1,6.7c-0.2,0.3-0.2,0.7,0.1,1C477,197.7,477.1,197.7,477.2,197.8z" />
              <path class="st3-print" d="M455.7,189.4l25.7,0.7l-4.5,5.7l-24.8-0.7c-0.3,0-0.6-0.3-0.6-0.6c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4l3.5-4.5
            C455.3,189.4,455.5,189.3,455.7,189.4z" />
              <path class="st4-print" d="M451.7,194.2l-0.1,0.2l0.4,0.4l-0.2,0.1c0.1,0,0.2,0.1,0.2,0.1l0.1-0.1l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.5,0.5
            l-0.1,0.1h0.5l-0.1-0.1l0.7-0.5l0.5,0.5l-0.1,0.1h0.5l-0.1-0.1l0.6-0.4l0.5,0.5l-0.1,0.1h0.4l0.5-0.3l0.4,0.4h0.5l0.5-0.3l0.3,0.4
            h0.5l0.5-0.3l0.3,0.4h0.3l-0.4-0.5l0.6-0.4l0.5,0.5l-0.5,0.4h0.3l0.3-0.2l0.2,0.2h0.3l-0.4-0.4l0.5-0.4l0.4,0.4l-0.5,0.3h0.3
            l0.2-0.2l0.2,0.2h0.3l-0.3-0.3l0.6-0.4l0.5,0.5l-0.4,0.3h0.3l0.2-0.1l0.1,0.1h0.3L464,195l0.5-0.4l0.5,0.5l-0.2,0.1h0.6l-0.1-0.1
            l0.6-0.4l0.5,0.5h0.3l0.5-0.4l0.4,0.4h0.4l0.5-0.3l0.3,0.4h0.2l-0.4-0.5l0.5-0.3l0.5,0.6l-0.4,0.2h0.3l0.2-0.1l0.1,0.1h0.3
            l-0.2-0.2l0.6-0.4l0.4,0.4l-0.3,0.2h0.3l0.1-0.1l0.1,0.1h0.3l-0.2-0.2l0.5-0.3l0.5,0.5h0.3l0.5-0.3l0.4,0.4h0.3l-0.5-0.5l0.5-0.4
            l0.5,0.5l-0.5,0.4h0.3l0.3-0.2l0.2,0.2h0.3l-0.3-0.4l0.6-0.4l0.5,0.6l-0.3,0.2h0.3l0.1-0.1l0.1,0.1h0.3l-0.2-0.2l0.6-0.4l0.4,0.4
            l-0.3,0.2h0.3l0.1-0.1l0,0l0.4-0.6l-0.4,0.3l-0.4-0.4l0.5-0.4l0.4,0.4l0.1-0.2l-0.3-0.3l0.5-0.4l0.2,0.2l0.1-0.2l-0.1-0.2l0.6-0.5
            l0.4-0.3l-0.4,0.3l-0.5-0.6l0.6-0.4l0.5,0.5l0.1-0.1l-0.5-0.5l0.6-0.4l0.3,0.3l0.1-0.2l-0.2-0.3l0.7-0.5l0.1,0.1l0.1-0.2l0,0
            l0.2-0.1l0.4-0.5l-0.8,0.5l-0.4-0.4l0.4-0.3H480l-0.2,0.1l-0.1-0.1h-0.3l0.3,0.3l-0.7,0.5l-0.5-0.5l0.4-0.2h-0.3l-0.2,0.1l-0.1-0.1
            h-0.3l0.2,0.2l-0.6,0.4l-0.4-0.4l0.4-0.2h-0.3l-0.2,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.6,0.4l-0.5-0.6l0.1-0.1h-0.5l0.1,0.1l-0.7,0.5
            l-0.4-0.4l0.2-0.1h-0.6l0.1,0.1l-0.5,0.4L473,190l0,0l-0.1,0.1l0.4,0.5l-0.5,0.3l-0.5-0.5l0.6-0.4h-0.3l-0.4,0.3l-0.3-0.3h-0.2
            l-0.1,0.1l0.4,0.4l-0.6,0.4l-0.5-0.5l0.5-0.4h-0.3l-0.3,0.2l-0.2-0.2h-0.3l0.3,0.4l-0.6,0.4l-0.5-0.5l0.3-0.2h-0.3l-0.2,0.1
            l-0.1-0.1H469l0.2,0.3l-0.5,0.4l-0.4-0.4l0.4-0.2h-0.3l-0.1,0.1l-0.1-0.1h-0.3l0.2,0.2l-0.5,0.4L467,190l0.1-0.1h-0.5l0.1,0.1
            l-0.5,0.3l-0.4-0.4h-0.4l-0.5,0.4l-0.4-0.4h-0.5l-0.4,0.2l-0.2-0.3h-0.3l0.4,0.4l-0.5,0.4l-0.5-0.5l0.4-0.3h-0.3l-0.2,0.1l-0.1-0.2
            H462l0.3,0.3l-0.5,0.4l-0.5-0.5l0.2-0.2h-0.6l0.1,0.1l-0.6,0.4l-0.5-0.5l0.1-0.1h-0.5l-0.5,0.4l-0.4-0.4h-0.4l-0.6,0.4l-0.4-0.4
            h-0.4l-0.4,0.3l-0.3-0.3h-0.3l0.4,0.4l-0.6,0.4l-0.5-0.5l-0.1,0.2l0.5,0.5L455,191l-0.3-0.4l-0.1,0.2l0.3,0.3l-0.6,0.4l-0.1-0.1
            l-0.1,0.2l0.1,0.1l-0.3,0.2l-0.4,0.5l0.3-0.2l0.4,0.5l-0.6,0.4l-0.3-0.4l-0.1,0.2l0.3,0.3l-0.8,0.5l-0.1-0.1l-0.1,0.2l0.1,0.1
            L452,194L451.7,194.2z M453.6,194.8l-0.5-0.5l0.7-0.5l0.5,0.5L453.6,194.8z M454,193.7l0.6-0.4l0.5,0.5l-0.6,0.4L454,193.7z
            M455.4,192.7l0.6-0.4l0.5,0.5l-0.6,0.4L455.4,192.7z M455.7,193.3l-0.5,0.3l-0.5-0.5l0.5-0.3L455.7,193.3z M456.2,192.1l0.6-0.4
            l0.5,0.5l-0.6,0.4L456.2,192.1z M456.7,192.9l0.5,0.5l-0.6,0.4l-0.5-0.5L456.7,192.9z M456.8,192.8l0.6-0.4l0.5,0.5l-0.6,0.4
            L456.8,192.8z M457.6,192.3l0.6-0.4l0.5,0.5l-0.6,0.4L457.6,192.3z M458,193l0.5,0.5l-0.6,0.4l-0.5-0.5L458,193z M458.2,192.9
            l0.6-0.4l0.5,0.5l-0.6,0.4L458.2,192.9z M459,192.4l0.5-0.4l0.5,0.5l-0.5,0.4L459,192.4z M459.4,193.1l0.4,0.5l-0.6,0.4l-0.4-0.4
            L459.4,193.1z M459.6,193l0.5-0.4l0.4,0.5l-0.5,0.4L459.6,193z M460.2,192.5l0.6-0.4l0.4,0.5l-0.6,0.4L460.2,192.5z M461.5,192.7
            l0.4,0.4l-0.6,0.4l-0.4-0.4L461.5,192.7z M461.6,192.6l0.5-0.4l0.4,0.4L462,193L461.6,192.6z M462.7,192.8l0.5,0.5l-0.5,0.4
            l-0.5-0.5L462.7,192.8z M462.8,192.7l0.6-0.4l0.5,0.5l-0.6,0.4L462.8,192.7z M463.6,192.2l0.5-0.4l0.5,0.5l-0.5,0.4L463.6,192.2z
            M464,193l0.4,0.4l-0.6,0.4l-0.4-0.4L464,193z M464.2,192.9l0.5-0.4l0.4,0.4l-0.5,0.4L464.2,192.9z M464.9,192.4l0.5-0.4l0.4,0.4
            l-0.5,0.4L464.9,192.4z M465.3,193.1l0.5,0.5l-0.5,0.4l-0.5-0.5L465.3,193.1z M465.5,193l0.5-0.4l0.5,0.5l-0.5,0.4L465.5,193z
            M466.2,192.5l0.5-0.3l0.5,0.5l-0.5,0.3L466.2,192.5z M466.6,193.2l0.5,0.5l-0.5,0.4l-0.5-0.5L466.6,193.2z M466.8,193.1l0.5-0.3
            l0.5,0.5l-0.5,0.3L466.8,193.1z M467.4,192.7l0.6-0.4l0.5,0.5l-0.6,0.4L467.4,192.7z M467.8,193.4l0.5,0.5l-0.5,0.3l-0.5-0.5
            L467.8,193.4z M468,193.3l0.6-0.4l0.5,0.5l-0.6,0.4L468,193.3z M468.8,192.8l0.5-0.3l0.5,0.5l-0.5,0.3L468.8,192.8z M469.9,193.1
            l0.4,0.4l-0.5,0.3l-0.4-0.4L469.9,193.1z M470,193l0.5-0.4l0.4,0.4l-0.5,0.4L470,193z M471.1,193.2l0.5,0.6l-0.5,0.4l-0.5-0.6
            L471.1,193.2z M471.2,193.1l0.5-0.4l0.5,0.6l-0.5,0.4L471.2,193.1z M472.5,193.4l0.4,0.4l-0.5,0.4l-0.4-0.4L472.5,193.4z
            M472.6,193.3l0.6-0.4l0.4,0.4l-0.6,0.4L472.6,193.3z M473.4,192.8l0.6-0.4l0.4,0.4l-0.6,0.4L473.4,192.8z M474.5,193l0.5,0.5
            l-0.6,0.4l-0.5-0.5L474.5,193z M474.7,192.9l0.5-0.3l0.5,0.5l-0.5,0.3L474.7,192.9z M475.8,193.2l0.5,0.5l-0.5,0.4l-0.5-0.5
            L475.8,193.2z M476,193.1l0.5-0.4l0.5,0.5l-0.5,0.4L476,193.1z M476.7,192.7l0.7-0.5l0.5,0.5l-0.7,0.5L476.7,192.7z M476.5,192.5
            L476,192l0.7-0.5l0.5,0.5L476.5,192.5z M476.4,192.6l-0.5,0.4l-0.5-0.5l0.5-0.4L476.4,192.6z M475.2,192.3l-0.4-0.4l0.5-0.4
            l0.4,0.5L475.2,192.3z M475,192.4l-0.5,0.4l-0.4-0.4l0.5-0.4L475,192.4z M474,192.2l-0.5-0.6l0.5-0.4l0.5,0.6L474,192.2z
            M473.8,192.3l-0.6,0.4l-0.5-0.6l0.6-0.4L473.8,192.3z M473.1,192.8l-0.6,0.4l-0.5-0.6l0.6-0.4L473.1,192.8z M471.8,192.5l-0.4-0.4
            l0.6-0.4l0.4,0.4L471.8,192.5z M471.7,192.6l-0.6,0.4l-0.4-0.4l0.5-0.4L471.7,192.6z M470.6,192.4l-0.5-0.5l0.5-0.4l0.5,0.5
            L470.6,192.4z M470.4,192.5l-0.5,0.4l-0.5-0.5l0.5-0.4L470.4,192.5z M469.2,192.2l-0.5-0.5l0.5-0.4l0.5,0.5L469.2,192.2z
            M469.1,192.3l-0.5,0.3l-0.5-0.5l0.5-0.3L469.1,192.3z M468,192l-0.5-0.5l0.5-0.3l0.5,0.5L468,192z M467.8,192.1l-0.6,0.4l-0.5-0.5
            l0.6-0.4L467.8,192.1z M466.6,191.9l-0.4-0.4l0.6-0.4l0.4,0.4L466.6,191.9z M466.5,192l-0.5,0.3l-0.4-0.4l0.5-0.3L466.5,192z
            M465.5,191.8l-0.5-0.5l0.5-0.3l0.5,0.5L465.5,191.8z M465.3,191.9l-0.5,0.4l-0.5-0.5l0.5-0.4L465.3,191.9z M464.1,191.6l-0.4-0.4
            l0.5-0.4l0.4,0.4L464.1,191.6z M464,191.7l-0.5,0.4l-0.4-0.4l0.5-0.4L464,191.7z M463.3,192.2l-0.6,0.4l-0.4-0.4l0.6-0.4
            L463.3,192.2z M462.1,192l-0.4-0.5l0.6-0.4l0.4,0.5L462.1,192z M462,192.1l-0.5,0.4L461,192l0.5-0.3L462,192.1z M460.9,191.9
            l-0.5-0.5l0.5-0.3l0.5,0.5L460.9,191.9z M460.7,192l-0.6,0.4l-0.5-0.5l0.6-0.4L460.7,192z M459.5,191.8l-0.5-0.5l0.6-0.4l0.5,0.5
            L459.5,191.8z M459.3,191.9l-0.5,0.4l-0.5-0.5l0.5-0.4L459.3,191.9z M458.2,191.6l-0.5-0.5l0.5-0.4l0.5,0.5L458.2,191.6z
            M458,191.7l-0.6,0.4l-0.5-0.5l0.6-0.4L458,191.7z M456.8,191.5l-0.4-0.4l0.6-0.4l0.4,0.4L456.8,191.5z M456.6,191.6l-0.5,0.4
            l-0.4-0.5l0.5-0.4L456.6,191.6z M455.9,192.1l-0.6,0.4l-0.4-0.5l0.6-0.4L455.9,192.1z M455.1,192.7l-0.5,0.3l-0.4-0.4l0.5-0.3
            L455.1,192.7z M455.1,194.8l-0.5-0.5l0.6-0.4l0.5,0.5L455.1,194.8z M455.4,193.8l0.5-0.3l0.5,0.5l-0.5,0.3L455.4,193.8z
            M456.5,194.9l-0.5-0.5l0.5-0.3l0.5,0.5L456.5,194.9z M456.6,194l0.6-0.4l0.5,0.5l-0.6,0.4L456.6,194z M457.6,195.1l-0.4-0.5
            l0.6-0.4l0.4,0.5L457.6,195.1z M458,194.1l0.6-0.4l0.4,0.5l-0.6,0.4L458,194.1z M459,195.1l-0.4-0.4l0.6-0.4l0.4,0.4L459,195.1z
            M459.8,194.6l-0.4-0.4l0.6-0.4l0.4,0.4L459.8,194.6z M460.2,193.6l0.5-0.4l0.4,0.4l-0.5,0.4L460.2,193.6z M461.2,194.7l-0.5-0.5
            l0.5-0.4l0.5,0.5L461.2,194.7z M461.3,193.7l0.6-0.4l0.5,0.5l-0.6,0.4L461.3,193.7z M462.3,194.8l-0.4-0.4l0.6-0.4l0.4,0.4
            L462.3,194.8z M462.7,193.8l0.5-0.3l0.4,0.4l-0.5,0.4L462.7,193.8z M463.7,194.8l-0.5-0.5l0.5-0.4l0.5,0.5L463.7,194.8z M464,194
            l0.6-0.4l0.5,0.5l-0.6,0.4L464,194z M465,195.1l-0.5-0.5l0.6-0.4l0.5,0.5L465,195.1z M465.3,194.1l0.5-0.4l0.5,0.5l-0.5,0.4
            L465.3,194.1z M466.4,195.2l-0.5-0.5l0.5-0.4l0.5,0.5L466.4,195.2z M466.6,194.2l0.5-0.4l0.5,0.5l-0.5,0.4L466.6,194.2z
            M467.7,195.4l-0.4-0.4l0.5-0.4l0.4,0.4L467.7,195.4z M468.4,194.9l-0.4-0.4l0.5-0.3l0.4,0.4L468.4,194.9z M468.6,194l0.6-0.4
            l0.4,0.4l-0.6,0.4L468.6,194z M469.7,195.1l-0.5-0.6l0.6-0.4l0.5,0.6L469.7,195.1z M469.9,194.1l0.5-0.3l0.5,0.6l-0.5,0.3
            L469.9,194.1z M471,195.2l-0.4-0.4l0.5-0.3l0.4,0.4L471,195.2z M471.2,194.3l0.5-0.4l0.4,0.4l-0.5,0.4L471.2,194.3z M472.3,195.4
            l-0.5-0.5l0.5-0.4l0.5,0.5L472.3,195.4z M473,195l-0.5-0.5l0.5-0.4l0.5,0.5L473,195z M473.7,194.5l-0.5-0.5l0.6-0.4l0.5,0.5
            L473.7,194.5z M474.4,195.2l-0.5-0.5l0.6-0.4l0.5,0.5L474.4,195.2z M475.1,194.6l-0.5-0.5l0.6-0.4l0.5,0.5L475.1,194.6z
            M475.8,195.4l-0.5-0.6l0.6-0.4l0.5,0.6L475.8,195.4z M476.5,194.9l-0.5-0.6l0.5-0.4l0.5,0.6L476.5,194.9z M477.1,194.4l-0.5-0.6
            l0.5-0.4l0.5,0.6L477.1,194.4z M478.5,193.4l-0.7,0.5l-0.5-0.6l0.7-0.5L478.5,193.4z M478,192.6l-0.5-0.5l0.6-0.4l0.4,0.5
            L478,192.6z M479.8,190.6l0.4,0.4l-0.7,0.5l-0.4-0.5L479.8,190.6z M478.9,191.2l0.4,0.5l-0.6,0.4l-0.5-0.5L478.9,191.2z
            M478.3,190.5l0.5,0.5l-0.6,0.4l-0.5-0.5L478.3,190.5z M478,191.6l-0.6,0.4l-0.5-0.5l0.6-0.4L478,191.6z M476.9,190.5l0.4,0.4
            l-0.6,0.4l-0.4-0.4L476.9,190.5z M476.6,191.5l-0.7,0.5l-0.4-0.4l0.7-0.5L476.6,191.5z M475.5,190.3l0.5,0.6l-0.7,0.5l-0.5-0.6
            L475.5,190.3z M475.2,191.5l-0.5,0.4l-0.5-0.6l0.5-0.4L475.2,191.5z M474.1,190.3l0.4,0.4L474,191l-0.4-0.4L474.1,190.3z
            M473.4,190.8l0.4,0.4l-0.5,0.4l-0.4-0.4L473.4,190.8z M473.2,191.7l-0.6,0.4l-0.4-0.4l0.6-0.4L473.2,191.7z M472.2,190.6l0.5,0.5
            l-0.6,0.4l-0.5-0.5L472.2,190.6z M471.4,191.1l0.5,0.5l-0.6,0.4l-0.5-0.5L471.4,191.1z M470.8,190.5l0.5,0.5l-0.6,0.4l-0.5-0.5
            L470.8,190.5z M470.6,191.5l-0.5,0.4l-0.5-0.5l0.5-0.4L470.6,191.5z M469.5,190.4l0.5,0.5l-0.5,0.4l-0.5-0.5L469.5,190.4z
            M469.2,191.4l-0.5,0.4l-0.5-0.5l0.5-0.4L469.2,191.4z M468.2,190.4l0.4,0.4l-0.5,0.4l-0.4-0.4L468.2,190.4z M468,191.2l-0.5,0.3
            L467,191l0.5-0.3L468,191.2z M467,190.2l0.5,0.5L467,191l-0.5-0.5L467,190.2z M466.8,191.2l-0.6,0.4l-0.5-0.5l0.6-0.4L466.8,191.2z
            M465.8,190.1l0.4,0.4l-0.6,0.4l-0.4-0.4L465.8,190.1z M465.5,191l-0.5,0.3l-0.4-0.4l0.5-0.3L465.5,191z M464.5,190l0.4,0.5
            l-0.5,0.3l-0.4-0.5L464.5,190z M463.8,190.4l0.4,0.5l-0.5,0.4l-0.4-0.5L463.8,190.4z M463.5,191.4l-0.5,0.4l-0.4-0.5l0.5-0.4
            L463.5,191.4z M462.5,190.4l0.5,0.5l-0.5,0.4l-0.5-0.5L462.5,190.4z M462.3,191.4l-0.6,0.4l-0.5-0.5l0.6-0.4L462.3,191.4z
            M461.2,190.2l0.5,0.5l-0.6,0.4l-0.5-0.5L461.2,190.2z M460.9,191.2l-0.5,0.4L460,191l0.5-0.4L460.9,191.2z M459.8,190.1l0.5,0.5
            l-0.5,0.4l-0.5-0.5L459.8,190.1z M459.6,191.1l-0.6,0.4l-0.5-0.5l0.6-0.4L459.6,191.1z M458.6,190l0.4,0.4l-0.6,0.4l-0.4-0.4
            L458.6,190z M458.3,191l-0.5,0.4l-0.4-0.4l0.5-0.4L458.3,191z M457.3,190l0.4,0.4l-0.5,0.4l-0.4-0.5L457.3,190z M456.6,190.4
            l0.4,0.4l-0.6,0.4l-0.4-0.4L456.6,190.4z M455.8,191l0.4,0.4l-0.5,0.4l-0.4-0.5L455.8,191z M455.1,191.5l0.4,0.5l-0.6,0.4l-0.4-0.5
            L455.1,191.5z M453.9,192.3l0.5-0.3l0.4,0.5l-0.5,0.3L453.9,192.3z M454.2,193l0.4,0.5l-0.6,0.4l-0.4-0.5L454.2,193z M453.4,193.6
            l0.4,0.4l-0.8,0.5l-0.4-0.4L453.4,193.6z M452.9,194.7l-0.6,0.4l-0.4-0.5l0.6-0.4L452.9,194.7z" />
            </g>
            <g id="AREAS">
              <rect id="AREA_1" x="23.2" y="18.2" class="st5-print" width="455.5" height="333.7" />
            </g>
          </svg>
  
          <div 
            id="areaOne"
            :style="{
              'min-width': areaOne.width,
              'max-width': areaOne.width,
              'min-height': areaOne.height,
              'max-height': areaOne.height,
              'top': areaOne.top,
              'left': areaOne.left,
            }"
            :class="{
              'zone-border': (isDragging || dragZone == 'areaOne') && config? true : null
            }"
            @drop="onDrop($event, 'AREA_1')"
            @dragover.prevent
            @dragenter.prevent
          >
            <grid-layout 
              ref="layoutOne"
              :layout.sync="layoutCopyOne"
              :col-num="28"
              :row-height="currentHeightOne"
              :max-rows="28"
              :is-draggable="true"
              :is-resizable="true"
              :is-bounded="true"
              :vertical-compact="false"
              :use-css-transforms="true"
              :preventCollision="true"
              :margin="[0,0]"
              :autoSize="false"
            >
              <grid-item
                :key="item.i"
                v-for="item in layoutCopyOne"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :minW="item.minW"
                :minH="item.minH"
                :h="item.h"
                :i="item.i"
                :is-resizable="item.resizable"
                :static="!config"
                @move="moveItem"
                @moved="movedItem"
                @resized="resizedEvent"
                :id="item.i"
              >
                <div class="area" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="item.elementType == 'area' || item.YardAreaId">
                  <div class="item" @click="ValidateTpArea(item)" :style="PointerArea(item)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.28 40.74">
                      <path d="M41.64 16.27c0 9.78-7.43 12.72-11.45 18.91a18.68 18.68 0 0 0-2.69 7.69 20.52 20.52 0 0 0-2.92-7.69C20.26 29 13.36 26.26 13.36 16.27a14.14 14.14 0 1 1 28.28 0Z" data-name="Capa 2" :style="{fill: item.TpYardAreaColor}" transform="translate(-13.36 -2.13)"/>
                    </svg>
                    <CIcon class="icon" :src="iconSrc(item.TpYardAreaIconRoute)" size="sm" v-if="item.TpYardAreaIconRoute"/>
                  </div>
                  <div class="item-label" :class="{ 'item-label-black': print ? true :  null}">
                    {{ item.YardAreaName }}
                  </div>
                  <div class="controls-option" v-if="config">
                    <div class="control remove" @click.prevent="removeFromZone(item, 1)">
                      <CIcon name="cil-trash"/>
                    </div>
                    <div class="control"  style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1)">
                      <CIcon name="cil-arrow-thick-to-top"/>
                    </div>
                    <div class="control" style="margin-right: 0; margin-left: 0;" @click.prevent="rotateDown(item, 1)">
                      <CIcon name="cil-arrow-thick-to-bottom"/>
                    </div>
                  </div>
                </div>
                <div :class="blockClasses(item.tpa)" :style="{ transform:  `rotate(${item.a}deg)`}" v-if="(item.elementType == 'block' || item.YardBlockId)&&(item?.Height >= level+1)">
                  <div :class="codeClasses(item.tpa)">
                    <div class="circle" :class="{ 'border-black': print ? true :  null}">
                      <img
                        :src="getCharacterSvg(item.BlockAlias)"
                        alt="A"
                        class="center-icon"
                      >
                    </div>
                    <div :class="lineClasses(item.tpa)"></div>
                  </div>
                  <div :class="gridClasses(item.tpa)">
                    <yard-grid
                      :col="colNum(item)"
                      :row="rowNum(item)"
                      :tooltip="tooltip"
                      :viewType="viewType"
                      :angleType="item.tpa"
                      :print="print"
                      :preferences="preferences"
                      :margin="1"
                      showStatus
                      showNumbers
                      border
                      :items="generateLayout(item, level)"
                      :maxCol="colNum(item)"
                      @selected-position="selectedPosition"
                      :block = "item"
                    />
                  </div>
                  <div :class="controlsOptionClasses(item.tpa)" v-if="config">
                    <div :class="controlClasses(item.tpa, 1)" @click.prevent="removeFromZone(item, 1)">
                      <CIcon name="cil-trash"/>
                    </div>
                    <div :class="controlClasses(item.tpa)" @click.prevent="orderBayItems(item)">
                      <CIcon name="cilSwapHorizontal"/>
                    </div>
                    <div :class="controlClasses(item.tpa)" style="margin-bottom: 0;" @click.prevent="rotateUp(item, 1, 'block')">
                      <CIcon name="cil-arrow-thick-to-top"/>
                    </div>
                    <div :class="controlClasses(item.tpa)" style="margin-right: 0; margin-left: 0;" @click.prevent="rotateDown(item, 1, 'block')">
                      <CIcon name="cil-arrow-thick-to-bottom"/>
                    </div>
                      <div :class="controlClasses(item.tpa)" @click.prevent="orderStackItems(item)">
                      <CIcon name="cilSwapVertical"/>
                    </div>
                  </div>
                </div>
              </grid-item>
            </grid-layout>
          </div>
        </div>
      </CCol>
    </CRow>
  </template>
  <script>
  import { mapState } from 'vuex';
  import { GridLayout, GridItem } from "vue-grid-layout";
  import YardGridMixin from '@/_mixins/yard-grid';
  import YardGrid from './yard-grid';
  import { GenerateSvgPlaceholder } from '@/_helpers/funciones';
  import YardSlotModal from "./yard-slot-modal";
  import { dragscroll } from 'vue-dragscroll';
  import PositionModal from './position-modal';
  
  //created
  function created() {
    
  }
  //mounted
  function mounted() {
    this.dimensionsAreaOne = document.querySelector('#AREA_1').getBoundingClientRect();
    this.mapDimensions = document.querySelector('.map-yard').getBoundingClientRect();
    this.$nextTick(() => {
      document.querySelector('#areaOne .vue-grid-layout').style.maxHeight = this.dimensionsAreaOne.height+'px';
      document.querySelector('#areaOne .vue-grid-layout').style.minHeight = this.dimensionsAreaOne.height+'px';
      this.currentHeightOne = this.dimensionsAreaOne.height / 28;
    });
    this.setObserver();
  
    this.layoutCopyOne = this.layout.reduce(function(filtered, option) {
      if (option.Location == 'AREA_1') {
        filtered.push({
          ...option,
          i: option.YardAreaId ? option.YardAreaId : option.YardBlockId,
        });
      }
      return filtered;
    }, []);

    this.AuxiliarBlockJson = this.AuxiliarBlock;
    this.RecognitionZoneJson = this.RecognitionZone;
  }
  
  //data
  function data() {
    return {
      dimensionsAreaOne: {},
      mapDimensions: null,
      dragZone: '',
      currentHeightOne: 0,
    }
  }
  
  //Method
  function positionSubmit (){
    this.$emit('positionSubmit');
  }
  function areaOne() {
    return {
      height: this.dimensionsAreaOne ? this.dimensionsAreaOne.height+'px' : '0px',
      width: this.dimensionsAreaOne ? this.dimensionsAreaOne.width+'px' : '0px',
      top: this.dimensionsAreaOne && this.mapDimensions? (this.dimensionsAreaOne.top-this.mapDimensions.top)+'px' : '0px',
      bottom: this.dimensionsAreaOne ? this.dimensionsAreaOne.bottom+'px' : '0px',
      left: this.dimensionsAreaOne && this.mapDimensions ? (this.dimensionsAreaOne.left-this.mapDimensions.left)+'px' : '0px',
      right: this.dimensionsAreaOne ? this.dimensionsAreaOne.right+'px' : '0px',
    }
  }

  function iconSrc(src) {
    return `${this.$store.getters["connection/getBase"]}${src}`;
  }
  
  function getCharacterSvg(character) {
    return GenerateSvgPlaceholder({
      width: 50,
      height: 30,
      bgColor: 'transparent',
      textColor: this.print ? '#000':'#fff',
      fontSize: 35,
      fontFamilty: 'Roboto',
      text: `${character}`
    });
  }
  function setObserver() {
    let idSvg = this.print ? 'YARD_GEN' : 'PA_GEN';
  
    const resizeObserver = new ResizeObserver(() => {
      if(document.querySelector('#AREA_1')) {
        this.dimensionsAreaOne = document.querySelector('#AREA_1').getBoundingClientRect();
        this.mapDimensions = document.querySelector('.map-yard').getBoundingClientRect();
        this.$nextTick(() => {
            document.querySelector('#areaOne .vue-grid-layout').style.maxHeight = this.dimensionsAreaOne.height+'px';
            document.querySelector('#areaOne .vue-grid-layout').style.minHeight = this.dimensionsAreaOne.height+'px';
            this.currentHeightOne = this.dimensionsAreaOne.height / 28;
        });
      }
    });
  
    resizeObserver.observe(document.getElementById(idSvg));
  }
  function onDrop(evt, divId) {
    this.$store.state.yardsetting.dragging = false;
    let area = evt.dataTransfer.getData('area');
    let atype = evt.dataTransfer.getData('atype');

    this.layoutCopyOne = this.layoutCopyOne.filter(arr => {
      if ((arr.y*21)+(arr.h*21)<=590) {
        return true
      }else{
        this.$emit("remove", arr)
        return false;
      }
    })
  /*let item = this.layoutCopyOne.find(layout => layout.i == area)
  if ((item.y*21)+(item.h*21)<=590) {
    let current = this.getCurrentLayout(divId);
    this.$emit('update-layout', area, divId, atype, current);
  }*/
    let current = this.getCurrentLayout(divId);
    this.$emit('update-layout', area, divId, atype, current);
  }

  function moveItem(i, newX, newY){
    this.dragZone = 'areaOne';
  }
  function movedItem(i, newX, newY){
    this.dragZone = '';
    this.layoutCopyOne = this.layoutCopyOne.filter(arr => {
      if ((arr.y*21)+(arr.h*21)<=590) {
        return true
      }else{
        this.$emit("remove", arr)
        return false;
      }
    })
  }
  function resizedEvent(i, newH, newW, newHPx, newWPx) {
    this.layoutCopyOne = this.layoutCopyOne.filter((arr, index) => {
      if ((arr.y*21)+(arr.h*21)<=590) {
        return true
      }else{
        this.$emit("remove", arr)
        return false;
      }
    })
  }
  function rotateStart(e, item) {
    
  }
  function rotateEnd(e, item) {
    
  }
  function orderStackItems(item) { 
    item.FgBlockOrder = item.FgBlockOrder ? 0 : 1;
  }
  function orderBayItems(item) {
    item.FgBayOrder = item.FgBayOrder ? 0 : 1;
  }
  function removeFromZone(item, arr) {
    let found = -1;
    if(item.YardAreaId) {
      switch (arr) {
        case 1:
          found = this.layoutCopyOne.findIndex((it) => it.YardAreaId == item.YardAreaId);
          if(found != -1) {
            this.layoutCopyOne.splice(found, 1);
          }
          break;
        default:
          break;
      }
    }

    if(item.YardBlockId) {
      switch (arr) {
        case 1:
          found = this.layoutCopyOne.findIndex((it) => it.YardBlockId == item.YardBlockId);
          if(found != -1) {
            this.layoutCopyOne.splice(found, 1);
          }
          break;
        default:
          break;
      }
    }
    this.$emit("remove", item);
  }
  function setAngle(item, angle, area) {
    let found = -1;
    switch (area) {
      case 1:
        if(item.YardAreaId)
          found = this.layoutCopyOne.findIndex((it) => it.YardAreaId == item.YardAreaId);      
        else if(item.YardBlockId)
          found = this.layoutCopyOne.findIndex((it) => it.YardBlockId == item.YardBlockId);
        if(found != -1) {
          this.layoutCopyOne[found].a = angle;
        }
        break;
      default:
        break;
    }
  }
  //computed
  function rowStyles() {
    if(this.print) 
      return {
        width: '70%',
      };

    return {
      width: '100%',
    }
  }
  
  //watch
  
  export default {
    name: 'pa-gen',
    mixins:[YardGridMixin],
    components: {
      GridLayout,
      GridItem,
      YardGrid,
      YardSlotModal,
      PositionModal
    },
    props: {
      layout: {
        type: Array,
        default: () => [],
      },
      print: {
        type: Boolean,
        default: false,
      },
      config: {
        type: Boolean,
        default: false,
      },
      level: {
        type: Number,
        default: 0,
      },
      tooltip: {
        type: Boolean,
        default: false,
      },
      viewType: {
        type: String,
        default: '',
      },
      preferences: {
        type: Array,
        default: () => []
      },
      zoom: {
        type: Number,
        default: 100,
      },
      AuxiliarBlock: {
        type: Array,
        default: () => []
      },
      RecognitionZone: {
        type: Array,
        default: () => []
      },
    },
    data,
    directives: {
      dragscroll
    },
    created,
    mounted,
    methods: {
      iconSrc,
      getCharacterSvg,
      setObserver,
      onDrop,
      moveItem,
      movedItem,
      resizedEvent,
      rotateEnd,
      rotateStart,
      removeFromZone,
      setAngle,
      orderStackItems,
      orderBayItems,
      positionSubmit
    },
    computed: {
      areaOne,
      rowStyles,
      ...mapState({
        submitState: state => state.yardsetting.isSubmit,
        submitStateEdit: state => state.yardsetting.isSubmitEdit,
        printing: state => state.yardsetting.printing,
        isDragging: state => state.yardsetting.dragging,
      })
    },
    watch: {
      layout: {
        handler: function (val, oldVal) {
          this.layoutCopyOne = this.updateCopy(val.filter((item) => item.Location == 'AREA_1'));
        },
        deep: true
      },
      submitState: function(newVal, oldVal) {
        if(newVal && !oldVal) {
          this.$emit("submited", [...this.layoutCopyOne]);
        }
      },
      submitStateEdit: function(newVal, oldVal) {
        if(newVal && !oldVal) {
          this.$emit("edited", [...this.layoutCopyOne]);
        }
      },
      printing: function(newVal, oldVal) {
        if(newVal && !oldVal) {
          this.$emit("print", [...this.layoutCopyOne]);
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import '../css/rotate.scss';
  .map-yard {
    width: 100%;
  
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .map-pdf{
    width: 80%;
  }
  .zone-border { 
    border: 2px dashed #ec631b !important;
  }
  .center-map-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #PA_GEN{
    width: 90%;
  }
  
  #areaOne,
  #areaTwo {
    // overflow: hidden;
    position: absolute;
    /* background-color: #000; */
  }
  
  .box {
    display: flex;
  }
  
  .sidebar {
    background-color: #d6d6d6;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  }
  .droppable-element {
      width: 150px;
      text-align: center;
      background: #dde9ff;
      border: 1px solid black;
      margin: 10px 0;
      padding: 10px;
      cursor: grab;
  }
  .vue-grid-layout {
      background: transparent;
  }
  .vue-grid-item:not(.vue-grid-placeholder) { /*Estilos del rectangulo*/
      /* background: #ccc; */
      /* border: 1px solid black; */
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .vue-grid-item.vue-grid-placeholder {
      background: #ec631b !important;
  }
  .vue-grid-item .resizing {
      opacity: 0.9;
  }
  .vue-grid-item .static {
      background: #cce;
  }
  .vue-grid-item .text {
      font-size: 16px;
      text-align: center;
      /* position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      width: 100%; */
  }
  .vue-grid-item .no-drag {
      height: 100%;
      width: 100%;
  }
  .vue-grid-item .minMax {
      font-size: 12px;
  }
  .vue-grid-item .add {
      cursor: pointer;
  }
  .vue-draggable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
      background-position: bottom right;
      padding: 0 8px 8px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: pointer;
  }
  .vue-grid-item {
    touch-action: none;
  }
  
  .layoutJSON {
      background: #ddd;
      border: 1px solid black;
      margin-top: 10px;
      padding: 10px;
  }
  .layoutJSON {
      background: #ddd;
      border: 1px solid black;
      margin-top: 10px;
      padding: 10px;
  }
  .columns {
      -moz-columns: 120px;
      -webkit-columns: 120px;
      columns: 120px;
  }
  
  .controls-option {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
  
    display: flex;
    flex-direction: column;
  
    .control {
      cursor: pointer;
      margin-bottom: 2px;
      color: #fff;
      &:active{
        background-color: #1a3760;
        border-radius: 2px;
      }
      &.remove {
        color: #e1373f;
        margin-bottom: 2px;
      }
    }
  }
  
  // AREAS
  .area {
    // margin: 0 5px;
    min-height: 60px;						
    width:	100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    .item {
      width: 100%;
      min-height: 44px;
      // background-color: #214774;
      // border-radius: 5px;
      // border: 3px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      .icon {
        width: 17%;
        position: absolute;
        top: 20%;
      }
      svg {
        width: 33%;
      }
    }
    .item-label {
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      }
    // .item {
    //   width: 100%;
    //   min-height: 44px;
    //   background-color: #214774;
    //   border-radius: 5px;
    //   border: 3px solid #000;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .icon {
    //     max-width: 25px;
    //   }
    // }
    // .item-label {
    //   font-size: 10px;
    //   font-weight: bold;
    //   color: #fff;
    // }
  }
  .item-label-black {
    color: #000 !important;
  }
  .border-black {
    border: 1px solid #000 !important;
  }
  .block {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    // border: 1px solid #bbbbbb;
    // border-radius: 2px;
  
    .code {
      width: 10%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        position: relative;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
      }
      .line {
        border: 1px solid #fff;
        flex: 1;
      }
  
    }
    .grid {
      width: 90%;
      height: 100%;
      position: relative;
    }
  }
  
  .center-icon { /* Center a.svg */
  
  position: absolute;
  width: 45%;
  height: 45%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }
  
  // SVG
.st0 {
    fill: #C6C6C6;
}

.st1 {
    opacity: 0.32;
    fill: #111315;
    enable-background: new;
}

.st2 {
    fill: none;
    stroke: #F9F9F9;
    stroke-width: 0.57;
    stroke-miterlimit: 10;
}

.st3 {
    fill: #CDCDCD;
}

.st4 {
    fill: #FFEC00;
}

.st5 {
    fill: none;
    stroke: #23210D;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.st6 {
    fill: #877D08;
}

.st7 {
    fill: none;
    stroke: #757373;
    stroke-width: 0.28;
    stroke-miterlimit: 10;
}

.st8 {
    fill: #757373;
}

.st9 {
    fill: #23210D;
}

.st10 {
    fill: none;
    stroke:#898989;
    stroke-width: 1px;
}

.st0-print {
  fill: #FFFFFF;
  stroke: #000000;
  stroke-width: 0.57;
  stroke-miterlimit: 10;
}

.st1-print {
  fill: none;
  stroke: #000000;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st2-print {
  fill: #050505;
}

.st3-print {
  fill: none;
  stroke: #020201;
  stroke-width: 0.28;
  stroke-miterlimit: 10;
}

.st4-print {
  fill: #070201;
}

.st5-print {
  fill: none;
  stroke:#fff;
  stroke-width: 1px;
}
</style>